import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Grid, Fade, Typography, Button } from '@material-ui/core';

import api from '~/services/api';

import ModalResposta from './ModalResposta';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '481px',
    height: '487px',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '8px',
    padding: '34px 42px',
  },
  container: {
    height: '100%',
  },
  tituloModal: {
    color: '#50A6FF',
    fontSize: '24px',
  },
  tituloAtividade: {
    fontSize: '20px',
    color: '#868CAB',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
    fontSize: '10px',
  },
  value: {
    color: '#868CAB',
    fontSize: '14px',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btAcessar: {
    background: '#fff',
    color: '#50A6FF',
    borderRadius: '200px',
    fontSize: '14px',
    '&:hover': {
      background: `${darken(0.1, '#fff')}`,
    },
  },
  containerFiles: {
    maxHeight: '90px',
    overflowY: 'auto',
  },
  btResponder: {
    width: '247px',
    color: '#fff',
    background: '#50A6FF',
    borderRadius: '200px',
    alignSelf: 'center',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  acessar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
}));

export default function ModalAtividade({ atividade, open, setOpen }) {
  const classes = useStyles();
  const [openModalResposta, setOpenModalResposta] = useState(false);
  const [atividadeLancada, setAtividadeLancada] = useState(null);

  const STATUS_ABERTA = 1;
  const STATUS_PENDENTE = 3;
  const STATUS_FAZER_NOVAMENTE = 5;

  useEffect(() => {
    async function getAtividadeLancada() {
      console.log('back call');
      const { smartconteudosmart_id: _lancaratividade_id } = atividade;

      const response = await api.get(
        `atividades-realizadas/?atividadeLancada=${_lancaratividade_id}`
      );

      if (open && response.data[0] && !response.data[0].visualizado) {
        const { atividade_id, lancaratividade_id } = response.data[0];
        await api.post('atividade-visualizada', {
          atividade_id,
          lancaratividade_id,
        });
      }

      setAtividadeLancada(response.data[0]);
    }

    if (!atividadeLancada && open) {
      getAtividadeLancada();
    }
  }, [atividade, atividadeLancada, open]);

  console.log(
    'Conteudo - Visualizando atividade',
    `Open ${open}`,
    JSON.stringify(atividade),
    JSON.stringify(atividadeLancada)
  );

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModalResposta = () => {
    setOpenModalResposta(false);
    handleCloseModal();
  };

  const respostaDisponivel = () => {
    return (
      !atividadeLancada.status_id ||
      [STATUS_ABERTA, STATUS_PENDENTE, STATUS_FAZER_NOVAMENTE].includes(
        atividadeLancada.status_id
      )
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={12} className={classes.info}>
                <Typography variant="h5" className={classes.tituloModal}>
                  Sobre a atividade
                </Typography>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    ATIVIDADE
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.tituloAtividade}
                  >
                    {atividade.titulo}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    DESCRIÇÃO
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.value}
                  >
                    {atividade.descricao}
                  </Typography>
                </div>
                <div>
                  {!!atividade.anexos && (
                    <>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        ARQUIVOS E LINKS COMPLEMENTARES
                      </Typography>
                      <Grid container className={classes.containerFiles}>
                        {atividade.anexos.map((anexo) => (
                          <Grid
                            item
                            xs={12}
                            className={classes.file}
                            key={anexo}
                          >
                            <Typography className={classes.fileName}>
                              {anexo.length > 30
                                ? `${anexo.substring(0, 30)}...`
                                : anexo}
                            </Typography>
                            <Button
                              className={classes.btAcessar}
                              size="small"
                              onClick={() => window.open(anexo, '_blank')}
                            >
                              ACESSAR
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                </div>
                {atividadeLancada && (
                  <Button
                    type="button"
                    variant="contained"
                    className={classes.btResponder}
                    onClick={() => {
                      if (respostaDisponivel()) {
                        setOpenModalResposta(true);
                      }
                    }}
                    disabled={!respostaDisponivel()}
                  >
                    RESPONDER ATIVIDADE
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {openModalResposta && (
        <ModalResposta
          open={openModalResposta}
          handleClose={handleCloseModalResposta}
          atividade={atividade}
        />
      )}
    </div>
  );
}

ModalAtividade.propTypes = {
  atividade: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
