import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { formatDistance } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { FaRegWindowClose } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { lighten } from 'polished';
import Input from '~/components/CustomUI/Input';
import 'react-perfect-scrollbar/dist/css/styles.css';
import api from '~/services/api';

import { Container, Title } from './styles';

const StyledButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    background: 'none',
    borderColor: '#000',
    '&:hover': {
      backgroundColor: '',
      borderColor: '#111',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#222',
      borderColor: '#222',
    },
  },
})(Button);

const schema = Yup.object().shape({
  mensagem: Yup.string().required('A mensagem é obrigatória'),
});

export default function Comentarios({ trabalho, isOpen, setOpenComentarios }) {
  const instituicao = useSelector((state) => state.instituicao.data);
  const profile = useSelector((state) => state.aluno.profile);
  const [mensagens, setMensagens] = useState([]);
  const [mensagensLoaded, setMensagensLoaded] = useState(false);
  const [ultimaMensagem, setUltimaMensagem] = useState(null);
  const scrollRef = useRef(null);

  if (scrollRef.current) {
    scrollRef.current._container.scrollTop =
      scrollRef.current._container.scrollHeight;
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: '15px 15px',
      height: '500px',
      width: '320px',
    },
    form: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    messageSender: {
      background: lighten(0.1, instituicao.primary_color),
      borderRadius: '10px',
      color: '#fff',
      padding: '8px 8px',
      marginTop: '5px',
      lineHeight: '15px',
      width: 'fit-content',
      alignSelf: 'flex-end',
      maxWidth: '200px',
    },
    messageReceiver: {
      background: '#999',
      borderRadius: '10px',
      color: '#fff',
      padding: '8px 8px',
      marginTop: '5px',
      lineHeight: '15px',
      width: 'fit-content',
      maxWidth: '200px',
    },
    chat: {
      margin: '15px 0',
      height: '100%',
      maxHeight: '300px',
      width: '100%',
    },
    messages: {
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
    },
    messageStatus: {
      width: '95%',
      fontSize: '8px',
      color: '#777',
      marginTop: '10px',
      display: 'flex',
      justifyContent:
        ultimaMensagem && ultimaMensagem.pessoa_id === profile.usuario_id
          ? 'flex-end'
          : 'flex-start',
    },
    input: {
      maxHeight: '50px',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    async function updateMessages() {
      await api.put(`observacoes/${trabalho}`);
    }

    async function getMessages() {
      const response = await api.get(`observacoes/${trabalho}`);
      await setMensagens(response.data);
    }

    async function getUltimaMensagem() {
      if (mensagens.length > 0) {
        await setUltimaMensagem(mensagens[mensagens.length - 1]);
        setMensagensLoaded(true);
      }
    }

    updateMessages();
    if (!ultimaMensagem && !mensagensLoaded) {
      getMessages();
      getUltimaMensagem();
    }
  }, [mensagens, mensagensLoaded, trabalho, ultimaMensagem]);

  async function handleSubmit({ mensagem }, { resetForm }) {
    const response = await api.post(`observacoes`, {
      trabalho,
      observacao: mensagem,
    });

    await setUltimaMensagem(response.data);
    await setMensagens(mensagens.concat(response.data));
    resetForm({ mensagem: '' });
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <Container>
              <Title>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenComentarios(false);
                    }}
                  >
                    <FaRegWindowClose
                      size={20}
                      color={instituicao.primary_color}
                    />
                  </button>
                </div>
                <p>Comentário sobre</p>
                <h3>Trabalho sobre história da informática e da computação</h3>
              </Title>
              <div className={classes.chat}>
                <PerfectScrollBar ref={scrollRef}>
                  <div className={classes.messages}>
                    {mensagens &&
                      mensagens.map((mensagem) => (
                        <div
                          key={mensagem.observacao_atividade_id}
                          className={
                            mensagem.pessoa_id === profile.usuario_id
                              ? classes.messageSender
                              : classes.messageReceiver
                          }
                        >
                          <Typography component="p" variant="body1">
                            {mensagem.observacao}
                          </Typography>
                        </div>
                      ))}
                  </div>
                  {ultimaMensagem && (
                    <div className={classes.messageStatus}>
                      <Typography component="p" variant="caption">
                        {ultimaMensagem.visualizado
                          ? `Visualizado ${formatDistance(
                              new Date(ultimaMensagem.visualizado),
                              new Date(),
                              { includeSeconds: true, locale, addSuffix: true }
                            )}`
                          : `Enviado ${formatDistance(
                              new Date(ultimaMensagem.data_hora),
                              new Date(),
                              { includeSeconds: true, locale, addSuffix: true }
                            )}`}
                      </Typography>
                    </div>
                  )}
                </PerfectScrollBar>
              </div>
              <Form schema={schema} onSubmit={handleSubmit}>
                <Grid container spacing={1} className={classes.form}>
                  <Grid item xs={8}>
                    <Input
                      name="mensagem"
                      type="text"
                      label="Mensagem"
                      multiline
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3}>
                    <StyledButton variant="contained" type="submit">
                      <IoMdSend size={25} color="#777" />
                    </StyledButton>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

Comentarios.propTypes = {
  trabalho: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpenComentarios: PropTypes.func.isRequired,
};
