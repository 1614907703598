import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'material-ui-image';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core';
import IconPodcast from '~/assets/img_grafismo_podcast.png';
import {
  addConteudoWatched,
  updateConteudoWatched,
} from '~/store/modules/conteudowatched/actions';

const TYPE_PODCAST = 3;

const useStyles = makeStyles({
  playerVideo: {
    width: 'fit-content',
  },
  playerPodcast: {
    width: 'fit-content',
    height: '70px',
  },
});

const Player = ({ conteudoEscolhido }) => {
  const classes = useStyles();
  const playerRef = useRef(null);
  const dispatch = useDispatch();
  const conteudosWatched = useSelector((state) => state.conteudowatched.data);
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(false);

  const handleProgress = (progress) => {
    if (Math.round(progress.playedSeconds) % 10 === 0) {
      dispatch(
        updateConteudoWatched({
          id: conteudoEscolhido.smartconteudosmart_id,
          page: null,
          seconds: Math.round(progress.playedSeconds - 5),
        })
      );
    }
  };

  const handlePlayerReady = useCallback(() => {
    if (!ready) {
      setReady(true);

      const conteudoStarted = conteudosWatched.find(
        (content) => content.id === conteudoEscolhido.smartconteudosmart_id
      );

      if (!conteudoStarted) {
        dispatch(
          addConteudoWatched({
            id: conteudoEscolhido.smartconteudosmart_id,
            page: null,
            seconds: 0,
          })
        );
      } else {
        playerRef.current.seekTo(conteudoStarted.seconds);
      }

      setPlaying(true);
    }
  }, [ready]); //eslint-disable-line

  const getFormattedUrl = (url) => {
    const matched = url.match(
      /^(http(s)??:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/
    );

    return (matched && matched[0]) || url;
  };

  if (!conteudoEscolhido) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      {TYPE_PODCAST === conteudoEscolhido.tipo && (
        <Image
          src={conteudoEscolhido.preview || IconPodcast}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            marginRight: '20px',
            marginBottom: '10px',
            marginTop: '10px',
            background: 'none',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
            maxHeight: '370px',
          }}
        />
      )}
      <ReactPlayer
        ref={playerRef}
        url={getFormattedUrl(conteudoEscolhido.url)}
        controls
        className={
          (TYPE_PODCAST === conteudoEscolhido.tipo && classes.playerPodcast) ||
          classes.playerVideo
        }
        height="55"
        config={{
          file: {
            attributes: {
              onContextMenu: (e) => e.preventDefault(),
              disablePictureInPicture: true,
              controlsList: 'nodownload noremoteplayback',
            },
          },
        }}
        playing={playing}
        onReady={handlePlayerReady}
        onProgress={handleProgress}
      />
    </>
  );
};

Player.propTypes = {
  conteudoEscolhido: PropTypes.shape().isRequired,
};

export default Player;
