import styled from 'styled-components';
import theme from 'styled-theming';

const primaryColor = theme('escola', {
  default: '#f97200',
  saga: '#f15a24',
  cebrac: '#2f2b60',
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  letter-spacing: 1px;

  button {
    width: 60%;
    margin: 0 auto;
    border: none;
    background: ${primaryColor};
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    font-weight: bold;
  }
`;

export const Title = styled.div`
  color: #444;
  h3 {
    color: ${primaryColor};
  }

  div {
    display: flex;
    justify-content: flex-end;

    button {
      width: fit-content;
      border: none;
      background: none;
      margin: 0;
      padding: 0;
      display: flex;
    }
  }
`;
