export function requestSuccess(contratos) {
  return {
    type: '@contratos/REQUEST_SUCCESS',
    payload: { contratos },
  };
}

export function request() {
  return {
    type: '@contratos/REQUEST',
  };
}

export function addNotificacaoContratos(quantidade) {
  return {
    type: '@notificacoes/ADD_NOTIFICACAO_CONTRATOS',
    payload: { quantidade },
  };
}
