export function addNotificacaoContratos(quantidade) {
  return {
    type: '@notificacoes/ADD_NOTIFICACAO_CONTRATOS',
    payload: { quantidade },
  };
}

export function removeNotificacaoContratos() {
  return {
    type: '@notificacoes/REMOVE_NOTIFICACAO_CONTRATOS',
  };
}

export function removeNotificacaoAtividades() {
  return {
    type: '@notificacoes/REMOVE_NOTIFICACAO_ATIVIDADES',
  };
}

export function removeNotificacaoVideoAula() {
  return {
    type: '@notificacoes/REMOVE_NOTIFICACAO_VIDEOAULA',
  };
}

export function addNotificacoes(notificacoes) {
  return {
    type: '@notificacoes/ADD_NOTIFICACAO_CONTRATOS',
    payload: { notificacoes },
  };
}

export function saveStateNotificacoes(notificacoes) {
  return {
    type: '@notificacoes/SAVE_STATE_NOTIFICACOES',
    payload: { notificacoes },
  };
}
