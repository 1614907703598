import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import unidade from './unidade/sagas';
import conteudos from './conteudos/sagas';
import contratos from './contratos/sagas';
import videoaula from './videoaula/sagas';
import aula from './aula/sagas';
import vejaemcasa from './vejaemcasa/sagas';
import notificacoes from './notificacoes/sagas';
import lookup from './lookup/sagas';
import pagamento from './pagamento/sagas';
import funcionalidades from './funcionalidades/sagas';
import aceitecontrato from './aceitecontrato/sagas';
import financeiro from './financeiro/sagas';
import documents from './documents/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    unidade,
    conteudos,
    contratos,
    videoaula,
    aula,
    notificacoes,
    vejaemcasa,
    lookup,
    pagamento,
    funcionalidades,
    aceitecontrato,
    financeiro,
    documents,
  ]);
}
