import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useDropzone } from 'react-dropzone';
import { FaFileUpload, FaRegWindowClose } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import api from '~/services/api';
import { Container, Title, Dropzone } from './styles';

Yup.setLocale({
  mixed: {
    default: 'Tipo de arquivo inválido',
  },
  string: {
    oneOf: 'Tipo de arquivo inválido',
  },
  number: {
    max: 'Arquivo excede o tamanho máximo permitido',
  },
});

const schema = Yup.object().shape({
  size: Yup.number().max(0.8).required(),
  type: Yup.string().oneOf(['application/pdf'], 'Tipo de arquivo inválido'),
});

export default function FormTrabalho({
  trabalho,
  isOpen,
  setOpenFormTrabalho,
}) {
  const [arquivo, setArquivo] = useState(null);
  const [labelButton, setLabelButton] = useState('Carregar Arquivo');
  const instituicao = useSelector((state) => state.instituicao.data);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: '350px',
    },
    button: {
      width: '60%',
      margin: '0 auto',
      border: 'none',
      color: '#fff',
      background: instituicao.primary_color,
      borderRadius: '4px',
      padding: '8px',
      fontWeight: 'bold',
      '&:disabled': {
        background: '#999',
        cursor: 'initial',
      },
    },
  }));

  const classes = useStyles();

  async function validarArquivoUpdado() {
    const { size: tamanho, type } = arquivo;
    const size = tamanho / 1024 / 1024;

    schema.validate({ size, type }).catch((error) => {
      setArquivo(null);
      toast.error(error.message);
    });
  }

  if (arquivo) {
    validarArquivoUpdado();
  }

  const onDrop = useCallback((acceptedFile) => {
    setArquivo(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  async function onClickHandler() {
    setLabelButton('Carregando');

    try {
      const data = new FormData();
      data.append('file', arquivo);
      data.append('trabalho', trabalho);
      await api.post('trabalhos', data);

      toast.success('Trabalho adicionada com sucesso.');
      setOpenFormTrabalho();
    } catch (err) {
      toast.error('Erro ao adicionar trabalho.');
    }

    setLabelButton('Carregar Arquivo');
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <Container>
              <Title>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenFormTrabalho(false);
                    }}
                  >
                    <FaRegWindowClose
                      size={20}
                      color={instituicao.primary_color}
                    />
                  </button>
                </div>
                <p>Arquivo para</p>
                <h3>Trabalho sobre história da informática e da computação</h3>
              </Title>
              <Dropzone>
                <div {...getRootProps()}>
                  <FaFileUpload size={60} color={instituicao.primary_color} />
                  <input {...getInputProps()} />
                  <aside>
                    <p>
                      Arraste seu arquivo ou <u>busque</u>
                    </p>
                    <small>Suportamos arquivos .PDF menores que 800kb.</small>
                    {arquivo && <p>{arquivo.name}</p>}
                  </aside>
                </div>
              </Dropzone>
              <button
                type="button"
                onClick={onClickHandler}
                disabled={!arquivo}
                className={classes.button}
              >
                {labelButton}
              </button>
            </Container>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

FormTrabalho.propTypes = {
  trabalho: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpenFormTrabalho: PropTypes.func.isRequired,
};
