export function loadSuccess(financeiro) {
  return {
    type: '@financeiro/LOAD_SUCCESS',
    payload: { financeiro },
  };
}

export function loadBilling(receber_id) {
  return {
    type: '@financeiro/LOAD_BILLING',
    payload: { receber_id },
  };
}

export function loadBillingSuccess(cobranca) {
  return {
    type: '@financeiro/LOAD_BILLING_SUCCESS',
    payload: { cobranca },
  };
}

export function loadBillingError() {
  return {
    type: '@financeiro/LOAD_BILLING_ERROR',
  };
}

export function loadInstallments(contrato_id) {
  return {
    type: '@financeiro/LOAD_INSTALLMENTS',
    payload: { contrato_id },
  };
}

export function loadInstallmentsSuccess(installments) {
  return {
    type: '@financeiro/LOAD_INSTALLMENTS_SUCCESS',
    payload: { installments },
  };
}

export function loadInstallmentsError() {
  return {
    type: '@financeiro/LOAD_INSTALLMENTS_ERROR',
  };
}
