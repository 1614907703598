import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MuiModal, Fade, Typography, Button } from '@material-ui/core';
import Image from 'material-ui-image';
// import { darken } from 'polished';

import PaymentErrorImg from '~/assets/ic_payment_error.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(4, 3),
    maxHeight: '520px',
    maxWidth: '370px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#47D19D',
    fontSize: '31px',
    lineHeight: '36px',
    textAlign: 'center',
    margin: '10px 0',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '19px',
    textAlign: 'center',
  },
  button: {
    borderRadius: '200px',
    marginTop: '15px',
  },
}));

export default function PaymentError({ retry, changeCard, open, setOpen }) {
  const classes = useStyles();

  return (
    <div>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Image
              src={PaymentErrorImg}
              style={{
                display: 'flex',
                paddingTop: 'unset',
                marginRight: '20px',
              }}
              imageStyle={{
                width: 'unset',
                height: 'unset',
                position: 'unset',
                maxHeight: '255px',
              }}
            />
            <Typography color="primary" variant="h6">
              Ocorreu um erro :(
            </Typography>
            <Typography color="secondary" variant="body1">
              O emissor do cartão não autorizou o pagamento.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              size="small"
              onClick={() => retry()}
              className={classes.button}
            >
              TENTAR NOVAMENTE
            </Button>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              size="small"
              onClick={() => changeCard()}
              className={classes.button}
            >
              SUBSTITUIR CARTÃO
            </Button>
          </div>
        </Fade>
      </MuiModal>
    </div>
  );
}

PaymentError.propTypes = {
  retry: PropTypes.func.isRequired,
  changeCard: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
