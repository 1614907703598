import React, { useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  Fade,
  Button,
  Typography,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import { BlurCircular } from '@material-ui/icons';
import { darken } from 'polished';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Input from '~/components/CustomUI/TextField';

import api from '~/services/api';

const useStyles = makeStyles((theme) => ({
  faleConosco: {
    display: 'flex',
    color: '#333',
    alignItens: 'center',
    justifyContent: 'flex-end',
    height: '38px',
    marginRight: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    maxWidth: '920px',
    borderRadius: '7px',
  },
  btFaleConosco: {
    background: '#868CAB',
    color: '#fff',
    fontWeight: 'normal',
    borderRadius: '200px',
    fontSize: '14px',
    transition: 'background 0.2s',
    textTransform: 'unset',
    '&:hover': {
      background: `${darken(0.1, '#868CAB')}`,
    },
  },
  iconButton: {
    marginRight: '7px',
  },
  btCancelar: {
    width: '203px',
    height: '36px',
    background: '#BDC0D5',
    color: '#fff',
    borderRadius: '200px',
    marginRight: '7px',
    '&:hover': {
      background: `${darken(0.1, '#BDC0D5')}`,
    },
  },
  btEnviar: {
    width: '203px',
    height: '36px',
    background: '#50A6FF',
    color: '#fff',
    borderRadius: '200px',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: '#4D5884',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.15px',
  },
  subtitle: {
    color: '#868CAB',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  btLabel: {
    fontSize: '14px',
  },
}));

const schema = Yup.object().shape({
  telefone: Yup.string().required('O telefone é obrigatório.'),
  titulo: Yup.string().required('O assunto é obrigatório.'),
  obs: Yup.string().required('A mensagem é obrigatória.'),
});

export default function FaleConosco() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state.aluno.profile);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TextMaskCustom = (props) => {
    // eslint-disable-next-line
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          '(',
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  };

  const handleSubmit = async ({ telefone, obs, titulo }) => {
    setLoading(true);
    const bodyRequest = {
      obs,
      titulo,
    };

    telefone = telefone.replace(/\D/g, '');

    if (telefone !== profile.login) {
      bodyRequest.telefone = telefone;
    }

    await api
      .post('fale-conosco', bodyRequest)
      .then(() => {
        setLoading(false);
        toast.success('Mensagem enviada com sucesso.');
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        toast.success(
          'Ocorreu um erro ao enviar mensagem, verifique os dados preenchidos e tente novamente.'
        );
      });
  };

  return (
    <div>
      <Button
        type="button"
        onClick={handleOpen}
        variant="contained"
        className={classes.btFaleConosco}
        size="small"
        startIcon={<BlurCircular />}
      >
        <Hidden only="xs">
          <Typography component="span" variant="caption">
            Fale com a gente
          </Typography>
        </Hidden>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography component="p" className={classes.title}>
                    Envie sua Mensagem
                  </Typography>
                  <Typography component="p" className={classes.subtitle}>
                    Preencha o formulário abaixo que em breve entraremos em
                    contato!
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="filled"
                    name="nome"
                    label="Nome"
                    fullWidth
                    defaultValue={profile && profile.nome}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="filled"
                    name="telefone"
                    label="Telefone com DDD"
                    fullWidth
                    InputProps={{ inputComponent: TextMaskCustom }}
                    defaultValue={profile && profile.login}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    variant="filled"
                    name="titulo"
                    label="Assunto"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    variant="filled"
                    name="obs"
                    label="Mensagem"
                    fullWidth
                    multiline
                    rows={5}
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridButton}>
                  {!loading && (
                    <>
                      <Button
                        variant="contained"
                        className={classes.btCancelar}
                        onClick={handleClose}
                      >
                        CANCELAR
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.btEnviar}
                        type="submit"
                      >
                        ENVIAR
                      </Button>
                    </>
                  )}
                  {loading && <CircularProgress />}
                </Grid>
              </Grid>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
