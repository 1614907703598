import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import api from '~/services/api';
import { requestSuccess } from '~/store/modules/materias/actions';

export default function FiltrosVideoAula({ returnFiltros, filtrosAplicados }) {
  const dispatch = useDispatch();

  const contratos = useSelector((state) => state.conteudos.data);
  const materiasState = useSelector((state) => state.materias.data);

  const [state, setState] = useState({ ...filtrosAplicados });
  const [materias, setMaterias] = useState(materiasState || undefined);
  const [cursos, setCursos] = useState(undefined);

  useEffect(() => {
    returnFiltros(state);
  }, [state]); //eslint-disable-line

  useEffect(() => {
    async function getMaterias() {
      const contratoFiltered = contratos.dados.filter(
        (contrato) => contrato.contrato_id > 0
      );

      if (contratoFiltered.length) {
        const response = await api.get(
          `materias/${contratoFiltered[0].contrato_id}/${contratoFiltered[0].curso_id}`
        );
        setMaterias(response.data);
        dispatch(requestSuccess(response.data));
      }
    }

    if (!materias && contratos) {
      getMaterias();
    }
  }, [materias, contratos]); //eslint-disable-line

  useEffect(() => {
    if (!cursos && contratos) {
      const cursosOptions = [];
      contratos.dados
        .filter((contrato) => contrato.contrato_id > 0)
        .map((contrato) => {
          return cursosOptions.push({
            curso: contrato.curso,
            curso_id: contrato.curso_id,
          });
        });
      setCursos(cursosOptions);
    }
  }, [cursos, contratos]); //eslint-disable-line

  function handleChangeCurso(event) {
    setState({ ...state, curso: event.target.value });
  }

  function handleChangeMateria(event) {
    setState({ ...state, materia: event.target.value });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="label-curso">Curso</InputLabel>
          <Select
            labelId="label-curso"
            id="curso"
            value={state.curso}
            onChange={handleChangeCurso}
          >
            <MenuItem value={undefined}>
              <em>Selecione</em>
            </MenuItem>
            {cursos &&
              cursos.map((curso) => (
                <MenuItem key={curso.curso_id} value={curso.curso_id}>
                  {curso.curso}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="label-materia">Matéria</InputLabel>
          <Select
            labelId="label-materia"
            id="materia"
            value={state.materia}
            onChange={handleChangeMateria}
          >
            <MenuItem value={undefined}>
              <em>Selecione</em>
            </MenuItem>
            {materias &&
              materias.map((materia) => (
                <MenuItem key={materia.materia_id} value={materia.materia_id}>
                  {materia.materia}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

FiltrosVideoAula.propTypes = {
  returnFiltros: PropTypes.func.isRequired,
  filtrosAplicados: PropTypes.shape().isRequired,
};
