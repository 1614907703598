import produce from 'immer';

const INITIAL_STATE = {
  conteudo: null,
  aulas: null,
};

export default function videoaula(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@videoaula/CHOOSED': {
        draft.conteudo = action.payload.aula;
        break;
      }
      case '@videoaula/CLEAN': {
        draft.conteudo = null;
        break;
      }
      case '@videoaula/REQUEST_SUCCESS': {
        draft.aulas = action.payload.aulas;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.conteudo = null;
        break;
      }
      default:
    }
  });
}
