import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { loockupRequestSuccess, loockupRequestFailure } from './actions';

export function* request() {
  try {
    const response = yield call(api.get, 'lookup');

    yield put(loockupRequestSuccess(response.data));
  } catch (err) {
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(loockupRequestFailure());
  }
}

export default all([
  takeLatest('@loockup/LOOCKUP_REQUEST', request),
  takeLatest('@unidade/SIGN_IN_SUCCESS', request),
  takeLatest('@conteudos/REQUEST', request),
  takeLatest('@auth/REHYDRATE_SUCCESS', request),
]);
