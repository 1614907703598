export function loockupRequest() {
  return {
    type: '@loockup/LOOCKUP_REQUEST',
  };
}

export function loockupRequestSuccess(loockup) {
  return {
    type: '@loockup/LOOCKUP_REQUEST_SUCCESS',
    payload: { loockup },
  };
}

export function loockupRequestFailure() {
  return {
    type: '@loockup/LOOCKUP_REQUEST_FAILURE',
  };
}
