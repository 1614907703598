import { takeLatest, put, all } from 'redux-saga/effects';
// import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import fxCall from '~/services/callWrapper';

import { videoAulasRequestSuccess, aulaChoosed } from './actions';

export function* getVideoAulas() {
  try {
    const response = yield fxCall(api.get, 'video-aulas');

    const aulaAgora = response.data.filter(
      (videoAula) => videoAula.aovivo === true
    );

    yield put(videoAulasRequestSuccess(response.data));

    if (aulaAgora && aulaAgora.length > 0) {
      yield put(aulaChoosed(aulaAgora[0]));
      history.push('video-aulas');
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.tron.log(err);
    // toast.error('Erro ao recuperar os contratos, contacte o suporte');
  }
}

export function* changeStatusVisualizado({ payload }) {
  try {
    const { videoaula_id } = payload.aula;

    yield fxCall(api.put, `video-aula/status/${videoaula_id}`);
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.tron.log(err);
  }
}

export default all([
  takeLatest('@unidade/SIGN_IN_SUCCESS', getVideoAulas),
  takeLatest('@auth/REHYDRATE_SUCCESS', getVideoAulas),
  takeLatest('@videoaula/CHOOSED', changeStatusVisualizado),
]);
