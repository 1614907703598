import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';

import { usePauta } from '~/hooks/pauta';
import { useMateria } from '~/hooks/materia';

import { aulaChoosed } from '~/store/modules/aula/actions';

import IconPdf from '~/assets/img_grafismo_pdf.png';
import IconLink from '~/assets/img_grafismo_link.png';
import IconPodcast from '~/assets/img_grafismo_podcast.png';
import IconVideo from '~/assets/img_grafismo_video.png';

import history from '~/services/history';

export default function CardConteudoAula({
  aula,
  conteudo,
  contrato,
  materia,
  turma,
}) {
  const conteudoEscolhido = useSelector((state) => state.aula.conteudo);
  const { salvarAulaMateria } = useMateria();

  const useStyles = makeStyles(() => ({
    newStyle: {},
    card: {
      marginTop: '5px',
      width: '100%',
      cursor: 'pointer',
      border: `${
        conteudoEscolhido && conteudoEscolhido === conteudo
          ? '2px solid blue'
          : 'none'
      }`,
    },
    iconConteudo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconNavigate: {
      display: 'flex',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const { salvarPresenca } = usePauta();

  const [conteudosAssistidos, setConteudosAssistidos] = useState(
    aula.conteudos_aula
      .filter(({ visualizado }) => visualizado)
      .reduce(
        (p, { smartconteudosmart_id }) => [...p, smartconteudosmart_id],
        []
      )
  );

  const conteudosEad = ((conteudos, isEad) => {
    let value = [];
    if (isEad && Array.isArray(conteudos) && conteudos.length > 0) {
      value = conteudos.reduce(
        (p, { smartconteudosmart_id }) => [...p, smartconteudosmart_id],
        []
      );
    }
    return value;
  })(aula.conteudos_aula, aula.ead);

  const marcarPresenca = useCallback(async () => {
    const data = await salvarPresenca({
      turma,
      materia,
      aula: aula.aula_id,
      contrato,
    });

    if (data) {
      aula.data = data.data_aula;
    }
  }, [aula, contrato, materia, salvarPresenca, turma]);

  useEffect(() => {
    if (
      !aula.data &&
      conteudosEad.length > 0 &&
      conteudosEad.length === conteudosAssistidos.length
    ) {
      marcarPresenca();
    }
  }, [conteudosEad, aula, marcarPresenca, conteudosAssistidos.length]); // eslint-disable-line

  useEffect(() => {
    if (
      conteudoEscolhido &&
      !conteudoEscolhido.visualizado &&
      !aula.data &&
      aula.ead
    ) {
      const updatedConteudosAula = aula.conteudos_aula;
      const indexUpdated = updatedConteudosAula.findIndex(
        (conteudoAula) =>
          conteudoAula.smartconteudosmart_id ===
          conteudoEscolhido.smartconteudosmart_id
      );
      updatedConteudosAula[indexUpdated] = {
        ...updatedConteudosAula[indexUpdated],
        visualizado: new Date(),
      };
      salvarAulaMateria(materia, {
        ...aula,
        conteudos_aula: updatedConteudosAula,
      });
    }
  }, [conteudoEscolhido]); //eslint-disable-line

  function handleClick(aulaEscolhida) {
    const newAulaEscolhida = { ...aulaEscolhida };

    if (!newAulaEscolhida.aula) {
      newAulaEscolhida.aula = aula.aula;
    }
    setConteudosAssistidos([
      ...conteudosAssistidos,
      newAulaEscolhida.smartconteudosmart_id,
    ]);
    dispatch(aulaChoosed(newAulaEscolhida));

    if (newAulaEscolhida.tipo === 15) {
      history.push(`/questionario/${newAulaEscolhida.smartconteudosmart_id}`);
    }
  }

  function getIcon(tipo) {
    switch (tipo) {
      case 1:
        return IconPdf;
      case 2:
        return IconVideo;
      case 3:
        return IconPodcast;
      default:
        return IconLink;
    }
  }

  return (
    <>
      <Card
        key={conteudo.smartconteudo_id}
        className={classes.card}
        onClick={() => {
          handleClick(conteudo);
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={4} className={classes.iconConteudo}>
            <Image
              src={getIcon(conteudo.tipo)}
              style={{
                display: 'flex',
                paddingTop: 'unset',
              }}
              imageStyle={{
                width: 'unset',
                height: 'unset',
                position: 'unset',
                maxHeight: '50px',
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="caption" component="p">
              {conteudo.titulo}
            </Typography>
            <Typography variant="caption" component="p">
              {conteudo.descricao}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.iconNavigate}>
            <NavigateNext fontSize="large" />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

CardConteudoAula.propTypes = {
  conteudo: PropTypes.shape().isRequired,
  aula: PropTypes.shape().isRequired,
  contrato: PropTypes.number.isRequired,
  turma: PropTypes.number.isRequired,
  materia: PropTypes.number.isRequired,
};
