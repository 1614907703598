import produce from 'immer';

const INITIAL_STATE = {
  data: null,
  cobranca: null,
  loading: false,
};

export default function financeiro(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financeiro/LOAD_INSTALLMENTS_SUCCESS': {
        draft.data = action.payload.installments;
        break;
      }
      case '@financeiro/LOAD_INSTALLMENTS_ERROR': {
        draft.data = null;
        break;
      }
      case '@financeiro/LOAD_BILLING': {
        draft.loading = true;
        break;
      }
      case '@financeiro/LOAD_BILLING_SUCCESS': {
        draft.cobranca = action.payload.cobranca;
        draft.loading = false;
        break;
      }
      case '@financeiro/LOAD_BILLING_ERROR': {
        draft.cobranca = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
