export function createPix(unidade_id, lista_receber) {
  return {
    type: '@pagamento/CREATE_PIX',
    payload: { unidade_id, lista_receber },
  };
}

export function createPixSuccess(pix) {
  return {
    type: '@pagamento/CREATE_PIX_SUCCESS',
    payload: { pix },
  };
}

export function getDataPagamento(unidade_id, receber_id) {
  return {
    type: '@pagamento/GET_DATA_PAGAMENTO',
    payload: { unidade_id, receber_id },
  };
}

export function getDataPagamentoSuccess(data) {
  return {
    type: '@pagamento/GET_DATA_PAGAMENTO_SUCCESS',
    payload: { data },
  };
}

export function clearDataPix() {
  return {
    type: '@pagamento/CLEAR_DATA_PIX',
  };
}
