import produce from 'immer';

const INITIAL_STATE = {
  data: [],
};

export default function conteudowatched(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@conteudowatched/ADD_WATCH_DATA': {
        const index = draft.data.findIndex(
          (content) => content.id === action.payload.data.id
        );
        if (index < 0) {
          draft.data = [...draft.data, action.payload.data];
        }
        break;
      }
      case '@conteudowatched/UPDATE_WATCH_DATA': {
        const index = draft.data.findIndex(
          (content) => content.id === action.payload.data.id
        );
        draft.data[index] = action.payload.data;
        break;
      }
      default:
    }
  });
}
