import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '~/components/CustomUI/TextField';
import { signInRequest } from '~/store/modules/auth/actions';
import { useLoading } from '~/hooks/loading';
// import history from '~/services/history';
import PasswordField from './Password';

const schema = Yup.object().shape({
  login: Yup.string().required('O login é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

const useStyles = makeStyles(() => ({
  btAcessar: {
    borderRadius: '200px',
    marginTop: '27px',
  },
  input: {
    marginBottom: '20px',
  },
  forgot: {
    textAlign: 'center',
    marginTop: '10px',
    width: '100%',
  },
}));

const TextMaskCustom = (props) => {
  // eslint-disable-next-line
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
};

export default function SignIn() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formRef = useRef(null);
  const { showLoading, hideLoading } = useLoading();

  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('auth');

  async function handleSignIn(login, password, token = null) {
    try {
      showLoading();
      if (login) {
        login = login.replace(/\D/g, '');
      }
      await dispatch(signInRequest(login, password, token));
      hideLoading();
    } catch (error) {
      hideLoading();
    }
  }

  useEffect(() => {
    if (accessToken) {
      handleSignIn(null, null, accessToken);
    }
  }, [accessToken]); // eslint-disable-line

  async function handleSubmit({ login, password }) {
    handleSignIn(login, password);
  }

  return (
    <>
      <Form schema={schema} onSubmit={handleSubmit} ref={formRef}>
        <TextField
          id="login"
          name="login"
          type="text"
          label="Celular"
          InputProps={{ inputComponent: TextMaskCustom }}
          fullWidth
          color="primary"
          variant="filled"
          className={classes.input}
        />
        <PasswordField />
        {/* <Link
          component="button"
          variant="body2"
          color="primary"
          onClick={() => {
            history.push('/recover');
          }}
          fullWidth
          className={classes.forgot}
        >
          Esqueci minha senha
        </Link> */}
        {!accessToken && (
          <Button
            type="submit"
            color="primary"
            fullWidth
            className={classes.btAcessar}
            variant="contained"
          >
            ACESSAR
          </Button>
        )}
      </Form>
    </>
  );
}
