import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

// import AuthConfig from '~/config/AuthConfig';
import history from '~/services/history';
import api from '~/services/api';

import {
  unidadeOnSignInSuccess,
  unidadeChooseSuccess,
} from '~/store/modules/unidade/actions';
import {
  signInSuccess,
  signFailure,
  signOut,
  signInSuccessSetUserId,
  reHydrateSuccess,
} from './actions';
import { getFuncionalidades } from '../funcionalidades/actions';

export function* signIn({ payload }) {
  console.log('AUTH.SAGAS.singIn');
  try {
    const { login, password, accessToken } = payload;

    const data = {};

    if (accessToken) {
      // jwt.verify(accessToken, AuthConfig.secret);
      data.accessToken = accessToken;
    } else {
      data.login = login;
      data.password = password;
    }

    const response = yield call(api.post, 'sessions', data);

    const { token, aluno } = response.data;

    const { usuario_id } = jwtDecode(token);

    yield put(signInSuccess(token, aluno));
    yield put(signInSuccessSetUserId(usuario_id));

    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (aluno.unidades.length > 1) {
      history.push(`/unidades${accessToken ? `/?auth=${accessToken}` : ''}`);
      return;
    }
    const { unidade_id, unidade } = aluno.unidades[0];
    api.defaults.headers.unidade_header = unidade_id;
    yield call(api.put, 'usuario');
    const responseTimezone = yield call(api.get, 'timezone');
    const { timezone } = responseTimezone.data[0];

    const responseAluno = yield call(api.get, 'aluno');

    yield put(unidadeChooseSuccess(responseAluno.data));
    yield put(unidadeOnSignInSuccess({ unidade_id, unidade, timezone }));
    yield put(getFuncionalidades());

    if (accessToken) {
      history.push('/contratos');
    } else {
      history.push('/dashboard');
    }
  } catch (err) {
    // toast.error(JSON.stringify(err.response));
    if (err.name && err.name === 'TokenExpiredError') {
      toast.error('Sua sessão expirou.');
      history.push('/');
    } else if (err.response && err.response.data && err.response.data.error) {
      toast.error(err.response.data.error);
      history.push('/');
    } else {
      toast.error('Falha na autenticação, verifique seus dados.');
    }
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  try {
    if (!payload || !payload.auth.token) {
      return;
    }

    console.log('AUTH.SAGAS.setToken: ');

    const { token: accessToken } = payload.auth;
    // const { unidade_id } = payload.unidade.data.unidade;
    if (accessToken) {
      const data = { accessToken };
      api.defaults.headers.Authorization = `Bearer ${accessToken}`;
      const { unidade } = payload;
      if (!unidade) {
        const response = yield call(api.post, 'sessions', data);

        const { token, aluno } = response.data;
        const { usuario_id } = jwtDecode(token);

        yield put(signInSuccess(token, aluno));
        yield put(signInSuccessSetUserId(usuario_id));

        history.push(`/unidades${accessToken ? `/?auth=${accessToken}` : ''}`);
        return;
      }

      const { data: dataUnidade } = unidade;
      api.defaults.headers.unidade_header = dataUnidade.unidade_id;
      yield call(api.put, 'usuario');
      const responseTimezone = yield call(api.get, 'timezone');
      const { timezone } = responseTimezone.data[0];

      const responseAluno = yield call(api.get, 'aluno');

      yield put(unidadeChooseSuccess(responseAluno.data));
      yield put(
        unidadeOnSignInSuccess({
          unidade: dataUnidade.unidade,
          timezone,
        })
      );
      yield put(getFuncionalidades());
      history.push('/dashboard');
      yield put(reHydrateSuccess());
    }
  } catch (err) {
    toast.error('Sessão expirada.');
    history.push('/');
    yield put(signOut());
    // return;
  }
  // try {
  //   const { usuario_id } = jwt.verify(token, AuthConfig.secret);
  //   yield put(signInSuccessSetUserId(usuario_id));
  //   if (token) {
  //     history.push('/contratos');
  //   }
  // } catch (error) {
  //   toast.error('Sessão expirada.');
  //   history.push('/');
  //   yield put(signOut());
  //   return;
  // }

  // if (token && unidade_id) {
  //   const responsePermissoes = yield call(api.get, 'access');
  //   const { videofront } = jwt.verify(
  //     responsePermissoes.data.token,
  //     AuthConfig.secret
  //   );
  //
  //   yield put(loadPermissionSuccess({ videofront }));
  // }

  // yield put(reHydrateSuccess());
}

export function logout() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', logout),
]);
