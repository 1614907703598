import React from 'react';
import { Switch } from 'react-router-dom';
import SignIn from '~/pages/SignIn';
import Unidade from '~/pages/Unidade';
import Contratos from '~/pages/Contratos';
import Turmas from '~/pages/Turmas';
import Materias from '~/pages/Materias';
import Financeiro from '~/pages/Financeiro';
import Conteudos from '~/pages/Conteudos';
import Dashboard from '~/pages/Dashboard';
import Cursos from '~/pages/Cursos';
import VejaEmCasa from '~/pages/VejaEmCasa';
import VideoAulas from '~/pages/VideoAulas';
import PendenteAceite from '~/pages/PendenteAceite';
import DetalhesContrato from '~/pages/DetalhesContrato';
import Notas from '~/pages/Notas';
import Atividades from '~/pages/Atividades';
import Pagamento from '~/pages/Pagamento';
import Conteudo from '~/pages/Conteudo';
import Questionario from '~/pages/Questionario';
import Pacotes from '~/pages/Pacotes';
// import Forum from '~/pages/Forum';
// import Post from '~/pages/Forum/Post';
import Route from './Route';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/unidades" exact component={Unidade} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/contratos" exact component={Contratos} isPrivate />
      <Route path="/turmas" exact component={Turmas} isPrivate />
      <Route path="/materias/:turma" exact component={Materias} isPrivate />
      <Route path="/materias" exact component={Materias} isPrivate />
      <Route path="/financeiro" exact component={Financeiro} isPrivate />
      <Route path="/conteudos" exact component={Conteudos} isPrivate />
      <Route path="/notas" exact component={Notas} isPrivate />
      <Route path="/veja-em-casa" exact component={VejaEmCasa} isPrivate />
      <Route path="/video-aulas" exact component={VideoAulas} isPrivate />
      <Route path="/atividades" exact component={Atividades} isPrivate />
      <Route
        path="/pendente-aceite/:contrato"
        exact
        component={PendenteAceite}
        isPrivate
      />
      <Route
        path="/detalhes-contrato/:contrato"
        exact
        component={DetalhesContrato}
        isPrivate
      />
      <Route path="/cursos/:contrato" exact component={Cursos} isPrivate />
      <Route
        path="/pagamento/:id_receber"
        exact
        component={Pagamento}
        isPrivate
      />
      <Route path="/conteudo/:contrato" exact component={Conteudo} isPrivate />
      <Route
        path="/conteudo/:contrato/:curso"
        exact
        component={Conteudo}
        isPrivate
      />
      <Route
        path="/contrato/:contrato/pacote/:pacote/conteudo"
        exact
        component={Conteudo}
        isPrivate
      />
      <Route
        path="/conteudo/:contrato/:curso/:turma"
        exact
        component={Conteudo}
        isPrivate
      />
      <Route
        path="/questionario/:atividadeLancada"
        exact
        component={Questionario}
        isPrivate
      />
      <Route path="/pacotes/:contrato" exact component={Pacotes} isPrivate />
      {/* <Route path="/forum" exact component={Forum} isPrivate />
      <Route path="/forum/:id" exact component={Post} isPrivate /> */}
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
