export function onSelect(unidade, token = null) {
  return {
    type: '@unidade/ON_SELECT',
    payload: { unidade, token },
  };
}

export function unidadeOnSignInSuccess(unidade) {
  return {
    type: '@unidade/SIGN_IN_SUCCESS',
    payload: { unidade },
  };
}

export function unidadeChooseSuccess(aluno) {
  return {
    type: '@unidade/UNIDADE_CHOOSE_SUCCESS',
    payload: { aluno },
  };
}

export function loadPermissionSuccess(permissions) {
  return {
    type: '@unidade/UNIDADE_LOAD_PERMISSIONS_SUCCESS',
    payload: { permissions },
  };
}
