import produce from 'immer';

const INITIAL_STATE = {
  conteudo: null,
  mode: 'default',
};

export default function aula(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@aula/CHOOSED': {
        draft.conteudo = action.payload.aula;
        break;
      }
      case '@aula/CHANGE_MODE': {
        draft.mode = action.payload.mode;
        break;
      }
      case '@aula/CLEAN': {
        draft.conteudo = null;
        break;
      }
      case '@aula/CHOOSED_SUCCESS': {
        draft.conteudo = action.payload.aula;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.conteudo = null;
        break;
      }
      default:
    }
  });
}
