import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  Typography,
  Divider,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';

import { format } from 'date-fns';
import { loadInstallments } from '~/store/modules/financeiro/actions';
import ModalPix from '~/components/Financeiro/ModalPix';

const useStyles = makeStyles({
  titulo: {
    color: '#4D5884',
    marginBottom: '15px',
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#3E98C7',
  },
  value: {
    fontWeight: 'bold',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  summary: {
    fontWeight: 'bold',
  },
  accordion: {
    paddingBottom: '20px',
  },
});

export default function Pagamento({ contrato }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const installments = useSelector((state) => state.financeiro.data);

  useEffect(() => {
    if (
      !installments ||
      !installments.cache ||
      new Date() - installments.cache > 60000
    ) {
      dispatch(loadInstallments(contrato.contrato_id));
    }
  }, [installments]); // eslint-disable-line

  const getDetails = (installment) => {
    return (
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="caption" className={classes.label}>
              Status:
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {installment.status}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="caption" className={classes.label}>
              Tipo:
            </Typography>
            <Typography variant="body1">{installment.tipoparcela}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="caption" className={classes.label}>
              Vencimento:
            </Typography>
            <Typography variant="body1">
              {format(new Date(installment.vencimento), 'dd/MM/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography variant="caption" className={classes.label}>
              Valor:
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(installment.valor)}
            </Typography>
          </Grid>
          {[3, 4].includes(installment.pix_status) && installment.older && (
            <Grid
              item
              xs={12}
              sm={4}
              md={12}
              lg={1}
              className={classes.gridButton}
            >
              <ModalPix
                receber_id={installment.receber_id}
                status={installment.status}
              />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    );
  };

  return (
    <>
      <Typography variant="h6" className={classes.titulo}>
        Resumo de pagamento
      </Typography>
      <Divider />
      {installments && installments.abertas && (
        <Accordion square expanded className={classes.accordion}>
          <AccordionSummary>
            <Typography
              variant="body1"
              color="secondary"
              className={classes.summary}
            >
              Próximo Vencimento
            </Typography>
          </AccordionSummary>
          {installments.abertas && installments.abertas.length === 0 && (
            <Typography variant="body1" align="center">
              Nenhuma parcela encontrada
            </Typography>
          )}
          {installments.abertas
            .filter((installment) => installment.older)
            .map((installment) => getDetails(installment))}
        </Accordion>
      )}

      {installments && installments.abertas && (
        <Accordion square className={classes.accordion}>
          <AccordionSummary>
            <Typography
              variant="body1"
              color="secondary"
              className={classes.summary}
            >
              Demais Parcelas do Contrato
            </Typography>
          </AccordionSummary>
          {installments.abertas && installments.abertas.length === 0 && (
            <Typography variant="body1" align="center">
              Nenhuma parcela encontrada
            </Typography>
          )}
          {installments.abertas
            .filter((installment) => !installment.older)
            .map((installment) => getDetails(installment))}
        </Accordion>
      )}

      {installments && installments.pagas && (
        <Accordion square className={classes.accordion}>
          <AccordionSummary>
            <Typography
              variant="body1"
              color="secondary"
              className={classes.summary}
            >
              Parcelas Quitadas
            </Typography>
          </AccordionSummary>
          {installments.pagas && installments.pagas.length === 0 && (
            <Typography variant="body1" align="center">
              Nenhuma parcela encontrada
            </Typography>
          )}
          {installments.pagas.map((installment) => getDetails(installment))}
        </Accordion>
      )}
    </>
  );
}

Pagamento.propTypes = {
  contrato: PropTypes.shape().isRequired,
};
