import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
  Grid,
  IconButton,
  Typography,
  Menu as MuiMenu,
  Divider,
  Badge,
} from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import Image from 'material-ui-image';
import { format, formatDistanceToNow, isToday, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';

import IconEventos from '~/assets/ic_eventos.svg';
import IconGeral from '~/assets/ic_geral.svg';
import IconLink from '~/assets/ic_link.svg';
import IconPdf from '~/assets/ic_pdf.svg';
import IconPodcast from '~/assets/ic_podcast.svg';
import IconVideo from '~/assets/ic_video.svg';

const useStyles = makeStyles({
  muiMenu: {
    padding: 0,
  },
  notificacoes: {
    display: 'flex',
    color: '#333',
    alignItens: 'center',
    justifyContent: 'flex-end',
  },
  menu: {
    width: '366px',
    maxHeight: '654px',
  },
  gridNovas: {
    padding: '25px',
    height: 'fit-content',
  },
  gridAnteriores: {
    background: 'rgba(189, 192, 212, 0.15)',
    padding: '25px',
    height: 'fit-content',
  },
  politica: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    color: '#868CAB',
    marginBottom: '10px',
  },
  notifyButton: {
    color: '#868CAB',
    borderRadius: '50%',
    padding: '4px',
    fontSize: '33px',
  },
  badge: {
    '& > .MuiBadge-anchorOriginTopRightRectangle': {
      top: '11px',
      right: '11px',
      background: '#F71249',
      minWidth: '5px',
      height: '5px',
    },
  },
  data: {
    fontSize: '9px',
    lineHeight: '17px',
    letterSpacing: '0.75px',
    color: '#465471',
  },
  mensagem: {
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '17px',
    letterSpacing: '0.75px',
    color: '#465471',
    margin: '10px 0',
  },
  tituloNotificacoes: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#50A6FF',
    marginBottom: '28px',
  },
  gridIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  gridData: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconLabel: {
    fontSize: '11px',
    marginLeft: '5px',
  },
  colorVideo: {
    color: '#D5322E',
  },
  colorGeral: {
    color: '#FF6B00',
  },
  colorVejaEmCasa: {
    color: '#FF5C7E',
  },
  colorEventos: {
    color: '#465471',
  },
  colorPdf: {
    color: '#6B41E0',
  },
  colorPodcast: {
    color: '#C14CD4',
  },
  colorLink: {
    color: '#777190',
  },
});

export default function Notificacoes() {
  const classes = useStyles();
  const notificacoes = useSelector((state) => state.notificacoes.data);
  const [showNotificacoes, setShowNotificacoes] = useState(null);
  const [hasNewNotification, setHasNewNotificacoes] = useState(false);

  useEffect(() => {
    if (notificacoes) {
      const novas = notificacoes.filter(
        (notificacao) => !notificacao.visualizada
      );
      setHasNewNotificacoes(!!novas.length);
    }
  }, [notificacoes]); //eslint-disable-line

  const handleClickNotificacoes = (event) => {
    setShowNotificacoes(event.currentTarget);
  };

  const handleCloseNotificacoes = () => {
    setShowNotificacoes(null);
  };

  const getDataFormatada = (data) => {
    const parsedDate = parseISO(data.replace('.000Z', ''));
    const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo');

    if (isToday(znDate)) {
      return formatDistanceToNow(znDate, {
        includeSeconds: true,
        addSuffix: 'true',
        locale: ptBR,
      });
    }

    return format(znDate, "dd/MM/yyyy à's' HH:mm", { locale: ptBR });
  };

  const montarTipoNotificacao = (icone, label, cor) => {
    return (
      <>
        <Image
          src={icone}
          style={{
            display: 'flex',
            paddingTop: 'unset',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
            maxHeight: '50px',
          }}
        />
        <Typography component="p" className={clsx(classes.iconLabel, cor)}>
          {label}
        </Typography>
      </>
    );
  };

  const getTipoNotificacao = (tipo, tipoConteudo) => {
    switch (tipo) {
      // case 2: return montarTipoNotificacao(IconGeral, '#FF6B00');
      case 3:
      case 4:
        switch (tipoConteudo) {
          case 1:
            return montarTipoNotificacao(IconPdf, 'EBOOK', classes.colorPdf);
          case 2:
            return montarTipoNotificacao(
              IconVideo,
              'VÍDEO',
              classes.colorVideo
            );
          case 3:
            return montarTipoNotificacao(
              IconPodcast,
              'PODCAST',
              classes.colorPodcast
            );
          default:
            return montarTipoNotificacao(IconLink, 'LINK', classes.colorLink);
        }

      // case 5: return montarTipoNotificacao(IconGeral, '#FF6B00');
      // case 6: return montarTipoNotificacao(IconGeral, '#FF6B00');
      // case 7: return montarTipoNotificacao(IconGeral, '#FF6B00');
      // case 8: return montarTipoNotificacao(IconGeral, '#FF6B00');
      // case 9: return montarTipoNotificacao(IconGeral, '#FF6B00');
      case 10:
        return montarTipoNotificacao(
          IconEventos,
          'EVENTO',
          classes.colorEventos
        );
      default:
        return montarTipoNotificacao(IconGeral, 'GERAL', classes.colorGeral);
    }
  };

  return (
    <div className={classes.notificacoes}>
      <IconButton onClick={handleClickNotificacoes}>
        {(hasNewNotification && (
          <Badge className={classes.badge} badgeContent=" " variant="dot">
            <Notifications className={classes.notifyButton} />
          </Badge>
        )) || <Notifications className={classes.notifyButton} />}
      </IconButton>
      <MuiMenu
        anchorEl={showNotificacoes}
        keepMounted
        open={!!showNotificacoes}
        onClose={handleCloseNotificacoes}
        className={classes.muiMenu}
      >
        <Grid container className={classes.menu}>
          <Grid item xs={12} className={classes.gridNovas}>
            <Typography component="p" className={classes.tituloNotificacoes}>
              Novas notificações
            </Typography>
            {notificacoes &&
              notificacoes
                .filter((notificacao) => !notificacao.visualizada)
                .map((notificacao) => (
                  <Grid
                    container
                    spacing={2}
                    key={notificacao.notificacao_smart_id}
                  >
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6} className={classes.gridIcon}>
                          {getTipoNotificacao(notificacao.tipo_notificacao)}
                        </Grid>
                        <Grid item xs={6} className={classes.gridData}>
                          <Typography component="p" className={classes.data}>
                            {notificacao.datahora_envio &&
                              getDataFormatada(
                                notificacao.datahora_envio,
                                notificacao.tipo_conteudo
                              )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            className={classes.mensagem}
                          >
                            {notificacao.mensagem}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider variant="fullWidth" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
          </Grid>
          <Grid item xs={12} className={classes.gridAnteriores}>
            <Typography component="p" className={classes.tituloNotificacoes}>
              Notificações anteriores
            </Typography>
            {notificacoes &&
              notificacoes
                .filter((notificacao) => notificacao.visualizada)
                .map((notificacao) => (
                  <Grid
                    container
                    spacing={2}
                    key={notificacao.notificacao_smart_id}
                  >
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6} className={classes.gridIcon}>
                          {getTipoNotificacao(
                            notificacao.tipo_notificacao,
                            notificacao.tipo_conteudo
                          )}
                        </Grid>
                        <Grid item xs={6} className={classes.gridData}>
                          <Typography component="p" className={classes.data}>
                            {notificacao.datahora_envio &&
                              getDataFormatada(notificacao.datahora_envio)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            className={classes.mensagem}
                          >
                            {notificacao.mensagem}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider variant="fullWidth" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </MuiMenu>
    </div>
  );
}
