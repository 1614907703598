import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Fade,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Iframe from 'react-iframe';
import clsx from 'clsx';
import { darken } from 'polished';
import { Close } from '@material-ui/icons';

import {
  loadBilling,
  loadInstallments,
} from '~/store/modules/financeiro/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    borderRadius: '8px',
  },
  buttonCard: {
    color: '#fff',
    borderRadius: '200px',
    background: '#1629CC',
    fontSize: '11px',
    lineHeight: '16px',
    '&:hover': {
      background: `${darken(0.1, '#1629CC')}`,
    },
  },
  closeDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '5px',
  },
}));

function ModalPix({ receber_id, status }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagamento = useSelector((state) => state.financeiro.cobranca);
  const loading = useSelector((state) => state.financeiro.loading);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    dispatch(loadBilling(receber_id));
    // setOpen(true);
  };

  const handleClose = () => {
    dispatch(loadInstallments());
    setOpen(false);
  };

  const getButtonClass = () => {
    switch (status) {
      case 'Processando':
        return clsx(classes.buttonCard, {
          background: '#6B41E0',
          '&:hover': {
            background: `${darken(0.1, '#6B41E0')}`,
          },
        });
      case 'Problemas no Pagamento':
        return clsx(classes.buttonCard, {
          background: '#FF9239',
          '&:hover': {
            background: `${darken(0.1, '#FF9239')}`,
          },
        });
      default:
        return clsx(classes.buttonCard, { background: '#1629CC' });
    }
  };

  const getLabelButton = () => {
    if (status === 'Processando' || status === 'Problemas no Pagamento') {
      return status;
    }

    return 'Pagar';
  };

  if (loading) {
    return (
      <Modal className={classes.modal} open={open} closeAfterTransition>
        <Fade in={open}>
          <CircularProgress color="primary" />
        </Fade>
      </Modal>
    );
  }

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        onClick={handleOpen}
        className={getButtonClass(status)}
      >
        {getLabelButton(status)}
      </Button>
      <Modal className={classes.modal} open={open} closeAfterTransition>
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.closeDiv}>
              <IconButton size="small" onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
            {pagamento && (
              <Iframe
                src={pagamento.url}
                styles={{
                  maxHeight: 800,
                  maxWidth: 800,
                  width: window.innerWidth - 30,
                  height: window.innerWidth > 800 ? 570 : 670,
                  overflow: 'auto',
                }}
                frameBorder={0}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalPix.propTypes = {
  receber_id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default ModalPix;
