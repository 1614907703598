import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { darken } from 'polished';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import history from '~/services/history';

import { request } from '~/store/modules/conteudos/actions';

const useStyles = makeStyles((theme) => ({
  titulo: {
    marginTop: '17px',
    color: '#4D5884',
    fontSize: '24px',
    lineHeight: '28px',
  },
  subtitulo: {
    color: '#50A6FF',
    fontSize: '20px',
    lineHeight: '23px',
    marginBottom: '19px',
  },
  formControl: {
    margin: theme.spacing(2, 0),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  porcentagem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contrato: {
    padding: theme.spacing(2, 3),
    borderRadius: '10px',
    border: '2px solid #50A6FF',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  turmas: {
    padding: theme.spacing(1, 2),
  },
  progressoCurso: {
    width: 70,
  },
  progressoTurma: {
    width: 60,
  },
  materialAtual: {
    background: '#4D5884',
    padding: '2px 6px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  quantidadeAulas: {
    color: '#868CAB',
    fontWeight: '500',
    marginLeft: '10px',
  },
  materia: {
    color: '#4D5884',
    fontSize: '17px',
    letterSpacing: '0.25',
    fontWeight: 'bold',
  },
  turma: {
    color: '#868CAB',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  gridBtConteudo: {
    margin: '15px 0',
  },
  btConteudo: {
    borderRadius: '200px',
    background: '#50A6FF',
    color: '#fff',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  gridProgressoTurma: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridProgressoCurso: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  captionHeader: {
    color: '#868CAB',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pendenteAceite: {
    background: '#EC194C',
    padding: '2px 6px',
    borderRadius: '3px',
    color: '#fff',
    fontSize: '9px',
  },
  btAceite: {
    borderRadius: '200px',
    marginTop: '10px',
    color: '#fff',
  },
  infoHeader: {
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.15px',
    color: '#50A6FF',
    fontWeight: 500,
  },
}));

export default function Conteudos() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const conteudos = useSelector((state) => state.conteudos.data);

  useEffect(() => {
    async function getConteudos() {
      dispatch(request());
    }

    if (
      !conteudos ||
      (conteudos && !conteudos.cache) ||
      (conteudos && new Date() - conteudos.cache > 60000)
    ) {
      getConteudos();
    }
  }, [dispatch, conteudos]);

  function handleClickConteudos(contrato, cursoId, turmaId) {
    // eslint-disable-next-line prettier/prettier
    history.push(
      `/conteudo/${contrato}/${cursoId}${turmaId > 0 ? `/${turmaId}` : ''}`
    );
    // history.push(`/cursos/${contrato}`);
  }

  const handleClickPacotes = (contrato) => {
    history.push(`/pacotes/${contrato}`);
  };

  function handleClickAceite(contrato) {
    history.push(`/pendente-aceite/${contrato}`);
  }

  const showConteudoTurma = (turma, contrato) => {
    if (!turma) {
      return null;
    }

    return (
      <Grid item xs={12} key={turma.turma_id}>
        <Card className={classes.turmas} elevation={1}>
          <Grid container justifyContent="space-between">
            <Grid item xs={9}>
              <Typography variant="caption" className={classes.materialAtual}>
                MATÉRIA ATUAL
              </Typography>
              <Typography variant="caption" className={classes.quantidadeAulas}>
                {(!turma.aulas && ``) ||
                  (turma.aulas && turma.aulas < 2 && ` ${turma.aulas} AULA`) ||
                  (turma.aulas && ` ${turma.aulas} AULAS`)}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.materia}
              >
                {turma.materia}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.turma}
              >
                {turma.turma}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridBtConteudo}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  handleClickConteudos(
                    contrato.contrato_id,
                    contrato.curso_id,
                    turma.turma_id
                  );
                }}
                className={classes.btConteudo}
              >
                IR PARA CONTEÚDOS DA TURMA
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Breadcrumb funcionalidade="Conteúdos" />
      <Typography component="p" className={classes.titulo}>
        Conteúdos
      </Typography>
      <Typography component="p" className={classes.subtitulo}>
        Selecione uma matéria de curso para ver seus conteúdos
      </Typography>
      <Grid container spacing={2}>
        {conteudos &&
          conteudos.dados &&
          conteudos.dados.map((contrato) => (
            <Grid item xs={12} sm={4} key={contrato.contrato_id}>
              <Card className={classes.contrato} elevation={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={8}>
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.captionHeader}
                    >
                      CURSO{' '}
                      {contrato.pendenteaceite && (
                        <Typography
                          variant="caption"
                          className={classes.pendenteAceite}
                        >
                          PENDENTE ACEITE
                        </Typography>
                      )}
                    </Typography>
                    <Typography component="p" className={classes.infoHeader}>
                      {contrato.curso}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.captionHeader}
                    >
                      N° DO CONTRATO
                    </Typography>
                    <Typography component="p" className={classes.infoHeader}>
                      {contrato.numerocontrato}
                    </Typography>
                  </Grid>
                </Grid>
                {!contrato.pendenteaceite && !contrato.conteudobloqueado && (
                  <>
                    {!contrato.naoexibirconteudoscurso &&
                      contrato.contrato_id > 0 && (
                        <>
                          <br />
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (contrato.usasequenciapacotes) {
                                handleClickPacotes(contrato.contrato_id);
                              } else {
                                handleClickConteudos(
                                  contrato.contrato_id,
                                  contrato.curso_id
                                );
                              }
                            }}
                            className={classes.btConteudo}
                          >
                            VER TODOS OS CONTEÚDOS
                          </Button>
                        </>
                      )}
                    <Divider className={classes.divider} variant="middle" />
                    <Typography component="p" className={classes.captionHeader}>
                      MATÉRIAS
                    </Typography>
                    <Grid container justifyContent="space-between">
                      {(Array.isArray(contrato.turmas) &&
                        contrato.turmas.map((turma) =>
                          showConteudoTurma(turma, contrato)
                        )) ||
                        showConteudoTurma(contrato.turmas, contrato)}
                    </Grid>
                  </>
                )}
                {!contrato.pendenteaceite && contrato.conteudobloqueado && (
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.btAceite}
                    color="primary"
                    disabled
                  >
                    ACESSO DESABILITADO
                  </Button>
                )}
                {contrato.pendenteaceite && contrato.istitular && (
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.btAceite}
                    color="primary"
                    onClick={() => {
                      handleClickAceite(contrato.contrato_id);
                    }}
                  >
                    IR PARA O ACEITE DE CONTRATO
                  </Button>
                )}
                {contrato.pendenteaceite && !contrato.istitular && (
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.btAceite}
                    color="grey"
                    disabled
                  >
                    PENDENTE ACEITE DO TITULAR
                  </Button>
                )}
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
