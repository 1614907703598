import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';

import { LoadingProvider } from '~/hooks/loading';
import { PautaProvider } from '~/hooks/pauta';
import { MateriaProvider } from '~/hooks/materia';

export default function AppProvider({ children }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
      <LoadingProvider>
        <MateriaProvider>
          <PautaProvider>{children}</PautaProvider>
        </MateriaProvider>
      </LoadingProvider>
    </MuiPickersUtilsProvider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
