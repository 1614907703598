import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function Logo({ width }) {
  const instituicao = useSelector((state) => state.instituicao.data);

  return (
    <img
      src={instituicao.logo_header}
      alt={instituicao && instituicao.nome}
      width={width}
    />
  );
}

Logo.propTypes = {
  width: PropTypes.number.isRequired,
};
