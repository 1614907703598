import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'portalaluno',
      storage,
      whitelist: ['auth', 'aluno', 'unidade', 'instituicao', 'conteudowatched'],
    },
    reducers
  );

  return persistedReducer;
};
