import { combineReducers } from 'redux';

import auth from './auth/reducer';
import aluno from './aluno/reducer';
import conteudos from './conteudos/reducer';
import contratos from './contratos/reducer';
import lookup from './lookup/reducer';
import turmas from './turmas/reducer';
import materias from './materias/reducer';
import trabalhos from './trabalhos/reducer';
import unidade from './unidade/reducer';
import instituicao from './instituicao/reducer';
import navigation from './navigation/reducer';
import notificacoes from './notificacoes/reducer';
import financeiro from './financeiro/reducer';
import aula from './aula/reducer';
import vejaemcasa from './vejaemcasa/reducer';
import videoaula from './videoaula/reducer';
import user from './user/reducer';
import pagamento from './pagamento/reducer';
import conteudowatched from './conteudowatched/reducer';
import funcionalidades from './funcionalidades/reducer';
import aceitecontrato from './aceitecontrato/reducer';
import questionario from './questionario/reducer';
import documents from './documents/reducer';

export default combineReducers({
  auth,
  aluno,
  conteudos,
  contratos,
  turmas,
  materias,
  trabalhos,
  unidade,
  instituicao,
  navigation,
  notificacoes,
  financeiro,
  aula,
  vejaemcasa,
  videoaula,
  user,
  lookup,
  pagamento,
  conteudowatched,
  funcionalidades,
  aceitecontrato,
  questionario,
  documents,
});
