import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import clsx from 'clsx';
import ModalRespostasQuestionario from '~/components/Atividades/ModalRespostasQuestionario';
import api from '~/services/api';
import history from '~/services/history';
import { chooseAtividade } from '~/store/modules/questionario/actions';
import ModalAtividade from './ModalAtividade';

const useStyles = makeStyles(() => ({
  atividades: {
    padding: '36px',
    maxHeight: '223px',
    overflow: 'auto',
  },
  atividade: {
    height: '151px',
    border: '2px solid #50A6FF',
    cursor: 'pointer',
  },
  titulo: {
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#50A6FF',
  },
  status: {
    borderRadius: '4px',
    color: '#fff',
    padding: '4px 8px',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '24px',
  },
  statusEnviado: {
    background: '#47D19D',
  },
  statusPendente: {
    background: '#EC184B',
  },
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#4D5884',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#868CAB',
  },
  gridAtividade: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 14px',
    height: '60%',
  },
  gridInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 14px',
    background: '#E3E4ED',
    height: '40%',
  },
  tituloAtividades: {
    margin: '10px 0',
    color: '#50A6FF',
    width: '640px',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
  },
}));

export default function Atividades() {
  const classes = useStyles();
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);
  const [openModalAnexo, setOpenModalAnexo] = useState(false);
  const [openModalQuestionario, setOpenModalQuestionario] = useState(false);
  const [atividadesLancadas, setAtividadesLancadas] = useState(null);
  const [atividadeLancada, setAtividadeLancada] = useState(null);

  const STATUS_ABERTA = 1;
  const STATUS_PENDENTE = 3;
  const STATUS_FAZER_NOVAMENTE = 5;

  useEffect(() => {
    async function getAtividadesLancadas() {
      const response = await api.get(
        `/atividades-lancadas/${conteudoEscolhido.videoaula_id}`
      );
      setAtividadesLancadas(response.data);
    }

    getAtividadesLancadas();
  }, [conteudoEscolhido]);

  const handleCloseModal = () => {
    setOpenModalAnexo(false);
    setAtividadeLancada(null);
  };

  const handleClick = (atividade) => {
    setAtividadeLancada(atividade);
    if (atividade.questionario) {
      if (atividade.realizaratividade_id) {
        setOpenModalQuestionario(true);
      } else {
        dispatchEvent(chooseAtividade(atividade));
        history.push(`/questionario/${atividade.lancaratividade_id}`);
      }
    } else {
      setOpenModalAnexo(true);
    }
  };

  const respostaDisponivel = (atividade) => {
    return (
      !atividade.status ||
      !atividade.data_resposta ||
      [STATUS_ABERTA, STATUS_PENDENTE, STATUS_FAZER_NOVAMENTE].includes(
        atividade.status
      )
    );
  };

  const removeTimezoneDate = (date) => {
    const dateFormatted = date.replace(/z/i, '');
    return dateFormatted;
  };

  return (
    <>
      {!!atividadesLancadas && !!atividadesLancadas.length && (
        <>
          <Typography component="p" className={classes.tituloAtividades}>
            ATIVIDADES
          </Typography>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={7}>
              <Paper elevation={1} className={classes.atividades}>
                <Grid container spacing={2}>
                  {atividadesLancadas.map((atividade) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={atividade.lancaratividade_id}
                    >
                      <Paper
                        className={classes.atividade}
                        onClick={() => handleClick(atividade)}
                      >
                        <Grid container className={classes.gridAtividade}>
                          <Grid item xs={12}>
                            <Typography
                              component="span"
                              className={clsx(
                                classes.status,
                                respostaDisponivel(atividade)
                                  ? classes.statusPendente
                                  : classes.statusEnviado
                              )}
                            >
                              {respostaDisponivel(atividade)
                                ? 'PENDENTE'
                                : `ENVIADO PELO ALUNO EM ${format(
                                    new Date(atividade.data_resposta),
                                    'dd/MM/yyyy',
                                    { locale: ptBR }
                                  )}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.titulo}>
                              {atividade.atividade}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.gridInfo}>
                          <Grid item xs={6}>
                            <Typography component="p" className={classes.label}>
                              Tipo
                            </Typography>
                            <Typography component="p" className={classes.value}>
                              {atividade.tipo_atividade}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="p" className={classes.label}>
                              Prazo
                            </Typography>
                            <Typography component="p" className={classes.value}>
                              {(atividade.prazo &&
                                format(
                                  new Date(removeTimezoneDate(atividade.prazo)),
                                  'dd/MM/yyyy',
                                  {
                                    locale: ptBR,
                                  }
                                )) ||
                                '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      {atividadeLancada && openModalAnexo && (
        <ModalAtividade
          open={openModalAnexo}
          handleClose={handleCloseModal}
          atividade={atividadeLancada}
        />
      )}
      {atividadeLancada && openModalQuestionario && (
        <ModalRespostasQuestionario
          open={openModalQuestionario}
          setOpen={setOpenModalQuestionario}
          realizarAtividadeId={atividadeLancada.realizaratividade_id}
        />
      )}
    </>
  );
}
