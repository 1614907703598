import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  SearchPanel,
  Paging,
  MasterDetail,
} from 'devextreme-react/data-grid';
import CurrencyFormatter from 'currency-formatter';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ParcelaDetalhada from './ParcelaDetalhada';

function GridParcelas({ parcelas }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  function prepareCell(e) {
    if (e.rowType === 'data' || e.rowType === 'detailAdaptive') {
      if (e.column.caption === 'Vencimento') {
        e.cellElement.innerText = format(
          new Date(e.data.vencimento),
          'dd/MM/yyyy',
          {
            locale: ptBR,
          }
        );
      }

      if (e.column.caption === 'Valor') {
        e.cellElement.innerText = CurrencyFormatter.format(e.data.valor, {
          code: 'BRL',
        });
      }

      if (e.column.dataField === 'calculo') {
        e.cellElement.innerText = CurrencyFormatter.format(e.data.calculo, {
          code: 'BRL',
        });
      }
    }
  }

  function hasColumnDesconto() {
    if (parcelas) {
      const parcelasDesconto = parcelas.filter(
        (parcela) =>
          parcela.calculo &&
          parcela.calculo > 0 &&
          parcela.valor !== parcela.calculo
      );
      return parcelasDesconto.length > 0;
    }
    return false;
  }

  return (
    <DataGrid
      dataSource={parcelas}
      allowColumnReordering
      showBorders
      onCellPrepared={(e) => {
        prepareCell(e);
      }}
    >
      <GroupPanel visible={!smallScreen} />
      <SearchPanel visible />
      <Grouping autoExpandAll={false} />
      <Paging defaultPageSize={100} />

      {smallScreen && <MasterDetail enabled component={ParcelaDetalhada} />}

      <Column caption="Tipo" dataField="tipo" dataType="string" />
      <Column caption="Mensalidade" dataField="parcela" dataType="string" />
      <Column
        caption="Vencimento"
        dataField="vencimento"
        dataType="string"
        visible={!smallScreen}
      />
      <Column
        caption="Valor"
        dataField="valor"
        dataType="string"
        visible={!smallScreen}
      />
      {parcelas && hasColumnDesconto() && (
        <Column
          caption="R$ com Desconto"
          dataField="calculo"
          dataType="string"
          visible={!smallScreen}
        />
      )}
    </DataGrid>
  );
}

GridParcelas.propTypes = {
  parcelas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default GridParcelas;
