import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { FilterList } from '@material-ui/icons';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import Conteudo from '~/components/VejaEmCasa/Conteudo';
import CardConteudoAula from '~/components/VejaEmCasa/CardConteudoAula';

import FiltrosVejaEmCasa from '~/components/VejaEmCasa/Filtros';
import Filtros from '~/components/Filtros';

import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';

export default function VejaEmCasa() {
  const [hideMenu, setHideMenu] = useState(false);
  const [showFiltros, setShowFiltros] = useState(true);
  const [filtros, setFiltros] = useState({
    tipo: undefined,
    data: undefined,
  });

  const useStyles = makeStyles({
    tituloFuncionalidade: {
      textTransform: 'uppercase',
      color: '#3E98C7',
      fontWeight: 'bold',
    },
    gridConteudo: {
      padding: '19px',
    },
    nomeAula: {
      color: '#777',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    descricaoAula: {
      color: '#999',
    },
    tituloAula: {
      color: '#3E98C7',
    },
    conteudo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    progresso: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    cards: (!hideMenu && {
      maxHeight: '700px',
      height: 'fit-content',
      overflow: 'auto',
      background: 'rgba(189, 192, 212, 0.19)',
    }) || {
      maxHeight: '700px',
      overflow: 'auto',
      background: 'rgba(189, 192, 212, 0.19)',
      display: 'flex',
      flexDirection: 'column',
      height: 'fit-content',
    },
    buttonHide: {
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.75px',
      color: '#868CAB',
      textTransform: 'capitalize',
    },
    showFiltros: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });

  const classes = useStyles();
  const conteudoEscolhido = useSelector((state) => state.vejaemcasa.conteudo);

  const [conteudos, setConteudos] = useState(null);

  useEffect(() => {
    async function getConteudos() {
      const response = await apiCallHandler(api.get, 'veja-em-casa', {
        params: filtros,
      });
      setConteudos(response.data);
    }

    getConteudos();
  }, [filtros]); //eslint-disable-line

  function handleChangeFiltros(novosFiltros) {
    setFiltros({ ...filtros, ...novosFiltros });
  }

  const handleChangeShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  return (
    <>
      <Breadcrumb categorias={['Conteúdos']} funcionalidade="Veja em casa" />
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.tituloFuncionalidade}>
            Conteúdos para ver em casa
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.showFiltros}>
          <Button
            size="small"
            type="button"
            onClick={handleChangeShowFiltros}
            className={classes.btShowFiltros}
          >
            <FilterList /> Filtros Avançados
          </Button>
        </Grid>
      </Grid>
      {showFiltros && (
        <Filtros
          returnFiltros={handleChangeFiltros}
          filtrosAplicados={filtros}
          form={
            <FiltrosVejaEmCasa
              returnFiltros={handleChangeFiltros}
              filtrosAplicados={filtros}
            />
          }
        />
      )}
      {conteudos && (
        <Grid container spacing={3} className={classes.gridConteudo}>
          <Grid
            item
            xs={12}
            sm={hideMenu ? 3 : 12}
            md={hideMenu ? 2 : 4}
            lg={hideMenu ? 1 : 3}
            className={classes.cards}
          >
            {hideMenu && (
              <IconButton
                onClick={() => {
                  setHideMenu(false);
                }}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            )}
            {!hideMenu && (
              <Button
                className={classes.buttonHide}
                startIcon={<MenuIcon fontSize="inherit" />}
                onClick={() => {
                  setHideMenu(true);
                }}
                fullWidth={hideMenu}
              >
                Esconder menu
              </Button>
            )}
            {conteudos &&
              conteudos.map((conteudoAula) => (
                <CardConteudoAula
                  key={conteudoAula.smartconteudo_id}
                  conteudo={conteudoAula}
                  aula={conteudoAula.titulo}
                  compact={hideMenu}
                />
              ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={hideMenu ? 9 : 12}
            md={hideMenu ? 10 : 8}
            lg={hideMenu ? 11 : 9}
            className={classes.conteudo}
          >
            {conteudoEscolhido && (
              <>
                <Typography variant="h6" className={classes.tituloAula}>
                  {conteudoEscolhido.titulo}
                </Typography>
                <Typography variant="body1" className={classes.descricaoAula}>
                  {conteudoEscolhido.descricao}
                </Typography>
              </>
            )}
            <Conteudo />
          </Grid>
        </Grid>
      )}
    </>
  );
}
