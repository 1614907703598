import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { limparConteudo } from '~/store/modules/videoaula/actions';

import Atividades from '~/components/VideoAulas/Atividades';
import ChatFullScreen from '../ChatFullScreen';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: '#50A6FF',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    position: 'relative',
    height: '100%',
    overflowX: 'hidden',
    padding: '3px',
  },
  descricaoAula: {
    color: '#4D5884',
    width: '640px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  labelDescricao: {
    margin: '10px 0',
    color: '#50A6FF',
    width: '640px',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
  },
  btChat: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    left: 10,
    right: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  containerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConteudoFullScreen({ conteudo, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const handleClose = () => {
    dispatch(limparConteudo());
  };

  const isVideoConferencia = () => {
    return [4, 5].includes(conteudoEscolhido.tipovideo);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Videoaula
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12} className={classes.containerContent}>
            {conteudo}
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" className={classes.labelDescricao}>
              DESCRIÇÃO
            </Typography>
            <Typography className={classes.descricaoAula}>
              {conteudoEscolhido.descricao}
            </Typography>
          </Grid>
          {!isVideoConferencia() &&
            !conteudoEscolhido.agendado &&
            conteudoEscolhido.aovivo &&
            !conteudoEscolhido.desativado &&
            !conteudoEscolhido.tipovideo === 3 &&
            !conteudoEscolhido.publicado && (
              <div className={classes.btChat}>
                <ChatFullScreen />
              </div>
            )}
          {conteudoEscolhido && !conteudoEscolhido.agendado && <Atividades />}
        </Grid>
      </Dialog>
    </div>
  );
}

ConteudoFullScreen.propTypes = {
  conteudo: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
};
