import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function FaturaDetalhes({ data }) {
  const { data: fatura } = data;

  const getFormattedDate = (date) => {
    const newDate = new Date(date.replace(/T.+/, ' 03:00:00.000'));
    return format(newDate, 'dd/MM/yyyy', {
      locale: ptBR,
    });
  };

  const getFormattedCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {fatura.aluno}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption" color="secondary">
          Status
        </Typography>
        <Typography variant="body2" color="secondary">
          {fatura.status}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption" color="secondary">
          Tipo
        </Typography>
        <Typography variant="body2" color="secondary">
          {fatura.tipoparcela}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption" color="secondary">
          Parcela
        </Typography>
        <Typography variant="body2" color="secondary">
          {fatura.parcela}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption" color="secondary">
          Vencimento
        </Typography>
        <Typography variant="body2" color="secondary">
          {getFormattedDate(fatura.vencimento)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption" color="secondary">
          Valor
        </Typography>
        <Typography variant="body2" color="secondary">
          {getFormattedCurrency(fatura.valor)}
        </Typography>
      </Grid>
    </Grid>
  );
}

FaturaDetalhes.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      contrato_id: PropTypes.number,
      numerocontrato: PropTypes.number,
      curso_id: PropTypes.number,
      curso: PropTypes.string,
      aluno: PropTypes.string,
      receber_id: PropTypes.number,
      tipoparcela: PropTypes.string,
      parcela: PropTypes.number,
      vencimento: PropTypes.string,
      valor: PropTypes.string,
      pago: PropTypes.bool,
      vencido: PropTypes.bool,
      linhadigitavel: PropTypes.string,
      calculo: PropTypes.string,
      status: PropTypes.string,
      pix_status: PropTypes.number,
      older: PropTypes.bool,
    }),
  }).isRequired,
};

export default FaturaDetalhes;
