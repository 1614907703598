import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image';

import ImgConteudo from '~/assets/ilustra_conteudo.svg';
import IconLink from '~/assets/img_grafismo_link.png';

import Player from '~/components/Medias/Player';
import Pdf from '~/components/Medias/Pdf';

const useStyles = makeStyles({
  descricao: {
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  caption: {
    color: '#777',
    fontWeight: 'bold',
  },
  podcast: {
    width: '100%',
    height: '70px',
  },
});

const TYPE_PDF = 1;
const TYPE_PODCAST = 3;
const TYPE_VIDEO = 2;

export default function Conteudo() {
  const conteudoEscolhido = useSelector((state) => state.vejaemcasa.conteudo);
  const classes = useStyles();

  function montaConteudo() {
    if (TYPE_PDF === conteudoEscolhido.tipo) {
      return <Pdf conteudoEscolhido={conteudoEscolhido} />;
    }

    if ([TYPE_PODCAST, TYPE_VIDEO].includes(conteudoEscolhido.tipo)) {
      return <Player conteudoEscolhido={conteudoEscolhido} />;
    }

    return (
      <>
        <Image
          src={conteudoEscolhido.preview || IconLink}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            marginRight: '20px',
            marginBottom: '10px',
            marginTop: '10px',
            background: 'none',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
            maxHeight: '370px',
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          href="#outlined-buttons"
          onClick={() => window.open(conteudoEscolhido.url, '_blank')}
        >
          Abrir Conteúdo
        </Button>
      </>
    );
  }

  return (
    <>
      {(!conteudoEscolhido && (
        <>
          <Image
            src={ImgConteudo}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '10px',
              justifyContent: 'center',
              background: 'none',
            }}
            imageStyle={{
              width: '370px',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography variant="h5" className={classes.descricao}>
            Preparado para estudar para valer?
          </Typography>
          <Typography variant="caption" className={classes.caption}>
            Selecione um conteúdo para começar.
          </Typography>
        </>
      )) ||
        montaConteudo()}
    </>
  );
}
