import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PainelExpansivo from '~/components/Curso/Menu/PainelExpansivo';

import { useMateria } from '~/hooks/materia';

import api from '~/services/api';

export default function Menu({ materia, curso, contrato }) {
  const { salvarMateria, buscarAulasMateria, aulas } = useMateria();
  const [materiaEscolhida, setMateriaEscolhida] = useState(() =>
    buscarAulasMateria(materia.materia_id)
  );

  useEffect(() => {
    async function getAulas() {
      const response = await api.get(
        `/aulas-materia/${curso}/${materia.materia_id}/${contrato.contrato_id}`
      );

      salvarMateria({
        materia: materia.materia_id,
        curso,
        contrato: contrato.contrato_id,
        ...response.data,
      });
    }

    if (!materiaEscolhida) {
      getAulas();
    }
  }, [materiaEscolhida]); //eslint-disable-line

  useEffect(() => {
    setMateriaEscolhida(buscarAulasMateria(materia.materia_id));
  }, [aulas]); //eslint-disable-line

  return (
    <>
      {materiaEscolhida && (
        <>
          {materiaEscolhida.conteudos_materia &&
            materiaEscolhida.conteudos_materia.map((conteudo) => (
              <PainelExpansivo
                key={conteudo.smartconteudo_id}
                aula={conteudo}
                conteudo
                contrato={contrato.contrato_id}
                curso={curso}
                materia={materia.materia_id}
                turma={materia.turma_id}
              />
            ))}
          {materiaEscolhida.aulas &&
            materiaEscolhida.aulas.map((aula) => {
              if (aula.conteudos_aula) {
                return (
                  <PainelExpansivo
                    key={aula.aula_id}
                    aula={aula}
                    contrato={contrato.contrato_id}
                    curso={curso}
                    materia={materia.materia_id}
                    turma={materia.turma_id}
                  />
                );
              }

              return '';
            })}
        </>
      )}
    </>
  );
}

Menu.propTypes = {
  materia: PropTypes.shape().isRequired,
  contrato: PropTypes.shape().isRequired,
  curso: PropTypes.number.isRequired,
};
