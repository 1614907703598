import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Fade,
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';

import Pencil from './Pencil';
import Font from './Font';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '442px',
  },
  titleModal: {
    color: '#4D5884',
    fontWeight: 700,
    letterSpacing: '0.15px',
    margin: '15px 0',
  },
  tabs: {
    marginBottom: '10px',
  },
  nextButton: {
    color: '#fff',
    padding: '5px 40px',
    borderRadius: '200px',
  },
  confirmButton: {
    borderRadius: '200px',
    minWidth: '120px',
    margin: theme.spacing(0, 1),
  },
}));

function ModalAssinatura({ termosConfirmados, gerarContrato, contratante }) {
  const classes = useStyles();
  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveSignature = (signature) => {
    confirm({
      description: 'Ao confirmar o contrato será gerado',
      title: 'Deseja continuar?',
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        className: classes.confirmButton,
      },
      cancellationButtonProps: {
        variant: 'contained',
        color: 'secondary',
        className: classes.confirmButton,
      },
    })
      .then(async () => {
        gerarContrato(signature);
      })
      .catch(() => {});
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        className={classes.nextButton}
        color="primary"
        disabled={!termosConfirmados}
      >
        Assinar
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.titleModal}>
                  Assinatura digital do contrato
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  value={tab}
                  className={classes.tabs}
                  variant="fullWidth"
                >
                  <Tab label="Desenhar" />
                  {/* <Tab label="Fonte" /> */}
                </Tabs>
              </Grid>
              {tab === 0 ? (
                <Pencil
                  saveSignature={saveSignature}
                  handleBack={handleClose}
                />
              ) : (
                <Font
                  saveSignature={saveSignature}
                  handleBack={handleClose}
                  contratante={contratante}
                />
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalAssinatura.propTypes = {
  termosConfirmados: PropTypes.bool.isRequired,
  gerarContrato: PropTypes.func.isRequired,
  contratante: PropTypes.shape().isRequired,
};

export default ModalAssinatura;
