import styled from 'styled-components';
import theme from 'styled-theming';
import { lighten } from 'polished';

const primaryColor = theme('escola', {
  default: '#f97200',
  saga: '#f15a24',
  cebrac: '#2f2b60',
});

const lightenPrimaryColor = theme('escola', {
  default: lighten(0.25, '#f97200'),
  saga: lighten(0.25, '#f15a24'),
  cebrac: lighten(0.25, '#2f2b60'),
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  letter-spacing: 1px;
`;

export const Title = styled.div`
  color: #444;
  h3 {
    color: ${primaryColor};
  }

  div {
    display: flex;
    justify-content: flex-end;

    button {
      width: fit-content;
      border: none;
      background: none;
      margin: 0;
      padding: 0;
      display: flex;
    }
  }
`;

export const Dropzone = styled.div`
  border: 2px dashed ${lightenPrimaryColor};
  border-radius: 10px;
  height: 100%;
  margin: 15px 0;
  cursor: pointer;
  background: #fff;

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    aside {
      color: #999;
      text-align: center;

      p {
        color: ${primaryColor};
        font-weight: bold;

        u {
          color: #333;
        }
      }
    }
  }
`;
