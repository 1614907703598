import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Card } from '@material-ui/core';
import { darken } from 'polished';

import api from '~/services/api';
import history from '~/services/history';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: '31px',
    borderRadius: '200px',
    background: '#50A6FF',
    color: '#fff',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '21px',
    background: '#fff',
    minHeight: '190px',
  },
  curso: {
    marginTop: '17px',
    color: '#4D5884',
    fontSize: '24px',
    lineHeight: '28px',
  },
  subtitulo: {
    color: '#50A6FF',
    fontSize: '20px',
    lineHeight: '23px',
    marginTop: '10px',
    marginBottom: '19px',
  },
}));

export default function Pacotes() {
  const classes = useStyles();
  const { contrato } = useParams();
  const [pacotes, setPacotes] = useState([]);
  const [curso, setCurso] = useState(null);

  const getPacotes = async () => {
    const response = await api.get(`contrato/${contrato}/pacotes`);
    if (response.data && response.data.length) {
      setPacotes(response.data);
      setCurso(response.data[0].curso);
    }
  };
  useEffect(() => {
    if (!pacotes.length) {
      getPacotes();
    }
  }, [pacotes]); //eslint-disable-line

  return (
    <Grid container spacing={3}>
      {curso && (
        <Grid item xs={12}>
          <Typography component="p" className={classes.curso}>
            {curso}
          </Typography>
          <Typography component="p" className={classes.subtitulo}>
            Selecione uma das opções para ver seus conteúdos
          </Typography>
        </Grid>
      )}
      {pacotes &&
        pacotes.map((pacote) => (
          <Grid item xs={6} sm={4} lg={3}>
            <Card className={classes.cardContent}>
              <div>
                <Typography variant="body1" className={classes.title}>
                  {pacote.descricao}
                </Typography>
                <Typography variant="body2">
                  {pacote.quantidade_materias} Matérias
                </Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                size="small"
                onClick={() => {
                  history.push(
                    `/contrato/${contrato}/pacote/${pacote.pacote_id}/conteudo`
                  );
                }}
              >
                Ver as matérias
              </Button>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}
