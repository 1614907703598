import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Button,
  SearchPanel,
  Paging,
  MasterDetail,
} from 'devextreme-react/data-grid';
import {
  Typography,
  Button as MuiButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { darken } from 'polished';
import CurrencyFormatter from 'currency-formatter';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// import ModalPagamentos from '~/components/Modal/Pagamento';
import { loadBilling } from '~/store/modules/financeiro/actions';
import clsx from 'clsx';
import FaturaDetalhes from '../FaturaDetalhes';
// import ModalPix from '../ModalPix';

const useStyles = makeStyles((theme) => ({
  tituloTable: {
    paddingTop: theme.spacing(4),
    color: '#868CAB',
  },
  button: {
    margin: '0 5px',
  },
  buttonCard: {
    color: '#fff',
    borderRadius: '200px',
    background: '#1629CC',
    fontSize: '11px',
    lineHeight: '16px',
    '&:hover': {
      background: `${darken(0.1, '#1629CC')}`,
    },
  },
  buttonBoleto: {
    color: '#fff',
    borderRadius: '200px',
    background: '#4D5884',
    fontSize: '11px',
    lineHeight: '16px',
    '&:hover': {
      background: `${darken(0.1, '#4D5884')}`,
    },
  },
}));

export default function FaturasAbertas() {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const financeiro = useSelector((state) => state.financeiro.data);
  const [qtdParcelas, setQtdParcelas] = useState(null);
  // const [openModal, setOpenModal] = useState(false);
  // const [linhaDigitavel, setLinhaDigitavel] = useState(null);

  useEffect(() => {
    if (financeiro) {
      const parcelas = [];
      financeiro.abertas.map((parcela) => {
        if (!parcelas[parcela.contrato_id]) {
          parcelas[parcela.contrato_id] = [];
        }

        if (!parcelas[parcela.contrato_id][parcela.tipoparcela]) {
          parcelas[parcela.contrato_id][parcela.tipoparcela] = 0;
        }
        parcelas[parcela.contrato_id][parcela.tipoparcela] += 1;
        return null;
      });

      financeiro.pagas.map((parcela) => {
        if (!parcelas[parcela.contrato_id]) {
          parcelas[parcela.contrato_id] = [];
        }

        if (!parcelas[parcela.contrato_id][parcela.tipoparcela]) {
          parcelas[parcela.contrato_id][parcela.tipoparcela] = 0;
        }
        parcelas[parcela.contrato_id][parcela.tipoparcela] += 1;
        return null;
      });

      setQtdParcelas(parcelas);
    }
  }, [financeiro]);

  function prepareRow(e) {
    if (e.rowType === 'data') {
      e.rowElement.style.fontWeight = 'normal';

      if (e.data.pago) {
        e.rowElement.style.background = '#F6F6F6';
        e.rowElement.style.color = '#d1d1d1';
      } else {
        if (e.data.status === 'Processando') {
          e.rowElement.style.background = 'rgb(107, 65, 224, 0.12)';
        }

        if (e.data.status === 'Problemas no Pagamento') {
          e.rowElement.style.background = 'rgb(241, 133, 0, 0.3)';
        }

        if (e.data.status === 'Em aberto') {
          e.rowElement.style.background = 'rgb(80, 166, 255, 0.12)';
        }

        if (e.data.status === 'Atrasada') {
          e.rowElement.style.background = 'rgb(236, 24, 75, 0.1)';
        }
      }
    }
  }

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'STATUS') {
        if (e.data.pago) {
          e.cellElement.innerText = 'Pago';
          e.cellElement.style.fontWeight = 'bold';
        } else {
          if (e.data.status === 'Processando') {
            e.cellElement.style.color = '#4D5884';
          }

          if (e.data.status === 'Problemas no Pagamento') {
            e.cellElement.style.color = '#F18500';
          }

          if (e.data.status === 'Em aberto') {
            e.cellElement.style.color = '#4D5884';
          }

          if (e.data.status === 'Atrasada') {
            e.cellElement.style.color = '#EC184B';
          }

          e.cellElement.style.fontWeight = 'bold';
        }
      }

      if (e.column.caption === 'VALOR') {
        e.cellElement.innerText = CurrencyFormatter.format(e.data.valor, {
          code: 'BRL',
        });
        e.cellElement.style.fontWeight = 'bold';
      }

      if (e.column.caption === 'PARCELA') {
        const quantidade = qtdParcelas[e.data.contrato_id][e.data.tipoparcela];
        e.cellElement.innerText = `${
          e.data.parcela > 1 ? e.data.parcela : 1
        } / ${quantidade}`;
      }

      if (e.column.dataField === 'calculo') {
        if (e.data.calculo !== e.data.valor) {
          e.cellElement.innerText = CurrencyFormatter.format(e.data.calculo, {
            code: 'BRL',
          });
        } else {
          e.cellElement.innerText = CurrencyFormatter.format(e.data.valor, {
            code: 'BRL',
          });
        }
      }

      if (e.column.caption === 'VENCIMENTO') {
        if (e.data.vencimento) {
          const newDate = new Date(
            e.data.vencimento.replace(/T.+/, ' 03:00:00.000')
          );
          e.cellElement.innerText = format(newDate, 'dd/MM/yyyy', {
            locale: ptBR,
          });
        }
      }
    }
  }

  // function showLinhaDigitavel(e) {
  //   setLinhaDigitavel(e.row.data.linhadigitavel);
  //   setOpenModal(true);
  // }

  const handleClickGerarCobranca = (receber_id) => {
    dispatch(loadBilling(receber_id));
  };

  const getButtonClass = (status) => {
    switch (status) {
      case 'Processando':
        return clsx(classes.buttonCard, {
          background: '#6B41E0',
          '&:hover': {
            background: `${darken(0.1, '#6B41E0')}`,
          },
        });
      case 'Problemas no Pagamento':
        return clsx(classes.buttonCard, {
          background: '#FF9239',
          '&:hover': {
            background: `${darken(0.1, '#FF9239')}`,
          },
        });
      default:
        return clsx(classes.buttonCard, { background: '#1629CC' });
    }
  };

  const getLabelButton = (status) => {
    if (status === 'Processando' || status === 'Problemas no Pagamento') {
      return status;
    }

    return 'Pagar';
  };

  return (
    <>
      <Typography className={classes.tituloTable}>Parcelas Abertas</Typography>
      <DataGrid
        dataSource={financeiro && financeiro.abertas}
        allowColumnReordering
        showBorders
        onRowPrepared={(e) => {
          prepareRow(e);
        }}
        onCellPrepared={(e) => {
          prepareCell(e);
        }}
      >
        <GroupPanel visible={!smallScreen} />
        <SearchPanel visible />
        <Grouping autoExpandAll={false} />
        <Paging defaultPageSize={100} />

        {smallScreen && <MasterDetail enabled component={FaturaDetalhes} />}

        <Column dataField="contrato_id" visible={false} />
        <Column
          caption="STATUS"
          dataField="status"
          dataType="string"
          visible={!smallScreen}
        />
        <Column
          caption="ALUNO"
          dataField="aluno"
          dataType="string"
          visible={!smallScreen}
        />
        <Column
          caption="TIPO"
          dataField="tipoparcela"
          dataType="string"
          visible={!smallScreen}
        />
        <Column
          caption="PARCELA"
          dataField="parcela"
          dataType="string"
          visible={!smallScreen}
        />

        <Column caption="VENCIMENTO" dataField="vencimento" dataType="date" />
        <Column caption="VALOR" dataField="valor" dataType="string" />
        <Column caption="PAGAMENTO" type="buttons">
          {/* <Button
            className={classes.button}
            render={e => (
              <MuiButton
                variant="contained"
                className={classes.buttonBoleto}
                onClick={() => showLinhaDigitavel(e)}
              >
                Boleto bancário
              </MuiButton>
            )}
          /> */}
          <Button
            className={classes.button}
            // onClick={showLinhaDigitavel}
            render={(e) => {
              if ([3, 4].includes(e.data.pix_status) && e.data.older) {
                return (
                  // <ModalPix
                  //   receber_id={e.data.receber_id}
                  //   status={e.data.status}
                  // />
                  <MuiButton
                    variant="contained"
                    size="large"
                    className={getButtonClass(e.data.status)}
                    onClick={() => handleClickGerarCobranca(e.data.receber_id)}
                  >
                    {getLabelButton(e.data.status)}
                  </MuiButton>
                );
              }
              return null;
            }}
          />
        </Column>
      </DataGrid>
      {/* {linhaDigitavel && (
        <ModalPagamentos
          isOpen={openModal}
          setIsOpen={setOpenModal}
          linhaDigitavel={linhaDigitavel}
        />
      )} */}
    </>
  );
}
