export function getFuncionalidades() {
  return {
    type: '@funcionalidades/REQUEST',
  };
}

export function getFuncionalidadesSuccess(academico, financeiro) {
  return {
    type: '@funcionalidades/REQUEST_SUCCESS',
    payload: { academico, financeiro },
  };
}

export function getContratoLabels() {
  return {
    type: '@funcionalidades/REQUEST_CONTRATO_LABELS',
  };
}

export function getContratoLabelsSuccess(labels) {
  return {
    type: '@funcionalidades/REQUEST_CONTRATO_LABELS_SUCCESS',
    payload: labels,
  };
}
