import produce from 'immer';

const INITIAL_STATE = {
  atividade: null,
};

export default function questionario(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@questionario/ATIVIDADE_CHOOSED': {
        draft.atividade = action.payload.atividade;
        break;
      }
      case '@unidade/UNIDADE_CHOOSE_SUCCESS':
      case '@auth/SIGN_OUT': {
        draft.conteudo = null;
        break;
      }
      default:
    }
  });
}
