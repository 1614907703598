import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Grid, Fade, Typography, Button } from '@material-ui/core';

import ModalResposta from '~/components/VideoAulas/Atividades/ModalAtividade/ModalResposta';
import api from '~/services/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '481px',
    height: '487px',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '8px',
    padding: '34px 42px',
  },
  container: {
    height: '100%',
  },
  tituloModal: {
    color: '#50A6FF',
    fontSize: '24px',
  },
  tituloAtividade: {
    fontSize: '20px',
    color: '#868CAB',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
    fontSize: '10px',
  },
  value: {
    color: '#868CAB',
    fontSize: '14px',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btAcessar: {
    background: '#fff',
    color: '#50A6FF',
    borderRadius: '200px',
    fontSize: '14px',
    '&:hover': {
      background: `${darken(0.1, '#fff')}`,
    },
  },
  containerFiles: {
    maxHeight: '90px',
    overflowY: 'auto',
  },
  btResponder: {
    width: '247px',
    color: '#fff',
    background: '#50A6FF',
    borderRadius: '200px',
    alignSelf: 'center',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
}));

export default function ModalAtividade({ atividade, open, handleClose }) {
  const classes = useStyles();
  const [openModalResposta, setOpenModalResposta] = useState(false);

  const STATUS_ABERTA = 1;
  const STATUS_PENDENTE = 3;
  const STATUS_FAZER_NOVAMENTE = 5;

  if (open && !atividade.visualizado) {
    const { atividade_id, lancaratividade_id } = atividade;
    api.post('atividade-visualizada', {
      atividade_id,
      lancaratividade_id,
    });
  }

  function handleCloseModalResposta() {
    setOpenModalResposta(false);
    handleClose();
  }

  const respostaDisponivel = () => {
    return (
      !atividade.status_id ||
      [STATUS_ABERTA, STATUS_PENDENTE, STATUS_FAZER_NOVAMENTE].includes(
        atividade.status_id
      )
    );
  };

  const getAtividadeAnexos = () => {
    const anexos =
      (atividade.anexos && atividade.anexos.files) || atividade.anexos;

    return (
      <>
        {Array.isArray(anexos) && (
          <>
            <Typography
              variant="caption"
              component="p"
              className={classes.label}
            >
              ARQUIVOS E LINKS COMPLEMENTARES
            </Typography>
            <Grid container className={classes.containerFiles}>
              {anexos.map((anexo) => (
                <Grid item xs={12} className={classes.file} key={anexo}>
                  <Typography className={classes.fileName}>
                    {anexo.length > 30 ? `${anexo.substring(0, 30)}...` : anexo}
                  </Typography>
                  <Button
                    className={classes.btAcessar}
                    size="small"
                    onClick={() => window.open(anexo, '_blank')}
                  >
                    ACESSAR
                  </Button>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={12} className={classes.info}>
                <Typography variant="h5" className={classes.tituloModal}>
                  Sobre a atividade
                </Typography>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    ATIVIDADE
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.tituloAtividade}
                  >
                    {atividade.atividade}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    DESCRIÇÃO
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.value}
                  >
                    {atividade.descricao}
                  </Typography>
                </div>
                <div>{getAtividadeAnexos()}</div>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.btResponder}
                  onClick={() =>
                    respostaDisponivel() && setOpenModalResposta(true)
                  }
                  disabled={!respostaDisponivel()}
                >
                  RESPONDER ATIVIDADE
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {openModalResposta && (
        <ModalResposta
          open={openModalResposta}
          handleClose={handleCloseModalResposta}
          atividade={atividade}
        />
      )}
    </div>
  );
}

ModalAtividade.propTypes = {
  atividade: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
