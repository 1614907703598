import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const MateriaContext = createContext({});

export const MateriaProvider = ({ children }) => {
  const [aulas, setAulas] = useState([]);

  const buscarAulasMateria = useCallback(
    (materiaId) => {
      const aulasFiltered = aulas.filter((aula) => aula.materia === materiaId);
      return aulasFiltered[0];
    },
    [aulas]
  );

  const salvarMateria = useCallback(
    (aula) => {
      const aulaExists = buscarAulasMateria(aula.materia);
      if (!aulaExists) {
        setAulas([...aulas, aula]);
      }
    },
    [aulas, buscarAulasMateria]
  );

  const salvarAulaMateria = useCallback(
    (materia, aula) => {
      const newArrayAulas = [...aulas];
      // eslint-disable-next-line
      newArrayAulas.map((aulaMateria) => {
        if (aulaMateria.materia === materia) {
          aulaMateria = aula;
        }
      });

      setAulas(newArrayAulas);
    },
    [aulas]
  );

  return (
    <MateriaContext.Provider
      value={{
        salvarMateria,
        salvarAulaMateria,
        buscarAulasMateria,
        aulas,
      }}
    >
      {children}
    </MateriaContext.Provider>
  );
};

MateriaProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useMateria() {
  const context = useContext(MateriaContext);

  return context;
}
