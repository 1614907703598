import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { darken } from 'polished';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import Materia from '~/components/Conteudo/Materia';

import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4D5884',
    marginBottom: '27px',
  },
  tagMateriaAtual: {
    fontSize: '10px',
    lineHeight: '24px',
    background: '#4D5884',
    color: '#fff',
    padding: '3px 16px',
    borderRadius: '4px',
  },
  materia: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  turma: {
    color: '#868CAB',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
  qdtAulas: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  detalhesAcordeon: {
    background: 'rgba(189, 192, 212, 0.14)',
  },
  aula: {
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
  },
  nomeAula: {
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#4D5884',
    fontWeight: 500,
  },
  data: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#868CAB',
  },
  acessar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  accordionAula: {
    width: '100%',
  },
}));

export default function Conteudo({ location }) {
  const classes = useStyles();
  const { pacote, contrato: contratoParam, curso, turma } = useParams();
  const queryObject =
    (location.search &&
      Object.fromEntries(new URLSearchParams(location.search))) ||
    null;

  const contratos = useSelector((state) => state.conteudos.data);
  const [materias, setMaterias] = useState(null);
  const [contrato, setContrato] = useState(null);
  const [materiaActive] = useState(
    (queryObject && queryObject.materia) || null
  );
  const [aulaActive] = useState((queryObject && queryObject.aula) || null);
  const [atividadeActive] = useState(
    (queryObject && queryObject.atividadeLancada) || null
  );

  useEffect(() => {
    if (!contrato && contratos && contratos.dados) {
      const contratoFiltered = contratos.dados.filter((contratoMap) => {
        return (
          Number(contratoMap.contrato_id) === Number(contratoParam) &&
          (!curso || Number(contratoMap.curso_id) === Number(curso))
        );
      });

      setContrato(contratoFiltered[0]);
    }

    async function getMaterias() {
      let response;
      if (pacote) {
        response = await apiCallHandler(
          api.get,
          `contrato/${Number(contratoParam)}/pacote/${Number(pacote)}/materias/`
        );
      } else {
        response = await apiCallHandler(
          api.get,
          `materias/${parseInt(contrato.contrato_id, 10)}/${
            curso || contrato.curso_id
          }`
        );
      }

      if (turma) {
        response.data = response.data.filter(
          ({ turma_id }) => Number(turma_id) === Number(turma)
        );
      }

      setMaterias(response.data);
    }

    if (!materias && contrato) {
      getMaterias();
    }
  }, [contrato, materias, contratos]); //eslint-disable-line

  return (
    <>
      <Breadcrumb
        categorias={['Conteúdos']}
        funcionalidade={(contrato && contrato.curso) || ''}
      />
      <Typography className={classes.title}>
        {(contrato && contrato.curso) || ''}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          {materias &&
            materias.map((materia) => (
              <Materia
                materiaActive={materiaActive && Number(materiaActive)}
                aulaActive={aulaActive && Number(aulaActive)}
                atividadeActive={atividadeActive && Number(atividadeActive)}
                key={materia.materia_id}
                materia={materia}
                contrato={contrato}
                materias={materias}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
}

Conteudo.propTypes = {
  location: PropTypes.shape().isRequired,
};
