export function requestSuccess(contratos) {
  return {
    type: '@conteudos/REQUEST_SUCCESS',
    payload: { contratos },
  };
}

export function request() {
  return {
    type: '@conteudos/REQUEST',
  };
}
