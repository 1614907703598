import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Image from 'material-ui-image';

import CardConteudoAula from '~/components/Curso/Menu/PainelExpansivo/CardConteudoAula';

import IconPdf from '~/assets/img_grafismo_pdf.png';
import IconLink from '~/assets/img_grafismo_link.png';
import IconPodcast from '~/assets/img_grafismo_podcast.png';
import IconVideo from '~/assets/img_grafismo_video.png';

import { aulaChoosed } from '~/store/modules/aula/actions';

export default function PainelExpansivo({
  aula,
  conteudo,
  contrato,
  turma,
  materia,
}) {
  const conteudoEscolhido = useSelector((state) => state.aula.conteudo);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: '#fff',
    },
    panel: {
      background: `${conteudo ? '#4E6172' : '#8099AF'}`,
      marginBottom: theme.spacing(1),
    },
    icon: {
      color: '#fff',
    },
    details: {
      background: '#D3D6E5',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      width: '100%',
      cursor: 'pointer',
      border: `${
        conteudoEscolhido && conteudoEscolhido === aula
          ? '2px solid blue'
          : 'none'
      }`,
    },
    iconConteudo: {
      display: 'flex',
      alignItems: 'center',
    },
    iconNavigate: {
      display: 'flex',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClick(aulaEscolhida) {
    if (conteudo) {
      if (!aulaEscolhida.introducao) {
        aulaEscolhida.introducao = true;
      }
      dispatch(aulaChoosed(aulaEscolhida));
      return;
    }

    aulaEscolhida.aula = aula;
    dispatch(aulaChoosed(aulaEscolhida));
  }

  function getIcon(tipo) {
    switch (tipo) {
      case 1:
        return IconPdf;
      case 2:
        return IconVideo;
      case 3:
        return IconPodcast;
      default:
        return IconLink;
    }
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.panel}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {conteudo ? aula.titulo : aula.aula}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {conteudo && (
            <Card
              className={classes.card}
              onClick={() => {
                handleClick(aula);
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={4} className={classes.iconConteudo}>
                  <Image
                    src={getIcon(aula.tipo)}
                    style={{
                      display: 'flex',
                      paddingTop: 'unset',
                      marginRight: '20px',
                    }}
                    imageStyle={{
                      width: 'unset',
                      height: 'unset',
                      position: 'unset',
                      maxHeight: '60px',
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption" component="p">
                    {aula.descricao}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.iconNavigate}>
                  <NavigateNext fontSize="large" />
                </Grid>
              </Grid>
            </Card>
          )}
          {!conteudo &&
            aula.conteudos_aula &&
            aula.conteudos_aula.map((conteudoAula) => {
              if (conteudoAula.smartconteudo_id) {
                return (
                  <CardConteudoAula
                    key={conteudoAula.smartconteudo_id}
                    conteudo={conteudoAula}
                    aula={aula}
                    contrato={contrato}
                    turma={turma}
                    materia={materia}
                  />
                );
              }
              return null;
            })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

PainelExpansivo.propTypes = {
  aula: PropTypes.shape().isRequired,
  conteudo: PropTypes.bool,
  contrato: PropTypes.number.isRequired,
  turma: PropTypes.number.isRequired,
  materia: PropTypes.number.isRequired,
};

PainelExpansivo.defaultProps = {
  conteudo: false,
};
