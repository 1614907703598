import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';

import ImgConteudo from '~/assets/ilustra_conteudo.svg';
import Player from '~/components/Medias/Player';
import Pdf from '~/components/Medias/Pdf';

const useStyles = makeStyles({
  descricao: {
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  caption: {
    color: '#777',
    fontWeight: 'bold',
  },
  iframe: {
    height: '91vh',
  },
});

const TYPE_PDF = 1;
const TYPE_PODCAST = 3;
const TYPE_VIDEO = 2;

export default function Conteudo() {
  const conteudoEscolhido = useSelector((state) => state.aula.conteudo);
  const viewMode = useSelector((state) => state.aula.mode);
  const classes = useStyles();

  function montaConteudo() {
    if (TYPE_PDF === conteudoEscolhido.tipo) {
      return <Pdf conteudoEscolhido={conteudoEscolhido} />;
    }

    if ([TYPE_PODCAST, TYPE_VIDEO].includes(conteudoEscolhido.tipo)) {
      return <Player conteudoEscolhido={conteudoEscolhido} />;
    }

    if (conteudoEscolhido.tipo === 4) {
      let urlConteudo = conteudoEscolhido.url;

      if (urlConteudo.includes('drive.google')) {
        urlConteudo = urlConteudo.replace('/view', '/preview');
      }

      return (
        <iframe
          width="100%"
          height="650"
          src={urlConteudo}
          title="Conteúdo"
          frameBorder="0"
          oncontextmenu="return false;" //eslint-disable-line
        >
          {' '}
        </iframe>
      );
    }

    return (
      <>
        <iframe
          width="100%"
          height="650"
          src={conteudoEscolhido.url}
          className={viewMode === 'default' ? '' : classes.iframe}
          title="Conteúdo Interativo"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          allow="geolocation ; microphone ; camera ; midi ; encrypted-media *"
          oncontextmenu="return false;" //eslint-disable-line
        >
          {' '}
        </iframe>
      </>
    );
  }

  return (
    <>
      {(!conteudoEscolhido && (
        <>
          <Image
            src={ImgConteudo}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '10px',
              justifyContent: 'center',
              background: 'none',
            }}
            imageStyle={{
              width: '370px',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography variant="h5" className={classes.descricao}>
            Preparado para estudar para valer?
          </Typography>
          <Typography variant="caption" className={classes.caption}>
            Selecione uma aula e um conteúdo para começar.
          </Typography>
        </>
      )) ||
        montaConteudo()}
    </>
  );
}
