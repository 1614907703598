import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import api from '~/services/api';
import { requestSuccess } from '~/store/modules/materias/actions';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';

export default function GridMaterias({ turma }) {
  const dispatch = useDispatch();
  const materias = useSelector((state) => state.materias.data);
  const unidade = useSelector((state) => state.unidade.data);

  useEffect(() => {
    async function loadMaterias() {
      const response = await api.get(
        `materias/${unidade.unidade_id}${turma ? `/${turma}` : ''}`
      );
      dispatch(requestSuccess(response.data));
    }

    loadMaterias();
  }, [dispatch, turma, unidade]);

  return (
    <DataGrid dataSource={materias} showBorders>
      <Paging defaultPageSize={5} />
      <Pager showInfo />

      <Column caption="MATÉRIA" dataField="materia" />
      <Column caption="SALA" dataField="sala" />
      <Column caption="PROFESSOR" dataField="professor" />
      <Column caption="PRESENÇAS" dataField="presencas" />
      <Column caption="NOTA" dataField="nota" />
    </DataGrid>
  );
}

GridMaterias.propTypes = {
  turma: PropTypes.number,
};

GridMaterias.defaultProps = {
  turma: null,
};
