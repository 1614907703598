import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Button, makeStyles, withWidth } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';

import { darken } from 'polished';
import { Document, Page } from 'react-pdf';
import {
  addConteudoWatched,
  updateConteudoWatched,
} from '~/store/modules/conteudowatched/actions';

const useStyles = makeStyles({
  pdfViewer: {
    maxHeight: '600px',
    overflow: 'auto',
  },
  pdfButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '10px',
    width: '100%',
  },
  navButton: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  pageState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageInput: {
    width: '70px',
    height: '30px',
    marginRight: '10px',
    padding: '3px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '1rem',
  },
});

const widths = {
  xs: 400,
  sm: 600,
  md: 700,
  lg: 900,
  xl: 900,
};

function Pdf({ width, conteudoEscolhido }) {
  const classes = useStyles();

  const widthUsed = widths[width];

  const dispatch = useDispatch();
  const conteudosWatched = useSelector((state) => state.conteudowatched.data);

  const pageInputRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const watchedContent = conteudosWatched.find(
      (content) => content.id === conteudoEscolhido.smartconteudosmart_id
    );
    if (watchedContent) {
      setPageNumber(watchedContent.page);
      pageInputRef.current.valueAsNumber = watchedContent.page || '1';
      pageInputRef.current.value = watchedContent.page || '1';
    } else {
      dispatch(
        addConteudoWatched({
          id: conteudoEscolhido.smartconteudosmart_id,
          page: 1,
          seconds: null,
        })
      );
      setPageNumber(1);
    }
  }, [conteudoEscolhido]); // eslint-disable-line

  const onDocumentSuccess = (pdfProps) => {
    setNumPages(pdfProps.numPages);
  };

  const prevPage = () => {
    const newPage = pageNumber <= 1 ? 1 : pageNumber - 1;
    setPageNumber(newPage);
    dispatch(
      updateConteudoWatched({
        id: conteudoEscolhido.smartconteudosmart_id,
        page: newPage,
        seconds: null,
      })
    );
    if (pageInputRef.current) {
      pageInputRef.current.valueAsNumber = newPage;
      pageInputRef.current.value = newPage;
    }
  };

  const nextPage = () => {
    const newPage = pageNumber + 1 > numPages ? pageNumber : pageNumber + 1;
    setPageNumber(newPage);
    dispatch(
      updateConteudoWatched({
        id: conteudoEscolhido.smartconteudosmart_id,
        page: newPage,
        seconds: null,
      })
    );
    if (pageInputRef.current) {
      pageInputRef.current.valueAsNumber = newPage;
      pageInputRef.current.value = newPage;
    }
  };

  const handleChangePage = (event) => {
    if (event.target.value > 0 && event.target.value <= numPages) {
      setPageNumber(Number(event.target.value));
      dispatch(
        updateConteudoWatched({
          id: conteudoEscolhido.smartconteudosmart_id,
          page: Number(event.target.value),
          seconds: null,
        })
      );
    }
  };

  return (
    <>
      <div className={classes.pdfButtons}>
        <Button
          variant="contained"
          startIcon={<NavigateBefore />}
          onClick={prevPage}
          className={classes.navButton}
        >
          Anterior
        </Button>
        <div className={classes.pageState}>
          <input
            defaultValue={pageNumber}
            onChange={handleChangePage}
            className={classes.pageInput}
            type="number"
            ref={pageInputRef}
          />
          <Typography>/ {numPages}</Typography>
        </div>
        <Button
          variant="contained"
          endIcon={<NavigateNext />}
          onClick={nextPage}
          className={classes.navButton}
        >
          Próxima
        </Button>
      </div>
      <div
        onContextMenu={(event) => event.preventDefault()}
        className={classes.pdfViewer}
      >
        <Document
          file={conteudoEscolhido.url}
          onLoadSuccess={onDocumentSuccess}
        >
          <Page pageNumber={pageNumber} width={widthUsed} />
        </Document>
      </div>
    </>
  );
}

Pdf.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  conteudoEscolhido: PropTypes.shape().isRequired,
};

export default withWidth()(Pdf);
