import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, Grid, IconButton } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import {
  format,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isSunday,
  getDay,
  subWeeks,
  addWeeks,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import clsx from 'clsx';

import DatePickerModal from '~/components/CustomUI/DatePickerModal';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardSelecionado: {
    border: '2px solid #FF9239',
  },
  navegacao: {
    display: 'flex',
    justifyContent: 'center',
  },
  nomeDia: {
    color: '#868CAB',
    fontSize: '12px',
  },
  dataDia: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  datePicker: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divFiltros: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Datas({ returnDataSelecionada }) {
  const classes = useStyles();
  const [dataSelecionada, setDataSelecionada] = useState(undefined);
  const [dataInicio, setDataInicio] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1, locale: ptBR })
  );
  const [dataFim, setDataFim] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1, locale: ptBR })
  );
  const [diasDaSemana, setDiasDaSemana] = useState(
    eachDayOfInterval({ start: dataInicio, end: dataFim })
  );

  const diaDaSemana = (dia) => {
    switch (dia) {
      case 1:
        return 'Segunda-feira';
      case 2:
        return 'Terça-feira';
      case 3:
        return 'Quarta-feira';
      case 4:
        return 'Quinta-feira';
      case 5:
        return 'Sexta-feira';
      default:
        return 'Sábado';
    }
  };

  const handleBackDates = () => {
    const novaDataInicial = subWeeks(dataInicio, 1);
    const novaDataFinal = subWeeks(dataFim, 1);
    setDataInicio(novaDataInicial);
    setDataFim(novaDataFinal);
    setDiasDaSemana(
      eachDayOfInterval({ start: novaDataInicial, end: novaDataFinal })
    );
    setDataSelecionada(novaDataInicial);
    returnDataSelecionada(novaDataInicial);
  };

  const handleForwardDates = () => {
    const novaDataInicial = addWeeks(dataInicio, 1);
    const novaDataFinal = addWeeks(dataFim, 1);
    setDataInicio(novaDataInicial);
    setDataFim(novaDataFinal);
    setDataSelecionada(novaDataInicial);
    setDiasDaSemana(
      eachDayOfInterval({ start: novaDataInicial, end: novaDataFinal })
    );
    setDataSelecionada(novaDataInicial);
    returnDataSelecionada(novaDataInicial);
  };

  const handleChangeDataSelecionada = (data) => {
    const novaDataInicial = startOfWeek(data, {
      weekStartsOn: 1,
      locale: ptBR,
    });
    const novaDataFinal = endOfWeek(data, { weekStartsOn: 1, locale: ptBR });
    setDataInicio(novaDataInicial);
    setDataFim(novaDataFinal);
    setDiasDaSemana(
      eachDayOfInterval({ start: novaDataInicial, end: novaDataFinal })
    );
    setDataSelecionada(data);
    returnDataSelecionada(data);
  };

  return (
    <div className={classes.divFiltros}>
      <IconButton onClick={handleBackDates}>
        <ArrowBackIos />
      </IconButton>
      <Grid container spacing={1}>
        {!!diasDaSemana &&
          diasDaSemana
            .filter((dia) => !isSunday(dia))
            .map((dia) => (
              <Grid item xs={6} sm={4} lg={2} key={dia.getDate()}>
                <Card
                  elevation={
                    (dataSelecionada &&
                      dia.getDate() === dataSelecionada.getDate() &&
                      0) ||
                    1
                  }
                  className={clsx(
                    classes.card,
                    dataSelecionada &&
                      dia.getDate() === dataSelecionada.getDate() &&
                      classes.cardSelecionado
                  )}
                  onClick={() => {
                    setDataSelecionada(dia);
                    returnDataSelecionada(dia);
                  }}
                >
                  <Typography component="p" className={classes.nomeDia}>
                    {diaDaSemana(getDay(dia))}
                  </Typography>
                  <Typography
                    component="p"
                    className={classes.dataDia}
                    color="primary"
                  >
                    {format(dia, 'dd/MM/yyyy', {
                      locale: ptBR,
                    })}
                  </Typography>
                </Card>
              </Grid>
            ))}
      </Grid>
      <IconButton onClick={handleForwardDates}>
        <ArrowForwardIos />
      </IconButton>
      <DatePickerModal setDataSelecionada={handleChangeDataSelecionada} />
    </div>
  );
}

Datas.propTypes = {
  returnDataSelecionada: PropTypes.func.isRequired,
};
