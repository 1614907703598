import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';

import clsx from 'clsx';
import { aulaChoosed } from '~/store/modules/videoaula/actions';

import IconVideoAulas from '~/assets/ic_portal_aluno-18.svg';
import IconVideo from '~/assets/img_grafismo_live.png';

export default function CardConteudoAula({ aula, conteudo, compact }) {
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const useStyles = makeStyles({
    divRoot: {
      position: 'relative',
      marginBottom: '20px',
      width: 'fit-content',
      alignSelf: 'center',
    },
    card: {
      marginTop: '5px',
      width: '100%',
      cursor: 'pointer',
      border: `${
        conteudoEscolhido &&
        conteudoEscolhido.videoaula_id === conteudo.videoaula_id
          ? '2px solid #FF9239'
          : 'none'
      }`,
      padding: '10px',
    },
    iconConteudo: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconNavigate: {
      display: 'flex',
      justifyContent: 'center',
      color: '#4D5884',
    },
    labelVideo: {
      color: '#FF9239',
      marginLeft: '2px',
      fontSize: '12px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    divLive: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titulo: {
      color: '#FF9239',
      fontSize: '17px',
      letterSpacing: '0.15px',
      lineHeight: '20px',
      fontWeight: '500',
    },
    caption: {
      color: '#FF9239',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    dataPost: {
      color: '#868CAB',
      fontSize: '12px',
    },
    contentValue: {
      color: '#4D5884',
      fontSize: '14px',
      letterSpacing: '0.25px',
      fontWeight: 'bold',
    },
    tagLive: {
      position: 'absolute',
      background: '#EC184B',
      fontSize: '13px',
      lineHeight: '24px',
      color: '#fff',
      top: '-15px',
      right: '20px',
      fontWeight: 'bold',
    },
    tagAgendado: {
      position: 'absolute',
      background: '#777190',
      fontSize: '13px',
      lineHeight: '24px',
      color: '#fff',
      top: '-15px',
      right: '20px',
      fontWeight: 'bold',
    },
    badgeLive: {
      position: 'absolute',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      background: '#EC184B',
      top: 0,
      right: '20px',
    },
    visualizado: {
      color: '#fff',
      background: '#47D19D',
      borderRadius: '4px',
      fontSize: '10px',
      padding: '5px 15px',
    },
    naoVisualizado: {
      color: '#fff',
      background: '#EC184B',
      borderRadius: '4px',
      fontSize: '10px',
      padding: '5px 15px',
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClick(aulaEscolhida) {
    let novaAulaEscolhida = { ...aulaEscolhida };
    if (!aulaEscolhida.aula) {
      novaAulaEscolhida = { ...aulaEscolhida, aula };
    }
    dispatch(aulaChoosed(novaAulaEscolhida));
  }

  return (
    <div className={classes.divRoot}>
      {conteudo.aovivo && !compact && (
        <Chip label="AO VIVO" className={classes.tagLive} />
      )}
      {conteudo.agendado && !compact && (
        <Chip label="AGENDADO" className={classes.tagAgendado} />
      )}
      {conteudo.aovivo && compact && <div className={classes.badgeLive} />}
      <Card
        key={conteudo.videoaula_id}
        className={classes.card}
        onClick={() => {
          handleClick(conteudo);
        }}
      >
        <Grid
          container
          justifyContent={!compact ? 'space-between' : 'center'}
          alignItems={!compact ? 'flex-start' : 'center'}
          spacing={1}
        >
          {compact && (
            <>
              <Grid item xs={3} className={classes.iconConteudo}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Image
                      src={IconVideo}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 'unset',
                        background: 'none',
                      }}
                      imageStyle={{
                        width: 'unset',
                        height: 'unset',
                        position: 'unset',
                        maxHeight: '30px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {!compact && (
            <>
              <Grid item xs={3} className={classes.iconConteudo}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.divLive}>
                    <img src={IconVideoAulas} width="20" alt="video aula" />
                    <Typography className={classes.labelVideo}>
                      VÍDEO AULA
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Image
                      src={IconVideo}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 'unset',
                        background: 'none',
                      }}
                      imageStyle={{
                        width: 'unset',
                        height: 'unset',
                        position: 'unset',
                        maxHeight: '60px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      component="label"
                      className={clsx(
                        conteudo.visualizado
                          ? classes.visualizado
                          : classes.naoVisualizado
                      )}
                    >
                      {conteudo.visualizado ? 'VISUALIZADO' : 'NÃO VISUALIZADO'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.dataPost}
                    >
                      {conteudo.data}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          variant="caption"
                          component="p"
                          className={classes.titulo}
                        >
                          {conteudo.titulo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} className={classes.iconNavigate}>
                        <NavigateNext fontSize="large" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="p" className={classes.caption}>
                      CURSO
                    </Typography>
                    <Typography component="p" className={classes.contentValue}>
                      {conteudo.curso}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.caption}
                    >
                      TURMA
                    </Typography>
                    <Typography component="p" className={classes.contentValue}>
                      {conteudo.turma}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.caption}
                    >
                      MATÉRIA
                    </Typography>
                    <Typography component="p" className={classes.contentValue}>
                      {conteudo.materia}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.caption}
                    >
                      AULA
                    </Typography>
                    <Typography component="p" className={classes.contentValue}>
                      {conteudo.aula}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </div>
  );
}

CardConteudoAula.propTypes = {
  conteudo: PropTypes.shape().isRequired,
  aula: PropTypes.string.isRequired,
  compact: PropTypes.bool,
};

CardConteudoAula.defaultProps = {
  compact: false,
};
