import { takeLatest, all, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { callHandler } from '~/services/callWrapper';
import { getContratoLabelsSuccess, getFuncionalidadesSuccess } from './actions';

const funcionalidadesAcademicas = {
  Conteudos: 2,
  Notas: 3,
  VejaEmCasa: 4,
  Videoaula: 9,
  Atividades: 10,
  Forum: 12,
};

const funcionalidadesFinanceiras = {
  Contratos: 11,
  Financeiro: 5,
};

export function* getFuncionalidadesRequest() {
  try {
    const response = yield callHandler(api.get, `/funcionalidades`);

    const academico = response.data.filter((funcionalidade) =>
      [
        funcionalidadesAcademicas.Conteudos,
        funcionalidadesAcademicas.Notas,
        funcionalidadesAcademicas.VejaEmCasa,
        funcionalidadesAcademicas.Videoaula,
        funcionalidadesAcademicas.Atividades,
        funcionalidadesAcademicas.Forum,
      ].includes(funcionalidade.card_id)
    );
    const financeiro = response.data.filter((funcionalidade) =>
      [
        funcionalidadesFinanceiras.Contratos,
        funcionalidadesFinanceiras.Financeiro,
      ].includes(funcionalidade.card_id)
    );

    yield put(getFuncionalidadesSuccess(academico, financeiro));
  } catch (err) {
    toast.error(
      'Falha ao tentar carregar as funcionalidades, favor contactar o suporte.'
    );
  }
}

export function* getContratoLabelsRequest() {
  try {
    const response = yield callHandler(
      api.get,
      `/functionalidade/contrato/labels`
    );

    yield put(getContratoLabelsSuccess(response.data));
  } catch (err) {
    toast.error(
      'Falha ao tentar carregar as funcionalidades, favor contactar o suporte.'
    );
  }
}

export default all([
  takeLatest('@funcionalidades/REQUEST', getFuncionalidadesRequest),
  takeLatest(
    '@funcionalidades/REQUEST_CONTRATO_LABELS',
    getContratoLabelsRequest
  ),
]);
