import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

import { store } from '~/store';
import api from '~/services/api';

export default function RouterWrapper({
  component: Component,
  isPrivate = false,
  isExpired = false,
  ...rest
}) {
  console.log(`ROUTE: ${Component.name}`);

  const { signed } = store.getState().auth;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  const { data: unidade } = store.getState().unidade;

  console.log('UNIDADE', JSON.stringify(unidade));

  if (!api.defaults.headers.unidade_header && unidade) {
    console.log('SET UNIDADE HEADER');
    // console.log('SET', JSON.stringify(unidade));
    if (unidade.unidade_id) {
      api.defaults.headers.unidade_header = unidade.unidade_id;
    } else if (unidade.unidade) {
      api.defaults.headers.unidade_header = unidade.unidade.unidade_id;
    }
  }

  console.log('HEADER', api.defaults.headers.unidade_header);

  if (signed && !isPrivate && unidade) {
    return <Redirect to="/dashboard" />;
  }

  const Layout = signed && unidade && !isExpired ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouterWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isExpired: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouterWrapper.defaultProps = {
  isPrivate: false,
  isExpired: false,
};
