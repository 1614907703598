import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { requestDadosPessoais } from '~/store/modules/aceitecontrato/actions';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    border: 'none',
    boxShadow: 'none',
    marginTop: '15px',
  },
  box: {
    background: 'rgba(234, 236, 245, 0.4)',
    marginTop: '10px',
    borderRadius: '10px',
  },
  gridItem: {
    padding: '10px',
  },
  caption: {
    fontWeight: 'bold',
    color: '#4D5884',
    fontSize: '14px',
  },
  info: {
    color: '#50A6FF',
    fontSize: '14px',
  },
  sessaoEndereco: {
    fontSize: '18px',
    color: '#868CAB',
    marginTop: '10px',
  },
  divider: {
    marginTop: '15px',
    height: '2px',
    background: '#bdc0d5',
  },
  titulo: {
    fontSize: '20px',
    color: '#4D5884',
    marginBottom: '15px',
  },
});

export default function DadosPessoais({ contrato }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dadosPessoais = useSelector(
    (state) => state.aceitecontrato.dadosPessoais
  );
  const dadosTitular = useSelector(
    (state) => state.aceitecontrato.dadosTitular
  );
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (contrato && !dadosPessoais) {
      dispatch(requestDadosPessoais(contrato.aluno_id, contrato.titular_id));
    }
  }, [contrato]); // eslint-disable-line

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!dadosPessoais) {
    return null;
  }

  return (
    <>
      {contrato.pendenteaceite && (
        <>
          <Typography className={classes.titulo}>
            Verifique os dados pessoais
          </Typography>
          <Divider />
        </>
      )}
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Dados do aluno" />
          {contrato && contrato.temtitular && dadosTitular && (
            <Tab label="Dados do titular" />
          )}
        </Tabs>
        <div
          role="tabpanel"
          hidden={value !== 0}
          id={`scrollable-auto-tabpanel-${0}`}
          aria-labelledby={`scrollable-auto-tab-${0}`}
        >
          {value === 0 && (
            <Box p={3} className={classes.box}>
              <Grid container>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    {(dadosPessoais.pessoa_juridica && 'Razão Social') ||
                      'Nome completo'}
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.nome}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    {(dadosPessoais.pessoa_juridica && 'CNPJ') || 'CPF'}
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.cgc_cpf}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    {(dadosPessoais.pessoa_juridica && 'Inscrição Estadual') ||
                      'RG'}
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.ie_rg}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    {(dadosPessoais.pessoa_juridica &&
                      'Data de constituição') ||
                      'Data de nascimento'}
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.nascimento}
                  </Typography>
                </Grid>
                {!dadosPessoais.pessoa_juridica && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Sexo
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.sexo}
                    </Typography>
                  </Grid>
                )}
                {dadosPessoais.estadocivil &&
                  !dadosPessoais.pessoa_juridica && (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      className={classes.gridItem}
                    >
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.caption}
                      >
                        Estado Civil
                      </Typography>
                      <Typography className={classes.info}>
                        {dadosPessoais.estadocivil}
                      </Typography>
                    </Grid>
                  )}
                {dadosPessoais.escolaridade &&
                  !dadosPessoais.pessoa_juridica && (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      className={classes.gridItem}
                    >
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.caption}
                      >
                        Escolaridade
                      </Typography>
                      <Typography className={classes.info}>
                        {dadosPessoais.escolaridade}
                      </Typography>
                    </Grid>
                  )}
                {dadosPessoais.profissao && !dadosPessoais.pessoa_juridica && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Profissão
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.profissao}
                    </Typography>
                  </Grid>
                )}
                {dadosPessoais.nomemae && !dadosPessoais.pessoa_juridica && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Nome da mãe
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.nomemae}
                    </Typography>
                  </Grid>
                )}
                {dadosPessoais.nomepai && !dadosPessoais.pessoa_juridica && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Nome do pai
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.nomepai}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Celular
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.celular}
                  </Typography>
                </Grid>
                {dadosPessoais.comercial && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Telefone
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.comercial}
                    </Typography>
                  </Grid>
                )}
                {dadosPessoais.email && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Email
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.email}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider className={classes.divider} variant="fullWidth" />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" className={classes.sessaoEndereco}>
                    Endereço
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Logradouro
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.logradouro}
                  </Typography>
                </Grid>
                {dadosPessoais.endcomplemento && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Complemento
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosPessoais.endcomplemento}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    CEP
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.cep}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Bairro
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.bairro}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Cidade
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.cidade}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Estado
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosPessoais.estado}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={value !== 1}
          id={`scrollable-auto-tabpanel-${1}`}
          aria-labelledby={`scrollable-auto-tab-${1}`}
        >
          {value === 1 && (
            <Box p={3} className={classes.box}>
              <Grid container>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    {(dadosTitular.pessoa_juridica && 'Razão Social') ||
                      'Nome completo'}
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.nome}
                  </Typography>
                </Grid>
                {dadosTitular.pessoa_juridica && dadosTitular.apelido && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Nome Fantasia
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosTitular.apelido}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    {(dadosTitular.pessoa_juridica && 'CNPJ') || 'CPF'}
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.cgc_cpf}
                  </Typography>
                </Grid>
                {dadosTitular.ie_rg && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      {(dadosTitular.pessoa_juridica && 'Inscrição Estadual') ||
                        'RG'}
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosTitular.ie_rg}
                    </Typography>
                  </Grid>
                )}
                {dadosTitular.nascimento && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      {(dadosTitular.pessoa_juridica &&
                        'Data de constituição') ||
                        'Data de nascimento'}
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosTitular.nascimento}
                    </Typography>
                  </Grid>
                )}
                {!dadosTitular.pessoa_juridica && (
                  <>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      className={classes.gridItem}
                    >
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.caption}
                      >
                        Sexo
                      </Typography>
                      <Typography className={classes.info}>
                        {dadosTitular.sexo}
                      </Typography>
                    </Grid>
                    {dadosTitular.estadocivil && (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.caption}
                        >
                          Estado Civil
                        </Typography>
                        <Typography className={classes.info}>
                          {dadosTitular.estadocivil}
                        </Typography>
                      </Grid>
                    )}
                    {dadosTitular.escolaridade && (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.caption}
                        >
                          Escolaridade
                        </Typography>
                        <Typography className={classes.info}>
                          {dadosTitular.escolaridade}
                        </Typography>
                      </Grid>
                    )}
                    {dadosTitular.profissao && (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.caption}
                        >
                          Profissão
                        </Typography>
                        <Typography className={classes.info}>
                          {dadosTitular.profissao}
                        </Typography>
                      </Grid>
                    )}
                    {dadosTitular.nomemae && (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.caption}
                        >
                          Nome da mãe
                        </Typography>
                        <Typography className={classes.info}>
                          {dadosTitular.nomemae}
                        </Typography>
                      </Grid>
                    )}
                    {dadosTitular.nomepai && (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.caption}
                        >
                          Nome do pai
                        </Typography>
                        <Typography className={classes.info}>
                          {dadosTitular.nomepai}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Celular
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.celular}
                  </Typography>
                </Grid>
                {dadosTitular.comercial && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Telefone
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosTitular.comercial}
                    </Typography>
                  </Grid>
                )}
                {dadosTitular.email && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Email
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosTitular.email}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider className={classes.divider} variant="fullWidth" />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" className={classes.sessaoEndereco}>
                    Endereço
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Logradouro
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.logradouro}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Complemento
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.endcomplemento}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    CEP
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.cep}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Bairro
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.bairro}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                  >
                    Cidade
                  </Typography>
                  <Typography className={classes.info}>
                    {dadosTitular.cidade}
                  </Typography>
                </Grid>
                {dadosTitular.estado && (
                  <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.caption}
                    >
                      Estado
                    </Typography>
                    <Typography className={classes.info}>
                      {dadosTitular.estado}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </div>
      </Paper>
    </>
  );
}

DadosPessoais.propTypes = {
  contrato: PropTypes.shape().isRequired,
};
