export function aulaChoosed(aula) {
  return {
    type: '@videoaula/CHOOSED',
    payload: { aula },
  };
}

export function limparConteudo() {
  return {
    type: '@videoaula/CLEAN',
  };
}

export function videoAulasRequest() {
  return {
    type: '@videoaula/REQUEST',
  };
}

export function videoAulasRequestSuccess(aulas) {
  return {
    type: '@videoaula/REQUEST_SUCCESS',
    payload: { aulas },
  };
}
