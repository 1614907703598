import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Fade, Grid, Typography, Button } from '@material-ui/core';
import { darken } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import api from '~/services/api';
import history from '~/services/history';
import { useLoading } from '~/hooks/loading';
import { chooseAtividade } from '~/store/modules/questionario/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: '854px',
    maxHeight: '572px',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
  },
  container: {
    height: '100%',
  },
  header: {
    maxHeight: '72px',
    height: '100%',
    background: '#4D5884',
    borderRadius: '8px 8px 0 0',
  },
  containerHeader: {
    height: '100%',
    padding: '10px',
  },
  footer: {
    maxHeight: '95px',
    height: '100%',
    background: 'rgba(234, 236, 245, 0.4)',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '36px',
  },
  body: {
    height: '100%',
    maxHeight: '405px',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  containerBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tableHeader: {
    marginBottom: '10px',
  },
  resposta: {
    height: '72px',
    background: 'rgba(255, 255, 255, 0.4)',
    borderRadius: '12px',
    marginTop: '15px',
  },
  respostaCorreta: {
    border: '1px solid #47D19D',
  },
  respostaIncorreta: {
    border: '1px solid #FF9239',
  },
  questaoSemResposta: {
    border: '1px solid #BDC0D5',
  },
  gridContentCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  gridLabel: {
    color: '#4D5884',
    fontWeight: 'bold',
  },
  gridValue: {
    color: '#50A6FF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  number: {
    borderRadius: '12px 0 0 12px',
    fontSize: '20px',
    color: '#fff',
  },
  tagFlag: {
    padding: '5px 8px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '10px',
  },
  correta: {
    background: '#47D19D',
  },
  incorreta: {
    background: '#FF9239',
  },
  semResposta: {
    background: '#BDC0D5',
  },
  tagResposta: {
    background: '#50A6FF',
    padding: '5px 8px',
    borderRadius: '4px',
    marginLeft: '8px',
    color: '#fff',
    fontSize: '10px',
  },
  button: {
    minWidth: '183px',
    height: '40px',
    background: '#50A6FF',
    color: '#fff',
    borderRadius: '200px',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  label: {
    color: '#50A6FF',
  },
  value: {
    color: '#fff',
  },
  valueNome: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nome: {
    color: '#fff',
    fontSize: '18px',
  },
  enunciado: {
    maxHeight: '37px',
    overflow: 'auto',
    padding: '2px',
    marginBottom: '2px',
  },
}));

export default function ModalRespostasQuestionario({
  lancarAtividadeId,
  realizarAtividadeId,
  atividade,
  open,
  setOpen,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.aluno.profile);
  const { showLoading, hideLoading } = useLoading();

  const [respostas, setRespostas] = useState([]);
  const [questionario, setQuestionario] = useState(null);
  const [notaFinal, setNotaFinal] = useState(null);
  const [hasNotaFinal, setHasNotaFinal] = useState(false);

  const SELECAO_UNICA = 1;
  const VERDADEIRO_FALSO = 4;
  const MULTIPLA_ESCOLHA = 5;

  const getRespostas = async () => {
    showLoading();
    await api
      .get(`/atividade-realizada/${realizarAtividadeId}/questionario/respostas`)
      .then((response) => {
        if (response.data.respostas) {
          setRespostas(response.data.respostas);
          setQuestionario(response.data.questionario);

          let notaFinalRespostas = 0;

          if (response.data.nota) {
            notaFinalRespostas = Number(response.data.nota);
            setHasNotaFinal(true);
          } else {
            response.data.respostas.map(
              // eslint-disable-next-line
              (resposta) => (notaFinalRespostas += resposta.nota || 0)
            );
          }

          setNotaFinal(notaFinalRespostas);
        } else {
          dispatch(chooseAtividade(atividade));
          history.push(`/questionario/${lancarAtividadeId}`);
        }
        hideLoading();
      })
      .catch(() => {
        hideLoading();
      });
  };

  useEffect(() => {
    if (!respostas.length) {
      getRespostas();
    }
  }, []); // eslint-disable-line

  const handleClose = () => {
    setOpen(false);
  };

  const showResposta = (questao) => {
    const respostaQuestao = respostas.filter(
      (resposta) => questao.id === resposta.questao_id
    );

    const questaoRespondida = Boolean(respostaQuestao.length);
    let opcoesCorretas = '';

    if (
      [SELECAO_UNICA, VERDADEIRO_FALSO, MULTIPLA_ESCOLHA].includes(questao.tipo)
    ) {
      // eslint-disable-next-line
      questao.opcoes.map((opcao) => {
        if (opcao.correta) {
          if (!opcoesCorretas) {
            opcoesCorretas = opcoesCorretas.concat(`${opcao.id}`);
          } else {
            opcoesCorretas = opcoesCorretas.concat(`, ${opcao.id}`);
          }
        }
      });
    }

    let classeTag = classes.semResposta;
    let classeBorder = classes.questaoSemResposta;
    let textTag = 'NÃO RESPONDEU';

    if (questaoRespondida && respostaQuestao[0].acertou === undefined) {
      textTag = 'AGUARDANDO CORREÇÃO';
    }

    if (questaoRespondida && respostaQuestao[0].acertou) {
      classeTag = classes.correta;
      classeBorder = classes.respostaCorreta;
      textTag = 'RESPONDEU CERTO';
    }

    if (questaoRespondida && respostaQuestao[0].acertou === false) {
      classeTag = classes.incorreta;
      classeBorder = classes.respostaIncorreta;
      textTag = 'RESPONDEU ERRADA';
    }

    return (
      <Grid
        container
        className={clsx(classes.resposta, classeBorder)}
        spacing={1}
      >
        <Grid
          item
          xs={2}
          className={clsx(classes.gridContentCenter, classes.number, classeTag)}
        >
          {questao.id}
        </Grid>
        <Grid item xs={6} className={classes.gridValue}>
          <Typography
            variant="body2"
            component="p"
            className={classes.enunciado}
          >
            {questao.enunciado}
          </Typography>
          <div>
            <Typography
              variant="caption"
              component="label"
              className={clsx(classes.tagFlag, classeTag)}
            >
              {textTag}
            </Typography>
            {questaoRespondida && respostaQuestao[0].acertou !== undefined && (
              <Typography
                variant="caption"
                component="label"
                className={classes.tagResposta}
              >
                CORRETA (S): {opcoesCorretas}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          className={clsx(classes.gridContentCenter, classes.gridValue)}
        >
          {questao.peso}
        </Grid>
        <Grid
          item
          xs={2}
          className={clsx(classes.gridContentCenter, classes.gridLabel)}
        >
          {(questaoRespondida && respostaQuestao[0].nota) || 0}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      {respostas.length && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid
                container
                className={classes.container}
                justifyContent="space-between"
              >
                <Grid xs={12} className={classes.header}>
                  <Grid container className={classes.containerHeader}>
                    <Grid xs={6} lg={4} className={classes.valueNome}>
                      <Typography className={classes.nome}>
                        {profile.nome}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={4}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        Questionário
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.value}
                      >
                        {questionario && questionario.titulo}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={2}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        Peso total
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.value}
                      >
                        {questionario && questionario.peso_total}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={2}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        {(hasNotaFinal && 'Nota final') || 'Nota Parcial'}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.value}
                      >
                        {notaFinal}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={classes.body}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <Grid container className={classes.tableHeader}>
                        <Grid
                          item
                          xs={2}
                          className={clsx(
                            classes.gridContentCenter,
                            classes.gridLabel
                          )}
                        >
                          #
                        </Grid>
                        <Grid item xs={6} className={classes.gridLabel}>
                          Enunciado
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={clsx(
                            classes.gridContentCenter,
                            classes.gridLabel
                          )}
                        >
                          Peso
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={clsx(
                            classes.gridContentCenter,
                            classes.gridLabel
                          )}
                        >
                          Nota
                        </Grid>
                      </Grid>
                      {questionario &&
                        Boolean(questionario.questoes.length) &&
                        questionario.questoes.map((questao) =>
                          showResposta(questao)
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={classes.footer}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => setOpen(false)}
                  >
                    OK, FECHAR
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}

ModalRespostasQuestionario.propTypes = {
  realizarAtividadeId: PropTypes.number.isRequired,
  lancarAtividadeId: PropTypes.number,
  atividade: PropTypes.shape(),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

ModalRespostasQuestionario.defaultProps = {
  lancarAtividadeId: null,
  atividade: null,
};
