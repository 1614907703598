import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import GridMaterias from '~/components/Grid/GridMaterias';
import GridTrabalhos from '~/components/Grid/GridTrabalhos';

// import { Container, Button } from './styles';

export default function Materias({ match }) {
  const instituicao = useSelector((state) => state.instituicao.data);
  const useStyles = makeStyles({
    title: {
      color: '#333',
      margin: '15px 0',
    },
    activated: {
      background: instituicao.primary_color,
      color: '#fff',
    },
    grids: {
      marginTop: '15px',
    },
  });

  const classes = useStyles();
  const { turma } = match.params;
  const [showMaterias, setShowMaterias] = useState(true);
  const [showTrabalhos, setShowTrabalhos] = useState(false);
  return (
    <>
      <Typography variant="h4" component="h2" className={classes.title}>
        Matérias e Conteúdos
      </Typography>
      <Grid container>
        <Grid item md={6} xs={12}>
          <ButtonGroup fullWidth aria-label="full width outlined button group">
            <Button
              className={showMaterias ? classes.activated : ''}
              onClick={() => {
                setShowMaterias(true);
                setShowTrabalhos(false);
              }}
            >
              Matérias e aulas
            </Button>
            <Button
              className={showTrabalhos ? classes.activated : ''}
              onClick={() => {
                setShowMaterias(false);
                setShowTrabalhos(true);
              }}
            >
              Trabalhos e provas
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} className={classes.grids}>
          {showMaterias && <GridMaterias turma={turma} />}
          {showTrabalhos && <GridTrabalhos turma={turma} />}
        </Grid>
      </Grid>
    </>
  );
}

Materias.propTypes = {
  match: PropTypes.shape().isRequired,
};
