import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    marginTop: '10px',
  },
}));

export default function Loading({ description }) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress />
      {description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
    </Backdrop>
  );
}

Loading.defaultProps = {
  description: '',
};

Loading.propTypes = {
  description: PropTypes.string,
};
