import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Popup,
  Position,
} from 'devextreme-react/data-grid';
import api from '~/services/api';
import { requestSuccess } from '~/store/modules/trabalhos/actions';

import FormTrabalho from '~/components/Modal/FormTrabalho';
import Comentarios from '~/components/Modal/Comentarios';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';

export default function GridTrabalhos({ turma }) {
  const dispatch = useDispatch();
  const trabalhos = useSelector((state) => state.trabalhos.data);
  const unidade = useSelector((state) => state.unidade.data);
  const instituicao = useSelector((state) => state.instituicao.data);
  const [openFormTrabalho, setOpenFormTrabalho] = useState(false);
  const [openComentarios, setOpenComentarios] = useState(false);
  const [idTrabalhoAluno, setIdTrabalhoAluno] = useState(null);

  useEffect(() => {
    async function loadTrabalhos() {
      const response = await api.get(
        `trabalhos/${unidade.unidade_id}${turma ? `/${turma}` : ''}`
      );
      dispatch(requestSuccess(response.data));
    }

    loadTrabalhos();
  }, [dispatch, turma, unidade.unidade_id]);

  function showComentarios(row) {
    setOpenComentarios(true);
    setIdTrabalhoAluno(row.row.data.atividadealuno_id);
  }

  function showFormAnexo(row) {
    setOpenFormTrabalho(true);
    setIdTrabalhoAluno(row.row.data.atividadealuno_id);
  }

  function handleColumn(e) {
    if (e.rowType === 'data' && e.column.type === 'buttons') {
      e.cellElement.firstChild.style.color = instituicao.primary_color;
    }
  }

  return (
    <>
      <DataGrid
        dataSource={trabalhos}
        showBorders
        onCellPrepared={(e) => handleColumn(e)}
      >
        <Paging defaultPageSize={5} />
        <Pager showInfo />
        <Editing mode="popup" allowUpdating>
          <Popup title="Employee Info" showTitle width={700} height={525}>
            <Position my="top" at="top" of={window} />
          </Popup>
        </Editing>

        <Column caption="MATÉRIA" dataField="materia" />
        <Column caption="AULA" dataField="aula" />
        <Column caption="DATA" dataField="data" dataType="date" />
        <Column caption="PRAZO" dataField="prazo" dataType="date" />
        <Column caption="STATUS" dataField="status" />
        <Column
          dataField="arquivo"
          caption="ANEXO"
          type="buttons"
          width={110}
          buttons={[
            {
              hint: 'Anexar',
              widget: 'dxButton',
              onClick: showFormAnexo,
              icon: 'upload',
            },
          ]}
        />
        <Column caption="NOTA" dataField="nota" />
        <Column
          type="buttons"
          width={110}
          buttons={[
            {
              hint: 'Comentários',
              widget: 'dxButton',
              onClick: showComentarios,
              icon: 'comment',
            },
          ]}
        />
      </DataGrid>
      {openFormTrabalho && idTrabalhoAluno && (
        <FormTrabalho
          trabalho={idTrabalhoAluno}
          isOpen={openFormTrabalho}
          setOpenFormTrabalho={setOpenFormTrabalho}
        />
      )}
      {openComentarios && idTrabalhoAluno && (
        <Comentarios
          trabalho={idTrabalhoAluno}
          isOpen={openComentarios}
          setOpenComentarios={setOpenComentarios}
        />
      )}
    </>
  );
}

GridTrabalhos.propTypes = {
  turma: PropTypes.number,
};

GridTrabalhos.defaultProps = {
  turma: null,
};
