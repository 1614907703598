import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '~/services/api';

const PautaContext = createContext({});

export const PautaProvider = ({ children }) => {
  const [pautaConcluida, setPautaConcluida] = useState([]);

  const salvarPresenca = useCallback(
    // eslint-disable-next-line
    async ({ turma, materia, aula, contrato }) => {
      try {
        if (!pautaConcluida.includes(aula)) {
          setPautaConcluida([...pautaConcluida, aula]);
          const response = await api.post('pauta-ead', {
            turma,
            materia,
            aula,
            contrato,
          });
          if (response.data) {
            return response.data;
          }
        }
      } catch (error) {
        toast.error('Falha ao tentar registrar presença.');
        return null;
      }
    },
    [pautaConcluida]
  );

  return (
    <PautaContext.Provider
      value={{
        salvarPresenca,
      }}
    >
      {children}
    </PautaContext.Provider>
  );
};

PautaProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function usePauta() {
  const context = useContext(PautaContext);

  return context;
}
