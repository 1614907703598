export function registerSignature(assinatura) {
  return {
    type: '@aceitecontrato/SAVE_SIGNATURE',
    payload: { assinatura },
  };
}

export function eraseSignature() {
  return {
    type: '@aceitecontrato/ERASE_SIGNATURE',
  };
}

export function uploadContratSuccessfully() {
  return {
    type: '@aceitecontrato/UPLOAD_CONTRACT_SUCCESFULLY',
  };
}

export function uploadContratError() {
  return {
    type: '@aceitecontrato/UPLOAD_CONTRACT_ERROR',
  };
}

export function uploadContract(
  bufferContrato,
  contrato_id,
  pessoa_id,
  unidade_id
) {
  return {
    type: '@aceitecontrato/UPLOAD_CONTRACT',
    payload: {
      bufferContrato,
      contrato_id,
      pessoa_id,
      unidade_id,
    },
  };
}

export function requestDadosPessoais(aluno_id, titular_id) {
  return {
    type: '@aceitecontrato/DADOS_PESSOAIS_REQUEST',
    payload: { aluno_id, titular_id },
  };
}

export function requestDadosContratada() {
  return {
    type: '@aceitecontrato/DADOS_CONTRATADA_REQUEST',
  };
}

export function requestDadosCurso(contrato_id) {
  return {
    type: '@aceitecontrato/DADOS_CURSO_REQUEST',
    payload: { contrato_id },
  };
}

export function requestDadosPagamento(contrato_id) {
  return {
    type: '@aceitecontrato/DADOS_PAGAMENTO_REQUEST',
    payload: { contrato_id },
  };
}

export function requestTermosUso(termo_id) {
  return {
    type: '@aceitecontrato/TERMOS_USO_REQUEST',
    payload: { termo_id },
  };
}

export function requestDadosPessoaisSuccess(dados) {
  return {
    type: '@aceitecontrato/DADOS_PESSOAIS_REQUEST_SUCCESS',
    payload: { dados },
  };
}

export function requestDadosContratadaSuccess(dadosContratada) {
  return {
    type: '@aceitecontrato/DADOS_CONTRATADA_REQUEST_SUCCESS',
    payload: { dadosContratada },
  };
}

export function requestDadosCursoSuccess(dadosCurso) {
  return {
    type: '@aceitecontrato/DADOS_CURSO_REQUEST_SUCCESS',
    payload: { dadosCurso },
  };
}

export function requestDadosPagamentoSuccess(dadosPagamento) {
  return {
    type: '@aceitecontrato/DADOS_PAGAMENTO_REQUEST_SUCCESS',
    payload: { dadosPagamento },
  };
}

export function requestTermosUsoSuccess(termosUso) {
  return {
    type: '@aceitecontrato/TERMOS_USO_REQUEST_SUCCESS',
    payload: { termosUso },
  };
}

export function setLoading(loading, loadingStep = '') {
  return {
    type: '@aceitecontrato/SET_LOADING',
    payload: { loading, loadingStep },
  };
}
