import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { lighten } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import BookOutlined from '@material-ui/icons/BookOutlined';
import LocalLibraryOutlined from '@material-ui/icons/LocalLibraryOutlined';
import LiveTvOutlined from '@material-ui/icons/LiveTvOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import AccountBalanceWalletOutlined from '@material-ui/icons/AccountBalanceWalletOutlined';
import ListAlt from '@material-ui/icons/ListAlt';

import Logo from '~/components/Logo';

const CONTRATOS_CARD_ID = 11;

export default function Menu() {
  const instituicao = useSelector((state) => state.instituicao.data);
  const funcionalidades = useSelector((state) => state.funcionalidades.list);
  const notificacoes = useSelector((state) => state.notificacoes);
  const pathname = window.location.pathname.split('/')[1];
  const paths = [
    {
      funcionalidade_id: 2,
      icon: <BookOutlined color="primary" />,
      path: 'conteudos',
    },
    {
      funcionalidade_id: 3,
      icon: <AssignmentOutlined color="primary" />,
      path: 'notas',
    },
    {
      funcionalidade_id: 4,
      icon: <LocalLibraryOutlined color="primary" />,
      path: 'veja-em-casa',
    },
    {
      funcionalidade_id: 5,
      icon: <AccountBalanceWalletOutlined color="primary" />,
      path: 'financeiro',
    },
    {
      funcionalidade_id: 9,
      icon: <LiveTvOutlined color="primary" />,
      path: 'video-aulas',
    },
    {
      funcionalidade_id: 10,
      icon: <ListAlt color="primary" />,
      path: 'atividades',
    },
    {
      funcionalidade_id: 11,
      icon: <PeopleAltOutlined color="primary" />,
      path: 'contratos',
    },
    {
      funcionalidade_id: 12,
      icon: <LiveTvOutlined color="primary" />,
      path: 'forum',
    },
  ];

  const useStyles = makeStyles({
    toolbar: {
      minHeight: '60px',
      display: 'flex',
      justifyContent: 'center',
    },
    subheader: {
      textAlign: 'left',
      fontSize: '12px',
      letterSpacing: '1.5px',
      fontWeight: 500,
      color: '#BDC0D5',
    },
    activated: {
      background: lighten(0.45, `${instituicao.primary_color}`),
      borderLeft: `5px solid ${instituicao.primary_color}`,
    },
  });

  const classes = useStyles();

  const getItemText = (functionality) => {
    if (
      functionality.card_id === CONTRATOS_CARD_ID &&
      notificacoes.contratos > 0
    ) {
      return (
        <Badge badgeContent={notificacoes.contratos} color="primary">
          <ListItemText primary={functionality.nome} />
        </Badge>
      );
    }

    return <ListItemText primary={functionality.nome} />;
  };

  const showFunctionalities = (functionalities) => {
    return functionalities.map((functionality) => {
      const path = paths.filter(
        (pathFilter) => pathFilter.funcionalidade_id === functionality.card_id
      );
      return (
        <NavLink to={`/${path[0].path}`} key={functionality.card_id}>
          <ListItem
            button
            className={pathname === path[0].path ? classes.activated : ''}
          >
            <ListItemIcon>{path[0].icon}</ListItemIcon>
            {getItemText(functionality)}
          </ListItem>
        </NavLink>
      );
    });
  };

  return (
    <div>
      <div className={classes.toolbar}>
        <Logo width={120} />
      </div>
      <List>
        {funcionalidades && Boolean(funcionalidades.academico.length) && (
          <>
            <ListSubheader className={classes.subheader}>
              ACADÊMICO
            </ListSubheader>
            {showFunctionalities(funcionalidades.academico)}
          </>
        )}
        {funcionalidades && Boolean(funcionalidades.financeiro.length) && (
          <>
            <ListSubheader className={classes.subheader}>
              FINANCEIRO
            </ListSubheader>
            {showFunctionalities([...funcionalidades.financeiro].reverse())}
          </>
        )}
      </List>
    </div>
  );
}
