import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import {
  FilterList,
  Menu as MenuIcon,
  ForumOutlined,
} from '@material-ui/icons';
import { darken } from 'polished';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import Conteudo from '~/components/VideoAulas/Conteudo';
import Atividades from '~/components/VideoAulas/Atividades';
import CardConteudoAula from '~/components/VideoAulas/CardConteudoAula';
import Chat from '~/components/VideoAulas/Chat';
import Filtros from '~/components/Filtros';
import FiltrosVideoAula from '~/components/VideoAulas/FiltrosVideoAula';

import { videoAulasRequestSuccess } from '~/store/modules/videoaula/actions';

import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';

export default function VideoAulas() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [fullScreen, setFullScreen] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [showFiltros, setShowFiltros] = useState(false);
  const [chatShowed, setChatShowed] = useState(false);
  // const [objectFilters, setObjectFilters] = useState({});
  // const [professores, setProfessores] = useState([]);
  const [filtros, setFiltros] = useState({
    data: undefined,
    curso: undefined,
    materia: undefined,
  });

  const useStyles = makeStyles({
    nomeCurso: {
      textTransform: 'uppercase',
      color: '#3E98C7',
      fontWeight: 'bold',
      marginBottom: '30px',
    },
    nomeAula: {
      color: '#777',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    descricaoAula: {
      color: '#4D5884',
      width: '640px',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    tituloAula: {
      fontWeight: 500,
      fontSize: '21px',
      lineHeight: '25px',
      letterSpacing: '0.15px',
      color: '#50A6FF',
      width: '640px',
      marginBottom: '17px',
    },
    conteudo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    progresso: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    cards: (!hideMenu && {
      maxHeight: '700px',
      overflow: 'auto',
      background: '#f5f5f5',
    }) || {
      maxHeight: '700px',
      overflow: 'auto',
      background: '#f5f5f5',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    labelDescricao: {
      margin: '10px 0',
      color: '#50A6FF',
      width: '640px',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    buttonHide: {
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.75px',
      color: '#868CAB',
      textTransform: 'capitalize',
    },
    hideButton: {
      width: 'fit-content',
      alignSelf: 'center',
    },
    showFiltros: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btShowFiltros: {
      color: '#4D5884',
    },
    gridBtChat: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btChat: {
      background: '#50A6FF',
      color: '#fff',
      '&:hover': {
        background: `${darken(0.1, '#50A6FF')}`,
      },
    },
    aula: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  });
  const classes = useStyles();
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);
  const conteudos = useSelector((state) => state.videoaula.aulas);

  useEffect(() => {
    setFullScreen(smallScreen);
  }, [smallScreen]);

  function handleChangeFiltroData(novoFiltro) {
    setFiltros({ ...filtros, ...novoFiltro });
  }

  function handleChangeFiltrosSelect(novosFiltros) {
    const { curso, materia } = novosFiltros;
    setFiltros({ ...filtros, curso, materia });
  }

  useEffect(() => {
    async function getVideoAulas() {
      const response = await apiCallHandler(api.get, 'video-aulas', {
        params: filtros,
      });
      dispatch(videoAulasRequestSuccess(response.data));
    }

    getVideoAulas();
  }, [filtros]); //eslint-disable-line

  // useEffect(() => {
  //   const getProfessores = async () => {
  //     const response = await api.get('professores');
  //     setProfessores(response.data);
  //   };

  //   if (!professores.length) {
  //     getProfessores();
  //   }
  // }, [professores]);

  const handleChangeFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  const getQuantityColumn = () => {
    let quantity = 8;

    if (conteudoEscolhido) {
      if (hideMenu && !chatShowed) {
        quantity = 11;
      }

      if (conteudoEscolhido.aovivo && !hideMenu && chatShowed) {
        quantity = 6;
      }
    }

    return quantity;
  };

  const showConteudo = () => {
    return !!conteudoEscolhido || (!fullScreen && !conteudoEscolhido);
  };

  return (
    <>
      <Breadcrumb categorias={['Conteúdos']} funcionalidade="Videoaulas" />
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.nomeCurso}>
            Videoaulas
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.showFiltros}>
          <Button
            size="small"
            type="button"
            onClick={handleChangeFiltros}
            className={classes.btShowFiltros}
          >
            <FilterList /> Filtros Avançados
          </Button>
          {/* <Filtros
            applyFilters={setObjectFilters}
            filters={objectFilters}
            professores={professores}
          /> */}
        </Grid>
      </Grid>
      {showFiltros && (
        <Filtros
          dateFilterEnable
          returnFiltros={handleChangeFiltroData}
          filtrosAplicados={filtros}
          form={
            <FiltrosVideoAula
              returnFiltros={handleChangeFiltrosSelect}
              filtrosAplicados={filtros}
            />
          }
        />
      )}
      {conteudos && (
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={hideMenu ? 1 : 12}
            md={hideMenu ? 1 : 3}
            className={classes.cards}
          >
            {hideMenu && !fullScreen && (
              <IconButton
                onClick={() => {
                  setHideMenu(false);
                }}
                className={classes.hideButton}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            )}
            {!hideMenu && !fullScreen && (
              <Button
                className={classes.buttonHide}
                startIcon={<MenuIcon fontSize="inherit" />}
                onClick={() => {
                  setHideMenu(true);
                }}
                fullWidth={hideMenu}
              >
                Esconder menu
              </Button>
            )}
            {conteudos &&
              conteudos.map((videoAula) => (
                <CardConteudoAula
                  key={videoAula.videoaula_id}
                  conteudo={videoAula}
                  aula={videoAula.titulo}
                  compact={hideMenu}
                />
              ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={getQuantityColumn()}
            className={classes.conteudo}
          >
            {conteudoEscolhido && !conteudoEscolhido.agendado && (
              <Grid container>
                <Grid item xs={conteudoEscolhido.aovivo ? 9 : 12}>
                  <Typography className={classes.tituloAula}>
                    {conteudoEscolhido.titulo}
                  </Typography>
                </Grid>
                {conteudoEscolhido.aovivo &&
                  ![3, 4, 5].includes(conteudoEscolhido.tipovideo) && (
                    <Grid item xs={3} className={classes.gridBtChat}>
                      <Button
                        className={classes.btChat}
                        variant="contained"
                        onClick={() => setChatShowed(!chatShowed)}
                      >
                        <ForumOutlined />
                      </Button>
                    </Grid>
                  )}
              </Grid>
            )}
            {showConteudo() && (
              <Grid container>
                <Grid item xs={11} className={classes.aula}>
                  <Conteudo />
                </Grid>
              </Grid>
            )}
            {!fullScreen &&
              conteudoEscolhido &&
              !conteudoEscolhido.agendado && (
                <>
                  <Typography component="p" className={classes.labelDescricao}>
                    DESCRIÇÃO
                  </Typography>
                  <Typography className={classes.descricaoAula}>
                    {conteudoEscolhido.descricao}
                  </Typography>
                  <Atividades />
                </>
              )}
          </Grid>
          {conteudoEscolhido &&
            !conteudoEscolhido.agendado &&
            conteudoEscolhido.aovivo &&
            !conteudoEscolhido.tipovideo === 3 &&
            chatShowed && (
              <Grid item xs={12} sm={3}>
                <Chat videoAula={conteudoEscolhido.videoaula_id} />
              </Grid>
            )}
        </Grid>
      )}
    </>
  );
}
