import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import clsx from 'clsx';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { aulaChoosed } from '~/store/modules/vejaemcasa/actions';

import IconPdf from '~/assets/img_grafismo_pdf.png';
import IconLink from '~/assets/img_grafismo_link.png';
import IconPodcast from '~/assets/img_grafismo_podcast.png';
import IconVideo from '~/assets/img_grafismo_video.png';

import LabelPdf from '~/assets/ic_pdf.svg';
import LabelLink from '~/assets/ic_link.svg';
import LabelPodcast from '~/assets/ic_podcast.svg';
import LabelVideo from '~/assets/ic_video.svg';

export default function CardConteudoAula({ aula, conteudo, compact }) {
  const conteudoEscolhido = useSelector((state) => state.vejaemcasa.conteudo);

  const useStyles = makeStyles({
    divRoot: {
      position: 'relative',
      marginBottom: '20px',
    },
    card: {
      marginTop: '5px',
      width: '100%',
      maxWidth: '368px',
      cursor: 'pointer',
      padding: '19px',
      border: `${
        conteudoEscolhido && conteudoEscolhido === conteudo
          ? '2px solid blue'
          : 'none'
      }`,
    },
    iconConteudo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelConteudo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconNavigate: {
      display: 'flex',
      justifyContent: 'center',
      color: '#4D5884',
    },
    dataPost: {
      color: '#868CAB',
      fontSize: '12px',
      margin: '10px 0',
    },
    tituloAula: {
      color: '#4D5884',
      fontSize: '15px',
      letterSpacing: '0.15px',
    },
    containerCard: {
      display: 'flex',
      alignItems: 'center',
    },
    containerConteudo: {
      display: 'flex',
      alignItems: 'center',
    },
    colorVideo: {
      color: '#D5322E',
    },
    colorPdf: {
      color: '#6B41E0',
    },
    colorPodcast: {
      color: '#C14CD4',
    },
    colorLink: {
      color: '#777190',
    },
    iconLabel: {
      fontSize: '11px',
      marginLeft: '5px',
      fontWeight: 'bold',
    },
    visualizado: {
      color: '#fff',
      background: '#47D19D',
      borderRadius: '4px',
      fontSize: '10px',
      padding: '5px 15px',
    },
    naoVisualizado: {
      color: '#fff',
      background: '#EC184B',
      borderRadius: '4px',
      fontSize: '10px',
      padding: '5px 15px',
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClick(aulaEscolhida) {
    if (!aulaEscolhida.aula) {
      aulaEscolhida.aula = aula;
    }
    dispatch(aulaChoosed(aulaEscolhida));
  }

  function getIcon(tipo) {
    switch (tipo) {
      case 1:
        return IconPdf;
      case 2:
        return IconVideo;
      case 3:
        return IconPodcast;
      default:
        return IconLink;
    }
  }

  const getTipoConteudo = (tipo) => {
    let icon = null;
    let classe = null;
    let label = null;

    switch (tipo) {
      case 1:
        icon = LabelPdf;
        classe = classes.colorPdf;
        label = 'EBOOK';
        break;
      case 2:
        icon = LabelVideo;
        classe = classes.colorVideo;
        label = 'VÍDEO';
        break;
      case 3:
        icon = LabelPodcast;
        classe = classes.colorPodcast;
        label = 'PODCAST';
        break;
      default:
        icon = LabelLink;
        classe = classes.colorLink;
        label = 'LINK';
        break;
    }

    return (
      <div className={classes.labelConteudo}>
        <Image
          src={icon}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            background: 'none',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
            maxHeight: '50px',
          }}
        />
        <Typography component="p" className={clsx(classes.iconLabel, classe)}>
          {label}
        </Typography>
      </div>
    );
  };

  return (
    <Card
      key={conteudo.smartconteudo_id}
      className={classes.card}
      onClick={() => {
        handleClick(conteudo);
      }}
    >
      <Grid container spacing={1} className={classes.containerCard}>
        <Grid item xs={compact ? 12 : 3}>
          <Grid container>
            <Grid item xs={12}>
              {getTipoConteudo(conteudo.tipo)}
            </Grid>
            <Grid item xs={12}>
              <Image
                src={getIcon(conteudo.tipo)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 'unset',
                  background: 'none',
                }}
                imageStyle={{
                  width: 'unset',
                  height: 'unset',
                  position: 'unset',
                  maxHeight: '60px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!compact && (
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  component="label"
                  className={clsx(
                    conteudo.visualizado
                      ? classes.visualizado
                      : classes.naoVisualizado
                  )}
                >
                  {conteudo.visualizado ? 'VISUALIZADO' : 'NÃO VISUALIZADO'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.dataPost} component="p">
                  {`Postado dia ${format(
                    new Date(conteudo.disponivel),
                    'dd/MM/yyyy',
                    {
                      locale: ptBR,
                    }
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.tituloAula}>
                  {conteudo && conteudo.titulo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={2}>
          <NavigateNext fontSize="large" />
        </Grid>
      </Grid>
    </Card>
  );
}

CardConteudoAula.propTypes = {
  conteudo: PropTypes.shape().isRequired,
  aula: PropTypes.string.isRequired,
  compact: PropTypes.bool,
};

CardConteudoAula.defaultProps = {
  compact: false,
};
