import produce from 'immer';

const INITIAL_STATE = {
  list: null,
  labels: null,
};

export default function funcionalidades(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@funcionalidades/REQUEST_SUCCESS': {
        draft.list = {
          academico: action.payload.academico,
          financeiro: action.payload.financeiro,
        };
        break;
      }
      case '@funcionalidades/REQUEST_CONTRATO_LABELS_SUCCESS': {
        draft.labels = {
          ...draft.labels,
          contrato: action.payload,
        };
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.list = null;
        break;
      }
      default:
    }
  });
}
