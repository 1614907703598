import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import { limparConteudo } from '~/store/modules/aula/actions';

const useStyles = makeStyles((theme) => ({
  materialAtual: {
    background: '#7F99AF',
    padding: '2px 6px',
    borderRadius: '3px',
    color: '#fff',
    fontSize: '9px',
  },
  quantidadeAulas: {
    color: '#999',
    fontWeight: '500',
  },
  card: {
    padding: theme.spacing(2, 4),
  },
  turma: {
    color: '#333',
    fontWeight: 'bold',
  },
}));

export default function SelectMateria({
  materias,
  materiaSelecionada,
  changeMateriaSelecionada,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleChange(event) {
    const newMateriaSelecionada = materias.filter((materia) => {
      return materia.materia_id === event.target.value;
    });

    changeMateriaSelecionada(newMateriaSelecionada[0]);
    dispatch(limparConteudo());
  }

  return (
    <Card className={classes.card}>
      {materiaSelecionada && materiaSelecionada.is_materia_atual && (
        <Typography variant="caption" className={classes.materialAtual}>
          MATÉRIA ATUAL
        </Typography>
      )}
      <Typography variant="caption" className={classes.quantidadeAulas}>
        {materiaSelecionada &&
          ((materiaSelecionada.aulas < 2 &&
            ` ${materiaSelecionada.aulas} AULA`) ||
            ` ${materiaSelecionada.aulas} AULAS`)}
      </Typography>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Matéria</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={materiaSelecionada.materia_id}
          onChange={handleChange}
        >
          {materias.map((materia) => (
            <MenuItem key={materia.materia_id} value={materia.materia_id}>
              {materia.materia}
            </MenuItem>
          ))}
        </Select>
        {materiaSelecionada.turma && (
          <FormHelperText className={classes.turma}>
            {materiaSelecionada.turma}
          </FormHelperText>
        )}
      </FormControl>
    </Card>
  );
}

SelectMateria.propTypes = {
  materias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  materiaSelecionada: PropTypes.shape().isRequired,
  changeMateriaSelecionada: PropTypes.func.isRequired,
};
