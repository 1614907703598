import produce from 'immer';

const INITIAL_STATE = {
  pix: null,
  cobranca: null,
};

export default function pagamento(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@pagamento/CREATE_PIX_SUCCESS': {
        draft.pix = action.payload.pix;
        break;
      }
      case '@pagamento/GET_DATA_PAGAMENTO_SUCCESS': {
        draft.cobranca = action.payload.data;
        break;
      }
      case '@auth/SIGN_OUT':
      case '@pagamento/CLEAR_DATA_PIX': {
        draft.pix = null;
        draft.cobranca = null;
        break;
      }
      default:
    }
  });
}
