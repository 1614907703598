import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { darken } from 'polished';

import Chat from '../Chat';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: '#4D5884',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  btOpen: {
    width: '303px',
    height: '42px',
    bottom: '23px',
    background: '#50A6FF',
    borderRadius: '20px',
    color: '#fff',
    border: '1px solid #50A6FF',
    transition: 'background 0.2s',

    '&:hover': {
      background: `${darken(0.075, '#50A6FF')}`,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatFullScreen() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        className={classes.btOpen}
      >
        Chat da Aula
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Chat da aula
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Chat videoAula={conteudoEscolhido.videoaula_id} mobile />
      </Dialog>
    </div>
  );
}
