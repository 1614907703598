import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';
// import jwt from 'jsonwebtoken';
import ReactPlayer from 'react-player';

import Loading from '~/components/Loading';

import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';
import api from '~/services/api';
import { toast } from 'react-toastify';

export default function Jitsi() {
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);
  const unidade = useSelector((state) => state.unidade.data);
  const profile = useSelector((state) => state.aluno.profile);

  const [showLoading, setLoading] = useState(false);
  const [urlConferencia, setUrlConferencia] = useState(conteudoEscolhido.url);

  const generateToken = async (sala) => {
    try {
      setLoading(true);
      const response = await api.post('/create/jitsi-token', {
        context: {
          user: {
            name: `${profile.nome}`,
            email: `${profile.login}`,
          },
        },
        aud: 'SalaDeAulaJitsi',
        iss: 'SalaDeAulaJitsi',
        sub: 'meet.f10.com.br',
        room: `${sala}`,
        moderator: false,
      });

      const { token } = response.data;

      setUrlConferencia(`https://meet.f10.com.br/${sala}?jwt=${token}`);
    } catch (error) {
      toast.error('Erro ao gerar autenticação para a sala de aula');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (conteudoEscolhido.aovivo) {
      const transmissaoId =
        conteudoEscolhido.videoaula_id || conteudoEscolhido.id;
      const sala = `${unidade.unidade_id}-${transmissaoId}`;
      generateToken(sala);
    }
  }, [conteudoEscolhido]); //eslint-disable-line

  const showVideoConferencia = () => {
    if (conteudoEscolhido.aovivo) {
      return (
        <iframe
          allow="camera; microphone; fullscreen; display-capture"
          src={urlConferencia}
          title="video-conferencia"
          width="100%"
          height="600px"
        />
      );
    }

    if (conteudoEscolhido.publicado && conteudoEscolhido.url) {
      return <ReactPlayer url={conteudoEscolhido.url} controls />;
    }

    return (
      <>
        <Image
          src={imgConteudoDesativado}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            backgroundColor: 'none',
            justifyContent: 'center',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
          }}
        />
        <Typography color="primary" variant="h5" component="div" align="center">
          Fim da Transmissão
        </Typography>
        <Typography
          color="secondary"
          variant="body1"
          component="div"
          align="center"
        >
          A transmissão da videoaula foi encerrada ;)
        </Typography>
      </>
    );
  };

  return <>{(showLoading && <Loading />) || showVideoConferencia()}</>;
}
