import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import apiCobranca from '~/services/apiCobranca';
import api from '~/services/api';

import {
  createPix,
  createPixSuccess,
  getDataPagamentoSuccess,
  getDataPagamento,
} from './actions';

export function* requestCreatePix({ payload }) {
  try {
    const response = yield call(
      apiCobranca.post,
      'pix/cobranca/gerar',
      payload
    );

    const { result, ocorrencias } = response.data;

    if (result) {
      yield put(createPixSuccess(response.data));
      yield put(getDataPagamento(payload.unidade_id, payload.lista_receber[0]));
      return;
    }

    ocorrencias.map((ocorrencia) => toast.error(ocorrencia));
  } catch (err) {
    if (err.response && err.response.data) {
      toast.error(err.response.data.error);
      return;
    }

    toast.error('Ocorreu um erro ao tentar registrar Pix.');
  }
}

export function* requestDataPagamento({ payload }) {
  try {
    const response = yield call(api.get, `cobranca/${payload.receber_id}`);

    if (response.data && response.data.qrcode_text) {
      yield put(getDataPagamentoSuccess(response.data));
      return;
    }

    yield put(createPix(payload.unidade_id, [payload.receber_id]));
  } catch (err) {
    toast.error('Ocorreu um erro ao tentar recuperar dados da cobrança.');
  }
}

export default all([
  takeLatest('@pagamento/CREATE_PIX', requestCreatePix),
  takeLatest('@pagamento/GET_DATA_PAGAMENTO', requestDataPagamento),
]);
