import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import history from '~/services/history';
import { onSelect } from '~/store/modules/unidade/actions';

const useStyles = makeStyles({
  label: { marginTop: '20px' },
  select: { width: '200px' },
});

export default function Unidade() {
  const classes = useStyles();
  const [unidades, setUnidades] = useState([]);
  const dispatch = useDispatch();
  const aluno = useSelector((state) => state.aluno.profile);
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('auth');

  useEffect(() => {
    async function setUnidadesOptions() {
      const options = [];
      aluno.unidades.map((unidade) => {
        return options.push({
          value: unidade.unidade_id,
          label: unidade.unidade,
        });
      });

      setUnidades(options);
    }

    async function setUnidadeUsuario() {
      const { unidade_id, unidade } = aluno.unidades[0];
      await dispatch(onSelect({ unidade_id, unidade }, accessToken));
    }

    if (!aluno) {
      history.push('/');
    } else if (aluno.unidades.length > 1) {
      setUnidadesOptions();
    } else {
      setUnidadeUsuario();
    }
  }, [accessToken, aluno, dispatch]);

  async function handleChange(selectedOption) {
    const { value: unidade_id, label: nome } = selectedOption;
    await dispatch(onSelect({ unidade_id, nome }, accessToken));
  }

  return (
    <>
      <Typography component="p" className={classes.label}>
        Selecione a instituição
      </Typography>
      <Select
        className={classes.select}
        label="Selecione"
        options={unidades}
        placeholder="Selecione"
        onChange={handleChange}
      />
    </>
  );
}
