import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal as MuiModal,
  Fade,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';
import { darken } from 'polished';
import cep from 'cep-promise';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import TextField from '~/components/CustomUI/TextField';
import apiPagamento from '~/services/apiPagamento';
import history from '~/services/history';
import Loading from '../Loading';
import PaymentSuccess from '../PaymentSuccess';
import PaymentError from '../PaymentError';

import 'react-credit-cards/es/styles-compiled.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    padding: theme.spacing(2, 4, 3),
    maxHeight: '606px',
    maxWidth: '1213px',
    overflow: 'auto',
  },
  titleModal: {
    fontSize: '24px',
    lineHeight: '28px',
    color: '#50A6FF',
  },
  session: {
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: '#868CAB',
  },
  btVoltar: {
    background: '#BDC0D5',
    borderRadius: '200px',
    width: '230px',
    height: '28px',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    color: '#FFFFFF',
    '&:hover': {
      background: darken(0.1, '#BDC0D5'),
    },
  },
  btPagar: {
    background: '#47D19D',
    borderRadius: '200px',
    width: '230px',
    height: '28px',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    color: '#FFFFFF',
    '&:hover': {
      background: darken(0.1, '#47D19D'),
    },
  },
  gridButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridButtom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridCard: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
}));

const schema = Yup.object().shape({
  numeroCartao: Yup.string().required('O número do cartão é obrigatório.'),
  validade: Yup.string().required('A validade é obrigatória.'),
  nomeImpresso: Yup.string().required('O nome do titular é obrigatório.'),
  codSeguranca: Yup.string().required('O codigo de segurança é obrigatório.'),
  cpf: Yup.string().required('O cpf do titular é obrigatório.'),
  cepCliente: Yup.string().required('O CEP é obrigatório.'),
  pjBankToken: Yup.string(),
});

export default function Modal({
  open,
  setOpen,
  dadosRecebimento,
  recebimentoId,
}) {
  const classes = useStyles();

  const unidade = useSelector((state) => state.unidade.data);

  const [focus, setFocus] = useState('');
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [hasCep, setHasCep] = useState(false);
  const [enderecoCepPromise, setEnderecoCepPromise] = useState('');
  const [estadoCepPromise, setEstadoCepPromise] = useState('');
  const [municipioCepPromise, setMunicipioCepPromise] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  async function handleChangeCep(event) {
    const cepCliente = event.target.value.replace(/\D+/g, '');
    if (cepCliente.length === 8) {
      setHasCep(true);
      await cep(cepCliente)
        .then((response) => {
          if (enderecoCepPromise === '') {
            setEnderecoCepPromise(response.street);
            setEstadoCepPromise(response.state);
            setMunicipioCepPromise(response.city);
          }
        })
        .catch(() => {});
    } else {
      setEnderecoCepPromise('');
      setEstadoCepPromise('');
      setMunicipioCepPromise('');
      setHasCep(false);
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const cardToken = document.getElementById('pjBankToken');

      await apiPagamento.post('GravarToken', {
        unidade_id: unidade.unidade_id,
        contrato_id: dadosRecebimento.numerocontrato,
        tipo: 1,
        token: cardToken.value,
      });

      const response = await apiPagamento.post('CobrancaCartao', {
        unidade_id: unidade.unidade_id,
        receber_id: recebimentoId,
        usuario_id: null,
      });

      if (!response.data.occurrence && !response.data.response.cobranca) {
        toast.info('Pagamento sendo processado...');
        history.push('/financeiro');
      }

      if (!response.data.occurrence && response.data.response.cobranca) {
        setPaymentSuccess(true);
      }

      if (response.data.occurrence) {
        setPaymentError(true);
      }

      setLoading(false);
    } catch (error) {
      history.push('/financeiro');
      toast.error(
        'Ocorreu um erro ao tentar realizar o pagamento, tente novamente mais tarde.',
        { delay: 5000 }
      );
    }
  };

  const retry = () => {
    setPaymentError(false);
    handleSubmit();
  };

  const changeCard = () => {
    setPaymentError(false);
    setFocus('');
    setCvc('');
    setExpiry('');
    setName('');
    setNumber('');
    setHasCep(false);
    setEnderecoCepPromise('');
    setEstadoCepPromise('');
    setMunicipioCepPromise('');
  };

  return (
    <div>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Grid container spacing={(4, 2)}>
                <Grid item xs={12}>
                  <Typography className={classes.titleModal}>
                    Dados do cartão
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography className={classes.session}>
                        Dados do cartão
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar=" "
                        onFocus={() => {
                          setFocus('number');
                        }}
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                      >
                        {() => (
                          <TextField
                            variant="filled"
                            name="numeroCartao"
                            label="Número do cartão"
                            fullWidth
                            inputProps={{
                              className: 'pjbank-cartao',
                            }}
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={4}>
                      <InputMask
                        mask="99/99"
                        onFocus={() => {
                          setFocus('expiry');
                        }}
                        onChange={(e) => {
                          setExpiry(e.target.value);
                        }}
                      >
                        {() => (
                          <TextField
                            variant="filled"
                            name="validade"
                            label="Validade"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="filled"
                        name="nomeImpresso"
                        label="Nome impresso no cartão"
                        fullWidth
                        onFocus={() => {
                          setFocus('name');
                        }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputMask
                        mask="999"
                        maskChar=" "
                        onFocus={() => {
                          setFocus('cvc');
                        }}
                        onChange={(e) => {
                          setCvc(e.target.value);
                        }}
                      >
                        {() => (
                          <TextField
                            variant="filled"
                            name="codSeguranca"
                            label="Cód. Segurança"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.session}>
                        Dados do titular do cartão
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <InputMask maskChar=" " mask="999.999.999-99">
                        {() => (
                          <TextField
                            variant="filled"
                            name="cpf"
                            label="CPF do titular do cartão"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={4}>
                      <InputMask
                        mask="99999-999"
                        maskChar=" "
                        onChange={handleChangeCep}
                      >
                        {() => (
                          <TextField
                            variant="filled"
                            name="cepCliente"
                            label="CEP de cobrança"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="filled"
                        name="endereco"
                        label="Endereço"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: hasCep,
                        }}
                        value={enderecoCepPromise}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputMask mask="99999999" maskChar=" ">
                        {() => (
                          <TextField
                            variant="filled"
                            name="numero"
                            label="Número"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        variant="filled"
                        name="complemento"
                        label="Complemento"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="filled"
                        name="estado"
                        label="Estado"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: hasCep,
                        }}
                        value={estadoCepPromise}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="filled"
                        name="municipio"
                        label="Município"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: hasCep,
                        }}
                        value={municipioCepPromise}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridCard}>
                  <Cards
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={name}
                    number={number}
                  />
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.gridButtons}
                  >
                    <Grid item xs={6} className={classes.gridButtom}>
                      <Button
                        variant="contained"
                        className={classes.btVoltar}
                        onClick={() => setOpen(false)}
                      >
                        VOLTAR
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.gridButtom}>
                      <Button
                        variant="contained"
                        className={classes.btPagar}
                        type="submit"
                      >
                        PAGAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <input
                  id="pjBankToken"
                  type="hidden"
                  name="pjBankToken"
                  className="pjbank-token"
                />
              </Grid>
            </Form>
          </div>
        </Fade>
      </MuiModal>
      <Loading open={loading} setOpen={setLoading} />
      <PaymentSuccess open={paymentSuccess} setOpen={setPaymentSuccess} />
      <PaymentError
        open={paymentError}
        setOpen={setPaymentError}
        retry={retry}
        changeCard={changeCard}
      />
    </div>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  dadosRecebimento: PropTypes.shape().isRequired,
  recebimentoId: PropTypes.string.isRequired,
};
