import { takeLatest, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import fxCall from '~/services/callWrapper';

import { requestSuccess } from './actions';

export function* getConteudos() {
  try {
    const response = yield fxCall(api.get, `conteudos`);
    const cache = new Date();
    const dados = response.data;

    yield put(requestSuccess({ dados, cache }));
  } catch (err) {
    toast.error('Erro ao recuperar os conteudos, contacte o suporte');
  }
}

export default all([
  takeLatest('@unidade/SIGN_IN_SUCCESS', getConteudos),
  takeLatest('@conteudos/REQUEST', getConteudos),
  takeLatest('@auth/REHYDRATE_SUCCESS', getConteudos),
]);
