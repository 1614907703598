import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { darken, lighten } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  Backdrop,
  Fade,
  Typography,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { DeleteOutlineOutlined, CloudUpload } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Loading from '~/components/Loading';
import api from '~/services/api';
import apiStorage from '~/services/apiStorage';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '442px',
    height: '550px',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '8px',
    padding: '34px 42px',
  },
  container: {
    height: '100%',
  },
  tituloModal: {
    color: '#4D5884',
    fontSize: '16px',
  },
  tituloAtividade: {
    fontSize: '19px',
    color: '#50A6FF',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
    fontSize: '10px',
  },
  value: {
    color: '#868CAB',
    fontSize: '14px',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btRemover: {
    color: '#50A6FF',
  },
  containerFiles: {
    maxHeight: '90px',
    overflowY: 'auto',
  },
  btEntregar: {
    color: '#fff',
    background: '#50A6FF',
    borderRadius: '200px',
    alignSelf: 'center',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  dropZone: {
    border: `2px dashed ${lighten(0.2, '#868CAB')}`,
    borderRadius: '10px',
    width: '364px',
    height: '185px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#fff',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  iconUpload: {
    color: '#50A6FF',
    fontSize: '110px',
  },
}));

Yup.setLocale({
  mixed: {
    default: 'Tipo de arquivo inválido',
  },
  string: {
    oneOf: 'Tipo de arquivo inválido',
  },
  number: {
    max: 'Arquivo excede o tamanho máximo permitido',
  },
});

const schema = Yup.object().shape({
  size: Yup.number().max(50).required(),
  type: Yup.string().oneOf(
    [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    'Tipo de arquivo inválido'
  ),
});

export default function ModalResposta({ atividade, open, handleClose }) {
  const classes = useStyles();

  const unidadeState = useSelector((state) => state.unidade.data);
  const userId = useSelector((state) => state.user.id);
  const [arquivo, setArquivo] = useState(null);
  const [anotacoes, setAnotacoes] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  async function validarArquivoUpdado() {
    const { size: tamanho, type } = arquivo;
    const size = tamanho / 1024 / 1024;

    schema.validate({ size, type }).catch((error) => {
      setArquivo(null);
      toast.error(error.message);
    });
  }

  if (arquivo) {
    validarArquivoUpdado();
  }

  const onDrop = useCallback((acceptedFile) => {
    setArquivo(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const getExtension = (extension) => {
    switch (extension) {
      case 'application/pdf':
        return 'pdf';
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      case 'image/jpg':
        return 'jpg';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      default:
        throw new Error('Invalid type.');
    }
  };

  async function onClickHandler() {
    // setShowLoading(true);
    console.log(JSON.stringify(unidadeState));
    const { atividade_id, lancaratividade_id } = atividade;
    let unidade = unidadeState.unidade
      ? unidadeState.unidade.unidade_id
      : unidadeState.unidade_id;
    unidade = `00000${unidade}`.slice(-5);
    const fileName = new Date().getTime();
    let extension = '';
    const data = arquivo;

    try {
      extension = getExtension(arquivo.type);
    } catch (error) {
      toast.error('Tipo de arquivo inválido');
      return;
    }

    await apiStorage
      .put(
        `${unidade}/atividadesalunos/${atividade_id}/${userId}/${fileName}.${extension}`,
        data,
        {
          headers: {
            'Content-Type': `${arquivo.type}`,
          },
        }
      )
      .then(() => {
        setShowLoading(false);
        handleClose();
        const storageFile = `${process.env.REACT_APP_ORACLE_URL_DOWNLOAD}${unidade}%2Fatividadesalunos%2F${atividade_id}%2F${userId}%2F${fileName}.${extension}`;
        api.post('resposta-atividade', {
          atividade_id,
          lancaratividade_id,
          file: storageFile,
          anotacoes,
        });

        toast.success('Arquivo enviado com sucesso.');
      })
      .catch(() => {
        setShowLoading(false);
        toast.error('Erro ao enviar arquivo.');
      });
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={12} className={classes.info}>
                <div>
                  <Typography variant="h5" className={classes.tituloModal}>
                    Entrega de arquivo para
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.tituloAtividade}
                  >
                    {atividade.atividade}
                  </Typography>
                </div>
                <div className={classes.dropZone}>
                  <div className={classes.internDrop} {...getRootProps()}>
                    <CloudUpload className={classes.iconUpload} />
                    <input {...getInputProps()} />
                    <aside className={classes.aside}>
                      <p className={classes.paside}>
                        Arraste seu arquivo ou{' '}
                        <u className={classes.pasideu}>busque</u>
                      </p>
                      <small>Suportamos arquivos até 50MB.</small>
                    </aside>
                  </div>
                </div>
                {arquivo && (
                  <Grid container className={classes.containerFiles}>
                    <Grid item xs={12} className={classes.file}>
                      <Tooltip title={arquivo.name}>
                        <Typography className={classes.fileName}>
                          {arquivo.name.length > 20
                            ? `${arquivo.name.substr(0, 19)}...`
                            : arquivo.name}
                        </Typography>
                      </Tooltip>
                      <IconButton size="small" onClick={() => setArquivo(null)}>
                        <DeleteOutlineOutlined className={classes.btRemover} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                <TextField
                  label="Anotações"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  onChange={(e) => {
                    setAnotacoes(e.target.value);
                  }}
                  color="secondary"
                />
                {(showLoading && <Loading />) || (
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    className={classes.btEntregar}
                    onClick={() => onClickHandler()}
                  >
                    ENTREGAR ARQUIVO
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalResposta.propTypes = {
  atividade: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
