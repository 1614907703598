import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#777',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#777',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#777',
      },
      '&:hover fieldset': {
        borderColor: '#777',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#777',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Input = ({ name, type = 'text', label, multiline, ...rest }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [valueX, setValueX] = useState(
    defaultValue === undefined ? '' : defaultValue
  );

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current.childNodes[1].childNodes[0],
        path: 'value',
        clearValue: (valueRef) => {
          if (multiline) {
            setValueX(defaultValue === undefined ? '' : defaultValue);
          } else {
            valueRef.clearValue();
          }
        },
      });
    }
  }, [ref.current, fieldName]); //eslint-disable-line
  return (
    <div>
      <StyledTextField
        className={classes.textField}
        label={label}
        margin="normal"
        type={type}
        name={name}
        value={valueX}
        onChange={(e) => {
          setValueX(e.target.value);
        }}
        ref={ref}
        error={!!error}
        helperText={error}
        fullWidth
        multiline={multiline}
        rowsMax="3"
        {...rest}
      />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  label: '',
  multiline: false,
};

export default Input;
