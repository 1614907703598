import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  SearchPanel,
  Paging,
  MasterDetail,
} from 'devextreme-react/data-grid';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { darken } from 'polished';
import CurrencyFormatter from 'currency-formatter';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import FaturaDetalhes from '../FaturaDetalhes';

const useStyles = makeStyles((theme) => ({
  tituloTable: {
    paddingTop: theme.spacing(4),
    color: '#868CAB',
  },
}));

export default function FaturasFechadas() {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const financeiro = useSelector((state) => state.financeiro.data);
  const [qtdParcelas, setQtdParcelas] = useState(null);

  useEffect(() => {
    if (financeiro) {
      const parcelas = [];
      financeiro.pagas.map((parcela) => {
        if (!parcelas[parcela.contrato_id]) {
          parcelas[parcela.contrato_id] = [];
        }

        if (!parcelas[parcela.contrato_id][parcela.tipoparcela]) {
          parcelas[parcela.contrato_id][parcela.tipoparcela] = 0;
        }
        parcelas[parcela.contrato_id][parcela.tipoparcela] += 1;
        return null;
      });

      financeiro.abertas.map((parcela) => {
        if (!parcelas[parcela.contrato_id]) {
          parcelas[parcela.contrato_id] = [];
        }

        if (!parcelas[parcela.contrato_id][parcela.tipoparcela]) {
          parcelas[parcela.contrato_id][parcela.tipoparcela] = 0;
        }
        parcelas[parcela.contrato_id][parcela.tipoparcela] += 1;
        return null;
      });

      setQtdParcelas(parcelas);
    }
  }, [financeiro]);

  function prepareRow(e) {
    if (e.rowType === 'data') {
      e.rowElement.style.fontWeight = 'normal';

      if (e.data.pago) {
        e.rowElement.style.background = '#F6F6F6';
        e.rowElement.style.color = '#d1d1d1';
      } else {
        e.rowElement.style.background = e.data.vencido ? '#FFEDEF' : '#EEF2FE';
      }
    }
  }

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'STATUS') {
        if (e.data.pago) {
          e.cellElement.innerText = 'Pago';
          e.cellElement.style.fontWeight = 'bold';
        } else if (e.data.vencido) {
          e.cellElement.innerText = 'Atrasada';
          e.cellElement.style.color = darken(0.5, '#FFEDEF');
          e.cellElement.style.fontWeight = 'bold';
        } else {
          e.cellElement.innerText = 'Em aberto';
          e.cellElement.style.color = darken(0.5, '#EEF2FE');
          e.cellElement.style.fontWeight = 'bold';
        }
      }

      if (e.column.caption === 'VENCIMENTO') {
        e.cellElement.style.fontWeight = 'bold';
        if (!e.data.pago && e.data.vencido) {
          e.cellElement.style.color = darken(0.5, '#FFEDEF');
        }
        if (e.data.vencimento) {
          const newDate = new Date(
            e.data.vencimento.replace(/T.+/, ' 03:00:00.000')
          );
          e.cellElement.innerText = format(newDate, 'dd/MM/yyyy', {
            locale: ptBR,
          });
        }
      }

      if (e.column.caption === 'VALOR') {
        e.cellElement.innerText = CurrencyFormatter.format(e.data.valor, {
          code: 'BRL',
        });
        e.cellElement.style.fontWeight = 'bold';
      }

      if (e.column.caption === 'PARCELA') {
        if (e.data.parcela) {
          const quantidade =
            qtdParcelas[e.data.contrato_id][e.data.tipoparcela] || 1;
          e.cellElement.innerText = `${
            e.data.parcela > 1 ? e.data.parcela : 1
          } / ${quantidade}`;
        }
      }

      if (e.column.caption === 'PAGAR') {
        if (e.data.pago || !e.data.linhadigitavel) {
          e.cellElement.firstChild.style.display = 'none';
        } else {
          e.cellElement.firstChild.style.background = '#3D4A5D';
          e.cellElement.firstChild.style.color = '#fff';
          e.cellElement.firstChild.style.borderRadius = '5px';
          e.cellElement.firstChild.style.padding = '10px 15px';
          e.cellElement.firstChild.style.fontSize = '14px';
        }
      }

      if (e.column.dataField === 'calculo') {
        if (e.data.calculo !== e.data.valor) {
          e.cellElement.innerText = CurrencyFormatter.format(e.data.calculo, {
            code: 'BRL',
          });
        } else {
          e.cellElement.innerText = CurrencyFormatter.format(e.data.valor, {
            code: 'BRL',
          });
        }
      }
    }
  }

  return (
    <>
      <Typography className={classes.tituloTable}>Parcelas Quitadas</Typography>
      <DataGrid
        dataSource={financeiro && financeiro.pagas}
        allowColumnReordering
        showBorders
        onRowPrepared={(e) => {
          prepareRow(e);
        }}
        onCellPrepared={(e) => {
          prepareCell(e);
        }}
      >
        <GroupPanel visible={!smallScreen} />
        <SearchPanel visible />
        <Grouping autoExpandAll={false} />
        <Paging defaultPageSize={100} />

        {smallScreen && <MasterDetail enabled component={FaturaDetalhes} />}
        <Column dataField="contrato_id" visible={false} />
        <Column
          caption="STATUS"
          dataField="status"
          dataType="string"
          visible={!smallScreen}
        />
        <Column
          caption="ALUNO"
          dataField="aluno"
          dataType="string"
          visible={!smallScreen}
        />
        <Column
          caption="TIPO"
          dataField="tipoparcela"
          dataType="string"
          visible={!smallScreen}
        />
        <Column
          caption="PARCELA"
          dataField="parcela"
          dataType="string"
          visible={!smallScreen}
        />
        <Column caption="VENCIMENTO" dataField="vencimento" dataType="date" />
        <Column caption="VALOR" dataField="valor" dataType="string" />
      </DataGrid>
    </>
  );
}
