import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  Divider,
  AccordionDetails,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { useMateria } from '~/hooks/materia';
import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';
import PainelExpansivo from './PainelExpansivo';

const useStyles = makeStyles({
  detalhesAcordeon: {
    background: 'rgba(189, 192, 212, 0.14)',
  },
  accordionAula: {
    width: '100%',
  },
  aula: {
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
  },
});

export default function Detalhes({
  materiaEscolhida,
  contrato,
  materias,
  aulaActive,
  atividadeActive,
}) {
  const classes = useStyles();
  const { salvarMateria, buscarAulasMateria, aulas } = useMateria();
  const [materia, setMateria] = useState(() =>
    buscarAulasMateria(materiaEscolhida.materia_id)
  );
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    async function getAulas() {
      const response = await apiCallHandler(
        api.get,
        `/aulas-materia/${contrato.curso_id}/${materiaEscolhida.materia_id}/${contrato.contrato_id}`
      );
      salvarMateria({
        materia: materiaEscolhida.materia_id,
        curso: contrato.curso_id,
        contrato: contrato.contrato_id,
        ...response.data,
      });
    }

    if (!materia) {
      getAulas();
    }
  }, [materia]); //eslint-disable-line

  useEffect(() => {
    setMateria(buscarAulasMateria(materiaEscolhida.materia_id));
  }, [aulas]); //eslint-disable-line

  return (
    <>
      {materia && (
        <>
          {materia.conteudos_materia && (
            <>
              <AccordionDetails className={classes.detalhesAcordeon}>
                <Accordion
                  elevation={0}
                  className={classes.accordionAula}
                  expanded={expanded}
                  onChange={() => setExpanded(!expanded)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    className={classes.detalhesAcordeon}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={7}>
                        <Typography component="p" className={classes.aula}>
                          Conteúdos do Módulo
                        </Typography>
                        <Typography component="p" variant="caption">
                          {materia.conteudos_materia.length} Conteúdos
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  {materia.conteudos_materia.map((conteudo) => (
                    <PainelExpansivo
                      key={conteudo.smartconteudo_id}
                      aula={conteudo}
                      contrato={contrato}
                      materiaEscolhida={materiaEscolhida}
                      materias={materias}
                      conteudo={conteudo}
                      aulaActive={aulaActive}
                      atividadeActive={atividadeActive}
                    />
                  ))}
                </Accordion>
              </AccordionDetails>
              <Divider variant="fullWidth" />
            </>
          )}
          {materia.aulas &&
            materia.aulas
              .filter((aula) => aula.conteudos_aula !== null)
              .map((aula) => (
                <PainelExpansivo
                  key={aula.aula_id}
                  aula={aula}
                  contrato={contrato}
                  materiaEscolhida={materiaEscolhida}
                  materias={materias}
                  aulaActive={aulaActive}
                  atividadeActive={atividadeActive}
                />
              ))}
        </>
      )}
    </>
  );
}

Detalhes.propTypes = {
  materiaEscolhida: PropTypes.shape().isRequired,
  materias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contrato: PropTypes.shape().isRequired,
  aulaActive: PropTypes.number,
  atividadeActive: PropTypes.number,
};

Detalhes.defaultProps = {
  aulaActive: null,
  atividadeActive: null,
};
