import BB from './banco_brasil.png';
import Bradesco from './bradesco.png';
import BRB from './BRB.png';
import Caixa from './caixa.png';
import Cora from './cora.png';
import Itau from './itau.png';
import Safra from './safra.png';
import Santander from './santander.png';
import Sicoob from './sicoob.png';
import Sicred from './sicred.png';

export default function getLogoBank(code) {
  switch (code) {
    case '001':
      return BB;
    case '033':
      return Santander;
    case '070':
      return BRB;
    case '104':
      return Caixa;
    case '237':
      return Bradesco;
    case '341':
      return Itau;
    case '403':
      return Cora;
    case '422':
      return Safra;
    case '748':
      return Sicred;
    case '756':
      return Sicoob;
    default:
      return '';
  }
}
