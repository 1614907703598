import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import Datas from './Datas';

const useStyles = makeStyles({
  filtros: {
    margin: '15px 0',
  },
});

export default function Filtros({
  form,
  dateFilterEnable,
  returnFiltros,
  filtrosAplicados,
}) {
  const classes = useStyles();
  const [state, setState] = useState(filtrosAplicados);

  const addData = (data) => {
    setState({ ...state, data: format(data, 'yyyy-MM-dd') });
  };

  useEffect(() => {
    if (state !== filtrosAplicados) {
      returnFiltros(state);
    }
  }, [state]); //eslint-disable-line

  useEffect(() => {
    if (state !== filtrosAplicados) {
      setState(filtrosAplicados);
    }
  }, [filtrosAplicados]); //eslint-disable-line

  return (
    <Grid container spacing={2} className={classes.filtros}>
      <Grid item xs={12} md={3}>
        {form}
      </Grid>
      {dateFilterEnable && (
        <Grid item xs={12} md={9}>
          <Datas returnDataSelecionada={addData} />
        </Grid>
      )}
    </Grid>
  );
}

Filtros.propTypes = {
  form: PropTypes.element,
  dateFilterEnable: PropTypes.bool,
  returnFiltros: PropTypes.func.isRequired,
  filtrosAplicados: PropTypes.shape().isRequired,
};

Filtros.defaultProps = {
  form: <></>,
  dateFilterEnable: false,
};
