import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { format, isValid } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

export default function FiltrosVejaEmCasa({ returnFiltros, filtrosAplicados }) {
  const [state, setState] = useState({ ...filtrosAplicados });
  const [tipos] = useState(['Ebook', 'Vídeo', 'Áudio', 'Link']);

  useEffect(() => {
    const { tipo, data } = state;
    returnFiltros({ tipo, data });
  }, [state]); //eslint-disable-line

  function handleChangeData(data) {
    if (isValid(data)) {
      setState({ ...state, dataValue: data, data: format(data, 'yyyy-MM-dd') });
    } else {
      setState({ ...state, dataValue: null, data: null });
    }
  }

  function handleChangeTipo(event) {
    setState({ ...state, tipo: event.target.value });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="label-tipo">Tipo</InputLabel>
          <Select
            labelId="label-tipo"
            id="tipo"
            value={state.tipo}
            onChange={handleChangeTipo}
          >
            <MenuItem value={undefined}>
              <em>Selecione</em>
            </MenuItem>
            {tipos &&
              tipos.map((tipo, key) => (
                <MenuItem key={tipo} value={key}>
                  {tipo}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
          <KeyboardDatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="filled"
            label="Data"
            value={(state.dataValue && new Date(state.dataValue)) || null}
            format="dd/MM/yyyy"
            InputAdornmentProps={{ position: 'end' }}
            onChange={(date) => handleChangeData(date)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
}

FiltrosVejaEmCasa.propTypes = {
  returnFiltros: PropTypes.func.isRequired,
  filtrosAplicados: PropTypes.shape().isRequired,
};
