import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';
import ReactPlayer from 'react-player';

import Loading from '~/components/Loading';

import api from '~/services/api';

import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';

export default function Videofront() {
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const [urlConferencia, setUrlConferencia] = useState(conteudoEscolhido.url);
  const [isUrlVideofront, setIsUrlVideofront] = useState(false);
  const [showLoading, setLoading] = useState(false);

  useEffect(() => {
    async function setUrlVideofront() {
      try {
        setLoading(true);
        const response = await api.get(
          `url-meet/${conteudoEscolhido.videoaula_id || conteudoEscolhido.id}`
        );

        setUrlConferencia(response.data.url);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    async function getUrlVideoteca() {
      try {
        setLoading(true);
        setUrlConferencia(conteudoEscolhido.url); // atualiza o valor corrente.

        let videoRecorded = conteudoEscolhido.url;

        if (!videoRecorded) {
          const responseCheck = await api.get(
            `check-record/${
              conteudoEscolhido.videoaula_id || conteudoEscolhido.id
            }`
          );

          videoRecorded = responseCheck.status === 200;
        }

        if (videoRecorded) {
          const response = await api.get(
            `url-videoteca/${
              conteudoEscolhido.videoaula_id || conteudoEscolhido.id
            }`
          );

          setUrlConferencia(response.data.urlVideoteca);
          setLoading(false);
          setIsUrlVideofront(!!response.data.urlVideoteca);
        }
      } catch (error) {
        setLoading(false);
      }
    }

    if (conteudoEscolhido.aovivo) {
      setUrlVideofront();
    } else if (!conteudoEscolhido.url) {
      getUrlVideoteca();
    }
  }, [conteudoEscolhido]); //eslint-disable-line

  const showVideoConferencia = () => {
    if (conteudoEscolhido.aovivo) {
      return (
        <iframe
          allow="camera *; microphone *; fullscreen *; display-capture *"
          src={urlConferencia}
          title="video-conferencia"
          width="100%"
          height="600px"
        />
      );
    }

    if (conteudoEscolhido.publicado && urlConferencia && isUrlVideofront) {
      return (
        <iframe
          allowFullScreen
          src={urlConferencia}
          title="video-conferencia"
          width="100%"
          height="600px"
        />
      );
    }

    if (conteudoEscolhido.publicado && urlConferencia && !isUrlVideofront) {
      return <ReactPlayer url={conteudoEscolhido.url} controls />;
    }

    return (
      <>
        <Image
          src={imgConteudoDesativado}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            backgroundColor: 'none',
            justifyContent: 'center',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
          }}
        />
        <Typography color="primary" variant="h5" component="div" align="center">
          Fim da Transmissão
        </Typography>
        <Typography
          color="secondary"
          variant="body1"
          component="div"
          align="center"
        >
          A transmissão da videoaula foi encerrada ;)
        </Typography>
      </>
    );
  };

  return <>{(showLoading && <Loading />) || showVideoConferencia()}</>;
}
