import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import history from '~/services/history';

const useStyles = makeStyles({
  font: {
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  linkDisable: {
    textDecoration: 'none',
  },
  link: {
    cursor: 'pointer',
    fontSize: '12px',
  },
  breadcrumb: {
    marginBottom: '10px',
  },
});

export default function CustomBreadcrumbs({ categorias, funcionalidade }) {
  const classes = useStyles();

  function redirectToHome() {
    history.push('/dashboard');
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      <Typography
        color="inherit"
        onClick={redirectToHome}
        className={classes.link}
      >
        HOME
      </Typography>
      {categorias &&
        categorias.map((categoria) => (
          <Typography key={categoria} color="inherit" className={classes.font}>
            {categoria}
          </Typography>
        ))}
      <Typography color="inherit" className={classes.font}>
        {funcionalidade}
      </Typography>
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  categorias: PropTypes.arrayOf(PropTypes.string),
  funcionalidade: PropTypes.string.isRequired,
};

CustomBreadcrumbs.defaultProps = {
  categorias: null,
};
