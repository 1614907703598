import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import SelectMateria from '~/components/Curso/SelectMateria';
import Progresso from '~/components/Curso/Progresso';
import Menu from '~/components/Curso/Menu';
import Conteudo from '~/components/Curso/Conteudo';

import api from '~/services/api';

const useStyles = makeStyles(() => ({
  nomeCurso: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  nomeAula: {
    color: '#777',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  descricaoAula: {
    color: '#999',
  },
  tituloAula: {
    color: '#3E98C7',
  },
  conteudo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  progresso: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menu: {
    maxHeight: '400px',
    overflow: 'auto',
  },
}));

export default function Cursos({ match }) {
  const classes = useStyles();
  const conteudoEscolhido = useSelector((state) => state.aula.conteudo);

  const [contrato, setContrato] = useState(null);
  const [materias, setMaterias] = useState(null);
  const [materiaSelecionada, setMateriaSelecionada] = useState(null);
  const [materiasCalled, setMateriasCalled] = useState(false);
  const contratos = useSelector((state) => state.conteudos.data);
  useEffect(() => {
    if (!contrato) {
      const contratoFiltered = contratos.filter((contratoMap) => {
        return contratoMap.contrato_id === parseInt(match.params.contrato, 10);
      });

      setContrato(contratoFiltered[0]);
    }

    async function getMaterias() {
      const response = await api.get(
        `/materias/${parseInt(match.params.contrato, 10)}`
      );

      let materiaAtual = response.data.filter((materia) => {
        return materia.is_materia_atual === true;
      });

      if (!materiaAtual || materiaAtual.length === 0) {
        materiaAtual = response.data;
      }

      await setMateriaSelecionada(materiaAtual[0]);
      await setMaterias(response.data);
    }

    if (!materias && !materiasCalled) {
      setMateriasCalled(true);
      getMaterias();
    }
  }, [
    contrato,
    contratos,
    match.params.contrato,
    materiaSelecionada,
    materias,
    materiasCalled,
  ]);

  return (
    <>
      <Breadcrumb categorias={['Conteúdos']} funcionalidade="Cursos" />
      {materias && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.nomeCurso}>
              {contrato && contrato.curso}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectMateria
              materias={materias}
              materiaSelecionada={materiaSelecionada}
              changeMateriaSelecionada={setMateriaSelecionada}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {conteudoEscolhido && (
              <>
                {!conteudoEscolhido.introducao && (
                  <Typography variant="h5" className={classes.nomeAula}>
                    {conteudoEscolhido.aula}
                  </Typography>
                )}
                <Typography variant="h6" className={classes.tituloAula}>
                  {conteudoEscolhido.titulo}
                </Typography>
                <Typography variant="body1" className={classes.descricaoAula}>
                  {conteudoEscolhido.descricao}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3} className={classes.progresso}>
            <Progresso materia={materiaSelecionada} />
          </Grid>
          <Grid item xs={12} md={3} className={classes.menu}>
            <Menu materia={materiaSelecionada} curso={contrato.curso_id} />
          </Grid>
          <Grid item xs={12} md={9} className={classes.conteudo}>
            <Conteudo />
          </Grid>
        </Grid>
      )}
    </>
  );
}

Cursos.propTypes = {
  match: PropTypes.shape().isRequired,
};
