import produce from 'immer';

const INITIAL_STATE = {
  tipoBanco: [],
  tipoContrato: [],
  tipoAtividade: [],
  statusContrato: [],
  statusTurma: [],
  sexo: [],
  estadoCivil: [],
  statusAtividade: [],
  escolaridade: [],
  tipoParcela: [],
  tipoAula: [],
  tipoTransmissaoVideoAula: [],
  videoAulaStatus: [],
  tipoAtividadeLancamento: [],
  tipoRespostaLancamento: [],
  tipoNota: [],
};

export default function loockup(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@loockup/LOOCKUP_REQUEST_SUCCESS': {
        const data = {
          tipoBanco: [],
          tipoContrato: [],
          tipoAtividade: [],
          statusContrato: [],
          statusTurma: [],
          sexo: [],
          estadoCivil: [],
          statusAtividade: [],
          escolaridade: [],
          tipoParcela: [],
          tipoAula: [],
          tipoTransmissaoVideoAula: [],
          videoAulaStatus: [],
          tipoAtividadeLancamento: [],
          tipoRespostaLancamento: [],
          tipoNota: [],
        };

        // eslint-disable-next-line
        action.payload.loockup.map((loockupMap) => {
          const { intkey, descricao } = loockupMap;
          switch (loockupMap.grupo) {
            case 'TipoBanco':
              data.tipoBanco.push({ intkey, descricao });
              break;
            case 'TipoContrato':
              data.tipoContrato.push({
                intkey,
                descricao,
              });
              break;
            case 'TipoAtividade':
              data.tipoAtividade.push({
                intkey,
                descricao,
              });
              break;
            case 'StatusContrato':
              data.statusContrato.push({
                intkey,
                descricao,
              });
              break;
            case 'StatusTurma':
              data.statusTurma.push({ intkey, descricao });
              break;
            case 'Sexo':
              data.sexo.push({ intkey, descricao });
              break;
            case 'EstadoCivil':
              data.estadoCivil.push({ intkey, descricao });
              break;
            case 'StatusAtividade':
              data.statusAtividade.push({
                intkey,
                descricao,
              });
              break;
            case 'Escolaridade':
              data.escolaridade.push({
                intkey,
                descricao,
              });
              break;
            case 'TipoParcela':
              data.tipoParcela.push({ intkey, descricao });
              break;
            case 'TipoAula':
              data.tipoAula.push({ intkey, descricao });
              break;
            case 'VideoAulaTipoTransmissao':
              data.tipoTransmissaoVideoAula.push({ intkey, descricao });
              break;
            case 'VideoAulaStatus':
              data.videoAulaStatus.push({ intkey, descricao });
              break;
            case 'TipoAtividadeLancar':
              data.tipoAtividadeLancamento.push({ intkey, descricao });
              break;
            case 'TipoRespostaLancar':
              data.tipoRespostaLancamento.push({ intkey, descricao });
              break;
            case 'TipoNota':
              data.tipoNota.push({ intkey, descricao });
              break;
            default:
              break;
          }
        });
        draft.tipoBanco = data.tipoBanco;
        draft.tipoContrato = data.tipoContrato;
        draft.tipoAtividade = data.tipoAtividade;
        draft.statusContrato = data.statusContrato;
        draft.statusTurma = data.statusTurma;
        draft.sexo = data.sexo;
        draft.estadoCivil = data.estadoCivil;
        draft.statusAtividade = data.statusAtividade;
        draft.escolaridade = data.escolaridade;
        draft.tipoParcela = data.tipoParcela;
        draft.tipoAula = data.tipoAula;
        draft.tipoTransmissaoVideoAula = data.tipoTransmissaoVideoAula;
        draft.videoAulaStatus = data.videoAulaStatus;
        draft.tipoAtividadeLancamento = data.tipoAtividadeLancamento;
        draft.tipoRespostaLancamento = data.tipoRespostaLancamento;
        draft.tipoNota = data.tipoNota;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_FAILURE': {
        draft.tipoBanco = [];
        draft.tipoContrato = [];
        draft.tipoAtividade = [];
        draft.statusContrato = [];
        draft.statusTurma = [];
        draft.sexo = [];
        draft.estadoCivil = [];
        draft.statusAtividade = [];
        draft.escolaridade = [];
        draft.tipoParcela = [];
        draft.tipoAula = [];
        draft.tipoTransmissaoVideoAula = [];
        draft.videoAulaStatus = [];
        draft.tipoAtividadeLancamento = [];
        draft.tipoRespostaLancamento = [];
        draft.tipoNota = [];
        break;
      }
      case '@loockup/LOOCKUP_REQUEST_FAILURE': {
        draft.tipoBanco = [];
        draft.tipoContrato = [];
        draft.tipoAtividade = [];
        draft.statusContrato = [];
        draft.statusTurma = [];
        draft.sexo = [];
        draft.estadoCivil = [];
        draft.statusAtividade = [];
        draft.escolaridade = [];
        draft.tipoParcela = [];
        draft.tipoAula = [];
        draft.tipoTransmissaoVideoAula = [];
        draft.videoAulaStatus = [];
        draft.tipoAtividadeLancamento = [];
        draft.tipoRespostaLancamento = [];
        draft.tipoNota = [];
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.tipoBanco = [];
        draft.tipoContrato = [];
        draft.tipoAtividade = [];
        draft.statusContrato = [];
        draft.statusTurma = [];
        draft.sexo = [];
        draft.estadoCivil = [];
        draft.statusAtividade = [];
        draft.escolaridade = [];
        draft.tipoParcela = [];
        draft.tipoAula = [];
        draft.tipoTransmissaoVideoAula = [];
        draft.videoAulaStatus = [];
        draft.tipoAtividadeLancamento = [];
        draft.tipoRespostaLancamento = [];
        draft.tipoNota = [];
        break;
      }
      default:
    }
  });
}
