export function addConteudoWatched(data) {
  return {
    type: '@conteudowatched/ADD_WATCH_DATA',
    payload: { data },
  };
}

export function updateConteudoWatched(data) {
  return {
    type: '@conteudowatched/UPDATE_WATCH_DATA',
    payload: { data },
  };
}
