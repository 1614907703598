import { takeLatest, all, put } from 'redux-saga/effects';
import api from '~/services/api';
import { callHandler } from '~/services/callWrapper';
import { aulaChoosedSuccess } from './actions';

export function* changeStatusVisualizado({ payload }) {
  try {
    const { aula } = payload;

    if (!aula.visualizado) {
      yield callHandler(
        api.put,
        `conteudo/status/${aula.smartconteudosmart_id || aula.smartconteudo_id}`
      );
      aula.visualizado = true;
    }

    yield put(aulaChoosedSuccess(aula));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.tron.log(err);
  }
}

export default all([takeLatest('@aula/CHOOSED', changeStatusVisualizado)]);
