import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Grid,
  Radio,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { darken } from 'polished';

const useStyles = makeStyles(() => ({
  canvas: {
    border: '2px dashed #A1ADEB',
    width: 329,
    height: 200,
    padding: '10px',
  },
  gridCanvas: {
    background: '#F7F8FA',
    borderRadius: '20px',
    padding: '25px',
  },
  assinatura: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  cpf: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  instructions: {
    lineHeight: '18px',
    fontWeight: 400,
    margin: '15px 0',
  },
  btSalvar: {
    marginBottom: '10px',
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  btVoltar: {
    color: '#fff',
    borderRadius: '200px',
    background: '#4D5884',
    '&:hover': {
      background: `${darken(0.1, '#4D5884')}`,
    },
  },
}));

function Font({ saveSignature, handleBack, contratante }) {
  const classes = useStyles();
  const [penColor, setPenColor] = useState('#000000');
  const [fontFamily, setFontFamily] = useState('Sacramento');
  const fontsAvailable = ['Sacramento', 'Meie Script', 'Dancing Script'];

  const handleChangePenColor = (event) => {
    setPenColor(event.target.value);
  };

  const handleSave = () => {
    saveSignature({ font: fontFamily, penColor });
  };

  return (
    <Grid item xs={12} className={classes.gridCanvas}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <BlackRadio
            checked={penColor === '#000000'}
            onChange={handleChangePenColor}
            value="#000000"
          />
          <BlueRadio
            checked={penColor === '#3834FF'}
            onChange={handleChangePenColor}
            value="#3834FF"
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="font-label">Fonte</InputLabel>
            <Select
              labelId="font-label"
              label="Fonte"
              value={fontFamily}
              onChange={(e) => setFontFamily(e.target.value)}
            >
              {fontsAvailable.map((font) => (
                <MenuItem value={font}>
                  <Typography
                    style={{
                      fontFamily: font,
                      fontSize: '18px',
                      letterSpacing: '1px',
                      color: penColor,
                    }}
                  >
                    {font}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.canvas}>
            <Grid item xs={12} className={classes.assinatura}>
              <Typography
                align="center"
                style={{
                  fontFamily,
                  fontWeight: 'bold',
                  fontSize: '20px',
                  letterSpacing: '1.5px',
                  color: penColor,
                }}
              >
                {contratante.nome}
              </Typography>
              <Divider variant="fullWidth" />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" style={{ fontWeight: 100 }}>
                {contratante.nome}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.cpf}>
              <Typography variant="caption" style={{ fontWeight: 100 }}>
                CPF: {contratante.cgc_cpf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={classes.instructions}
            color="secondary"
          >
            Ao assinar e confirmar você desclara que leu e aceitou todos os
            termos desse contrato
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            className={classes.btSalvar}
            onClick={handleSave}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            fullWidth
            className={classes.btVoltar}
            onClick={() => handleBack()}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const BlackRadio = withStyles({
  root: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
  root: {
    color: '#3834FF',
    '&$checked': {
      color: '#3834FF',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

Font.propTypes = {
  saveSignature: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  contratante: PropTypes.shape().isRequired,
};

export default Font;
