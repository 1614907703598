import { createTheme } from '@material-ui/core/styles';

export default function theme(institution) {
  const overrides = {
    MuiButton: {
      label: {
        textTransform: 'initial',
        fontWeight: 400,
      },
    },
  };

  return createTheme({
    typography: {
      fontFamily: ['Roboto'].join(','),
    },
    palette: {
      primary: {
        main: institution.primary_color,
        contrastText: '#FFF',
      },
      secondary: {
        main: '#4D5884',
      },
      neutral: {
        main: '#50A6FF',
      },
    },
    overrides,
  });
}
