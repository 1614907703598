import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  Grid,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Detalhes from './Detalhes';

const useStyles = makeStyles({
  tagMateriaAtual: {
    fontSize: '10px',
    lineHeight: '24px',
    background: '#4D5884',
    color: '#fff',
    padding: '3px 16px',
    borderRadius: '4px',
  },
  materia: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  turma: {
    color: '#868CAB',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
  qdtAulas: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default function Materia({
  materia,
  contrato,
  materias,
  materiaActive,
  aulaActive,
  atividadeActive,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    materia.materia_id === Number(materiaActive)
  );

  return (
    <Accordion onChange={() => setExpanded(!expanded)} expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={7}>
            {materia.is_materia_atual && (
              <Typography component="span" className={classes.tagMateriaAtual}>
                MATÉRIA ATUAL
              </Typography>
            )}
            <Typography component="p" className={classes.materia}>
              {materia.materia}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.grid}>
            <Typography component="p" className={classes.turma}>
              {materia.turma || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.grid}>
            <Typography component="p" className={classes.qdtAulas}>
              {materia.aulas
                ? `${materia.aulas} aulas`
                : materia.materia_id < 1
                ? '1 conteúdo'
                : ''}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {expanded && (
        <Detalhes
          materiaEscolhida={materia}
          contrato={contrato}
          materias={materias}
          aulaActive={aulaActive}
          atividadeActive={atividadeActive}
        />
      )}
    </Accordion>
  );
}

Materia.propTypes = {
  materiaActive: PropTypes.number,
  aulaActive: PropTypes.number,
  atividadeActive: PropTypes.number,
  materia: PropTypes.shape().isRequired,
  materias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contrato: PropTypes.shape().isRequired,
};

Materia.defaultProps = {
  materiaActive: null,
  aulaActive: null,
  atividadeActive: null,
};
