import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MuiModal, Fade, Typography } from '@material-ui/core';
import Image from 'material-ui-image';

import LoadingImg from '~/assets/ic_loading_payment.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(4),
    maxHeight: '490px',
    maxWidth: '440px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#50A6FF',
    fontSize: '31px',
    lineHeight: '36px',
    textAlign: 'center',
    margin: '20px 0',
  },
  subtitle: {
    fontSize: '19px',
    textAlign: 'center',
  },
}));

export default function Loading({ open, setOpen }) {
  const classes = useStyles();

  return (
    <div>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Image
              src={LoadingImg}
              style={{
                display: 'flex',
                paddingTop: 'unset',
                marginRight: '20px',
              }}
              imageStyle={{
                width: 'unset',
                height: 'unset',
                position: 'unset',
                maxHeight: '255px',
              }}
            />
            <Typography className={classes.title}>
              Pagamento em processamento
            </Typography>
            <Typography color="secondary" className={classes.subtitle}>
              Seu pagamento está sendo processado, aguarde...
            </Typography>
          </div>
        </Fade>
      </MuiModal>
    </div>
  );
}

Loading.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
