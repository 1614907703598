export function aulaChoosed(aula) {
  return {
    type: '@aula/CHOOSED',
    payload: { aula },
  };
}

export function limparConteudo() {
  return {
    type: '@aula/CLEAN',
  };
}

export function aulaChoosedSuccess(aula) {
  return {
    type: '@aula/CHOOSED_SUCCESS',
    payload: { aula },
  };
}

export function changeMode(mode) {
  return {
    type: '@aula/CHANGE_MODE',
    payload: { mode },
  };
}
