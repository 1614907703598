import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { format, isPast, isValid } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { darken } from 'polished';
import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import history from '~/services/history';
import { request } from '~/store/modules/contratos/actions';
import { getContratoLabels } from '~/store/modules/funcionalidades/actions';

export default function Contratos() {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    tituloFuncionalidade: {
      textTransform: 'uppercase',
      color: '#3E98C7',
      fontWeight: 'bold',
    },
    captionHeader: {
      fontWeight: 'bold',
      color: 'rgba(255, 255, 255, 0.46)',
      fontSize: '11px',
    },
    caption: {
      fontWeight: 'bold',
      color: '#4D5884',
      fontSize: '14px',
    },
    infoHeader: {
      color: '#FFFFFFFF',
      fontSize: '20px',
      letterSpacing: '0.15px',
      fontWeight: 500,
    },
    info: {
      color: '#50A6FF',
      fontSize: '14px',
    },
    dataParcelaVencida: {
      color: '#F5415A',
      fontSize: '14px',
    },
    btPagarVencida: {
      color: '#fff',
      background: '#F5415A',
      padding: '2px 10px',
      cursor: 'pointer',
      marginLeft: '5px',
      borderRadius: '4px',
      textTransform: 'uppercase',
      fontSize: '10px',
      '&:hover': {
        background: `${darken(0.2, '#F5415A')}`,
      },
    },
    gridBtPagar: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    btPagar: {
      color: '#fff',
      background: '#50A6FF',
      padding: '2px 10px',
      cursor: 'pointer',
      marginLeft: '5px',
      borderRadius: '4px',
      textTransform: 'uppercase',
      fontSize: '10px',
      '&:hover': {
        background: `${darken(0.2, '#50A6FF')}`,
      },
    },
    infoPendente: {
      color: '#FFF',
      fontSize: '10px',
      background: '#F5415A',
      borderRadius: '4px',
      padding: '1px 15px',
      width: 'fit-content',
      textTransform: 'uppercase',
    },
    infoParcelaPendente: {
      color: '#F5415A',
      fontSize: '14px',
    },
    cardHeader: {
      padding: theme.spacing(3, 5),
      background: '#50A6FF',
    },
    cardHeaderPendente: {
      padding: theme.spacing(3, 5),
      background: '#F5415A',
    },
    cardBody: {
      padding: theme.spacing(3, 5),
    },
    gridPagamento: {
      padding: '20px',
      background: '#F7F8FA',
      marginTop: '10px',
      borderRadius: '7px',
    },
    gridInfo: {
      marginTop: '10px',
    },
    btDetalhes: {
      color: '#50A6FF',
    },
    btDetalhesPendente: {
      color: '#F5415A',
      borderRadius: '200px',
      border: '1px solid #F5415A',
    },
    btDetalhesPendenteDisabled: {
      color: '#808080',
      borderRadius: '200px',
      border: '1px solid #808080',
    },
    gridDetalhes: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '15px',
    },
  }));

  const classes = useStyles();
  const contratos = useSelector((state) => state.contratos.data);
  const labels = useSelector((state) => state.funcionalidades.labels);

  useEffect(() => {
    if (!labels) {
      dispatch(getContratoLabels());
    }
  }, [labels]); // eslint-disable-line

  useEffect(() => {
    async function getContratos() {
      dispatch(request());
    }

    if (
      !contratos ||
      !contratos.cache ||
      new Date() - contratos.cache > 60000
    ) {
      getContratos();
    }
  }, [dispatch, contratos]);

  function handleClickAceite(contrato) {
    history.push(`/pendente-aceite/${contrato}`);
  }

  function handleClickDetalhes(contrato) {
    history.push(`/detalhes-contrato/${contrato}`);
  }

  function getDataParcela(dataParcela) {
    if (!isValid(new Date(dataParcela))) {
      return null;
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} lg={7}>
          <Typography className={classes.dataParcelaVencida}>
            {format(new Date(dataParcela), 'dd/MM/yyyy', {
              locale: pt,
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridBtPagar}>
          <Typography
            className={
              isPast(new Date(dataParcela))
                ? classes.btPagarVencida
                : classes.btPagar
            }
            onClick={() => {
              history.push('/financeiro');
            }}
          >
            Pagar
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function getValorParcela(valor, dataParcela) {
    if (isPast(new Date(dataParcela))) {
      return (
        <Typography className={classes.infoParcelaPendente}>
          {`R$ ${valor}`}
        </Typography>
      );
    }

    return (
      <Typography className={classes.infoParcelaPendente}>
        {`R$ ${valor}`}
      </Typography>
    );
  }

  return (
    <>
      <Breadcrumb funcionalidade="Contratos" />
      <Typography variant="h6" className={classes.tituloFuncionalidade}>
        Contratos
      </Typography>
      <Grid container spacing={2}>
        {contratos &&
          contratos.dados &&
          contratos.dados
            .filter((contrato) => {
              return parseInt(contrato.numerocontrato, 10) > 0;
            })
            .map((contrato) => {
              if (contrato.pendenteaceite) {
                return (
                  <Grid item xs={12} md={6} lg={4} key={contrato.contrato_id}>
                    <Card className={classes.cardHeaderPendente}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography
                            component="p"
                            variant="caption"
                            className={classes.captionHeader}
                          >
                            {(labels &&
                              labels.contrato.singular.toUpperCase()) ||
                              'CURSO'}
                          </Typography>
                          <Typography className={classes.infoHeader}>
                            {contrato.curso}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            component="p"
                            variant="caption"
                            className={classes.captionHeader}
                          >
                            N° DO CONTRATO
                          </Typography>
                          <Typography className={classes.infoHeader}>
                            {contrato.numerocontrato}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Card className={classes.cardBody}>
                      <Grid container>
                        <Grid item xs={12} md={6} className={classes.gridInfo}>
                          <Typography
                            component="p"
                            variant="caption"
                            className={classes.caption}
                          >
                            Status
                          </Typography>
                          <Typography className={classes.infoPendente}>
                            Pendente Aceite
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridDetalhes}>
                          {contrato.istitular && (
                            <Button
                              className={classes.btDetalhesPendente}
                              fullWidth
                              onClick={() => {
                                handleClickAceite(contrato.contrato_id);
                              }}
                            >
                              IR PARA O ACEITE DE CONTRATO
                            </Button>
                          )}
                          {!contrato.istitular && (
                            <Button
                              className={classes.btDetalhesPendenteDisabled}
                              fullWidth
                              color="grey"
                              disabled
                            >
                              PENDENTE ACEITE DO TITULAR
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} md={6} lg={4} key={contrato.contrato_id}>
                  <Card className={classes.cardHeader}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.captionHeader}
                        >
                          {(labels && labels.contrato.singular.toUpperCase()) ||
                            'CURSO'}
                        </Typography>
                        <Typography className={classes.infoHeader}>
                          {contrato.curso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.captionHeader}
                        >
                          N° DO CONTRATO
                        </Typography>
                        <Typography className={classes.infoHeader}>
                          {contrato.numerocontrato}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                  <Card className={classes.cardBody}>
                    <Grid container>
                      <Grid item xs={12} md={6} className={classes.gridInfo}>
                        <Typography
                          component="p"
                          variant="caption"
                          className={classes.caption}
                        >
                          Títular
                        </Typography>
                        <Typography className={classes.info}>
                          {contrato.nometitular}
                        </Typography>
                      </Grid>
                      {contrato.mesesaula &&
                        Math.round(contrato.mesesaula) > 0 && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={classes.gridInfo}
                          >
                            <Typography
                              component="p"
                              variant="caption"
                              className={classes.caption}
                            >
                              Meses Aula
                            </Typography>
                            <Typography className={classes.info}>
                              {Math.round(contrato.mesesaula)}
                            </Typography>
                          </Grid>
                        )}
                      {contrato.matricula &&
                        contrato.matricula !== '' &&
                        isValid(new Date(contrato.matricula)) && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={classes.gridInfo}
                          >
                            <Typography
                              component="p"
                              variant="caption"
                              className={classes.caption}
                            >
                              Data da matrícula
                            </Typography>
                            <Typography className={classes.info}>
                              {format(
                                new Date(contrato.matricula),
                                'dd/MM/yyyy',
                                {
                                  locale: pt,
                                }
                              )}
                            </Typography>
                          </Grid>
                        )}
                      {Boolean(contrato.cargahoraria) &&
                        Math.round(contrato.cargahoraria / 60) > 0 && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={classes.gridInfo}
                          >
                            <Typography
                              component="p"
                              variant="caption"
                              className={classes.caption}
                            >
                              Carga Horária
                            </Typography>
                            <Typography className={classes.info}>
                              {Math.round(contrato.cargahoraria / 60)}
                            </Typography>
                          </Grid>
                        )}
                      {contrato.inicio &&
                        contrato.inicio !== '' &&
                        isValid(new Date(contrato.inicio)) && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={classes.gridInfo}
                          >
                            <Typography
                              component="p"
                              variant="caption"
                              className={classes.caption}
                            >
                              Início das aulas
                            </Typography>
                            <Typography className={classes.info}>
                              {format(new Date(contrato.inicio), 'dd/MM/yyyy', {
                                locale: pt,
                              })}
                            </Typography>
                          </Grid>
                        )}
                      <Grid item xs={12} className={classes.gridPagamento}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={7}>
                            <Typography
                              component="p"
                              variant="caption"
                              className={classes.caption}
                            >
                              Próxima parcela
                            </Typography>
                            {contrato.proximovencimento &&
                              getDataParcela(contrato.proximovencimento)}
                          </Grid>
                          <Grid item xs={12} md={6} lg={5}>
                            <Typography
                              component="p"
                              variant="caption"
                              className={classes.caption}
                            >
                              Valor da parcela
                            </Typography>
                            {contrato.valorparcela &&
                              contrato.proximovencimento &&
                              getValorParcela(
                                contrato.valorparcela,
                                contrato.proximovencimento
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.gridDetalhes}>
                        <Button
                          className={classes.btDetalhes}
                          onClick={() => {
                            handleClickDetalhes(contrato.contrato_id);
                          }}
                        >
                          Ver mais detalhes
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
      </Grid>
    </>
  );
}
