import { takeLatest, all, put, call } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';

import { unidadeOnSignInSuccess, unidadeChooseSuccess } from './actions';
import { getFuncionalidades } from '../funcionalidades/actions';

export function* setUnidadeHeader({ payload }) {
  const { unidade, token } = payload;
  api.defaults.headers.unidade_header = unidade.unidade_id;

  yield call(api.put, 'usuario');

  const responseTimezone = yield call(api.get, 'timezone');
  const { timezone } = responseTimezone.data[0];

  const response = yield call(api.get, 'aluno');

  // const responsePermissoes = yield call(api.get, 'access');
  // const { videofront } = jwt.verify(
  //   responsePermissoes.data.token,
  //   AuthConfig.secret
  // );

  yield put(unidadeChooseSuccess(response.data));
  yield put(unidadeOnSignInSuccess({ unidade, timezone }));
  yield put(getFuncionalidades());
  // yield put(loadPermissionSuccess({ videofront }));
  if (token) {
    history.push('/contratos');
  } else {
    history.push('/dashboard');
  }
}

export default all([takeLatest('@unidade/ON_SELECT', setUnidadeHeader)]);
