import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveStateNotificacoes } from './actions';

export function* getSistemaNotificacoes() {
  try {
    const response = yield call(api.get, 'notificacoes');

    yield put(saveStateNotificacoes(response.data));
  } catch (err) {
    toast.error('Falha ao carregar notificações.');
  }
}

export default all([
  takeLatest('@unidade/SIGN_IN_SUCCESS', getSistemaNotificacoes),
  takeLatest('@auth/REHYDRATE_SUCCESS', getSistemaNotificacoes),
]);
