import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import { fetchDocumentsFailure, fetchDocumentsSuccess } from './actions';

export function* fetchDocuments({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/contrato/${payload.numeroContrato}/documents`
    );

    const deliveredDocuments = response.data.filter(
      (document) => document.anexosentregues
    );

    const missingDocuments = response.data.filter(
      (document) => !document.anexosentregues
    );

    yield put(fetchDocumentsSuccess(deliveredDocuments, missingDocuments));
  } catch (err) {
    yield put(fetchDocumentsFailure());
  }
}

export default all([takeLatest('@documents/FETCH', fetchDocuments)]);
