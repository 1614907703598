import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import { darken } from 'polished';
import ReactPlayer from 'react-player';

import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';
import imgMeet from '~/assets/meet_class.svg';

const useStyles = makeStyles(() => ({
  btMeeting: {
    borderRadius: '200px',
    color: '#fff',
    background: '#50A6FF',
    width: '247px',
    margin: '15px 0',

    '&:hover': {
      background: darken(0.2, '#50A6FF'),
    },
  },
  arrowBt: {
    marginLeft: '10px',
  },
}));

export default function Hangouts() {
  const classes = useStyles();
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const isUrlFileUploaded = () => {
    return (
      (conteudoEscolhido.url &&
        conteudoEscolhido.url.includes('objectstorage')) ||
      (conteudoEscolhido.streaming_url &&
        conteudoEscolhido.streaming_url.includes('objectstorage'))
    );
  };

  const showButtonConferencia = () => {
    if (conteudoEscolhido.desativado || !conteudoEscolhido.url) {
      return (
        <>
          <Image
            src={imgConteudoDesativado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              backgroundColor: 'none',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography
            color="primary"
            variant="h5"
            component="div"
            align="center"
          >
            Fim da Transmissão
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            component="div"
            align="center"
          >
            A transmissão da videoaula foi encerrada ;)
          </Typography>
        </>
      );
    }

    if (
      (conteudoEscolhido.streaming_url || conteudoEscolhido.url) &&
      isUrlFileUploaded()
    ) {
      return (
        <ReactPlayer
          url={conteudoEscolhido.url || conteudoEscolhido.streaming_url}
          controls
        />
      );
    }

    return (
      <>
        <Image
          src={imgMeet}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            backgroundColor: 'none',
            justifyContent: 'center',
            margin: '15px 0',
          }}
          imageStyle={{
            width: 'unset',
            height: 'unset',
            position: 'unset',
          }}
        />
        <Typography color="primary" variant="h5" component="div" align="center">
          Sua aula já está acontencendo.
        </Typography>
        <Typography
          color="secondary"
          variant="body1"
          component="div"
          align="center"
        >
          Clique para entrar na sala.
        </Typography>
        <Button
          type="button"
          color="primary"
          variant="contained"
          className={classes.btMeeting}
          onClick={() => {
            const url = conteudoEscolhido.url.includes('http')
              ? conteudoEscolhido.url
              : `https://${conteudoEscolhido.url}`;
            window.open(url, '_blank');
          }}
        >
          ENTRAR NA SALA{' '}
          <ArrowForward className={classes.arrowBt} fontSize="small" />
        </Button>
      </>
    );
  };

  return <>{showButtonConferencia()}</>;
}
