import produce from 'immer';

const INITIAL_STATE = {
  contratos: 0,
  atividades: 0,
  videoAula: 0,
  data: null,
};

export default function notificacoes(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@notificacoes/ADD_NOTIFICACAO_CONTRATOS': {
        draft.contratos = action.payload.quantidade;
        break;
      }
      case '@notificacoes/REMOVE_NOTIFICACAO_CONTRATOS': {
        draft.contratos -= 1;
        break;
      }
      case '@notificacoes/REMOVE_NOTIFICACAO_ATIVIDADES': {
        draft.atividades -= 1;
        break;
      }
      case '@notificacoes/REMOVE_NOTIFICACAO_VIDEOAULA': {
        draft.videoAula -= 1;
        break;
      }
      case '@notificacoes/ADD_NOTIFICACOES': {
        draft.atividades = action.payload.atividades;
        draft.videoAula = action.payload.videoAula;
        break;
      }
      case '@notificacoes/SAVE_STATE_NOTIFICACOES': {
        draft.data = action.payload.notificacoes;
        break;
      }
      default:
    }
  });
}
