import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const useStyles = makeStyles(() => ({
  divProgresso: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progresso: {
    width: 70,
    marginLeft: '5px',
  },
}));

export default function Progresso({ materia }) {
  const classes = useStyles();
  let progresso;
  if (materia && materia.progresso) {
    progresso = materia.progresso;
  } else {
    progresso = 0.0;
  }

  return (
    <>
      <Typography variant="body1" component="span">
        Progresso da Matéria
      </Typography>
      <CircularProgressbar
        className={classes.progresso}
        value={progresso}
        maxValue={100}
        text={`${progresso}%`}
        strokeWidth={15}
        styles={buildStyles({
          strokeLinecap: 'butt',
          trailColor: '#d6d6d6',
        })}
      />
    </>
  );
}

Progresso.propTypes = {
  materia: PropTypes.shape().isRequired,
};
