import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ForumOutlined } from '@material-ui/icons';
import Iframe from 'react-iframe';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  frame: {
    border: 'none',
    width: '100%',
    height: '100%',
    maxHeight: '700px',
  },
  chat: {
    height: '100%',
    border: '1px solid #50A6FF',
    borderRadius: '7px 7px 0 0',
  },
  gridTitulo: {
    display: 'flex',
    alignItems: 'center',
    background: '#50A6FF',
    borderRadius: '5px 5px 0 0',
    fontWeight: 'bold',
    fontSize: '21px',
    lineHeight: '16px',
    color: '#FFFFFF',
    padding: theme.spacing(4, 2),
    maxHeight: '24px',
  },
  iconTitulo: {
    marginRight: '7px',
  },
  messages: {
    height: '100%',
    maxHeight: '651px',
  },
}));

export default function Chat({ videoAula, mobile }) {
  const classes = useStyles();
  const unidadeState = useSelector((state) => state.unidade.data);
  const userId = useSelector((state) => state.user.id);

  const userChatEmail = `${userId}@smart.alu.no`;
  const userChatPass = 'f10mobile';
  const unidade = `000000${unidadeState.unidade_id}`.slice(-6);

  const chatUrl = `${process.env.REACT_APP_CHAT_BASE_URL}/?unit=${unidade}&room=${videoAula}&user=${userChatEmail}&pass=${userChatPass}`;

  return (
    <Grid container className={classes.chat}>
      {!mobile && (
        <Grid item xs={12} className={classes.gridTitulo}>
          <ForumOutlined color="inherit" className={classes.iconTitulo} /> Chat
          da aula
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={clsx(classes.messages, mobile && { maxHeight: 'unset' })}
      >
        <Iframe
          url={chatUrl}
          display="initial"
          position="relative"
          className={classes.frame}
        />
      </Grid>
    </Grid>
  );
}

Chat.propTypes = {
  videoAula: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};

Chat.defaultProps = {
  mobile: false,
};
