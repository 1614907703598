import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  profile: {
    display: 'flex',
    color: '#333',
    alignItens: 'center',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },
  menu: {
    width: '276px',
    height: '432px',
    padding: '48px 19px 25px',
  },
  avatar: {
    color: '#fff',
    fontSize: '90px',
    background: '#BDC0D5',
    borderRadius: '50%',
    padding: '10px',
  },
  avatarButton: {
    color: '#fff',
    background: '#868CAB',
    borderRadius: '50%',
    padding: '4px',
    fontSize: '33px',
  },
  nome: {
    color: '#868CAB',
    fontSize: '24px',
    textAlign: 'center',
  },
  login: {
    color: '#BDC0D5',
    fontSize: '13px',
    textAlign: 'center',
    letterSpacing: '0.5px',
  },
  politica: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    color: '#868CAB',
    marginBottom: '10px',
  },
  exit: {
    borderRadius: '200px',
    border: '2px solid #868CAB',
    width: '171px',
    height: '36px',
    color: '#868CAB',
    fontSize: '14px',
    letterSpacing: '0.75px',
  },
  gridExit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  gridAvatar: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
