import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Modal,
  Fade,
  Button,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';
import { darken } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import CurrencyFormatter from 'currency-formatter';

import { getDataPagamento } from '~/store/modules/pagamento/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 10px',
  },
  divModal: {
    width: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    width: '850px',
    maxHeight: '600px',
    overflow: 'auto',
  },
  content: {
    width: '785px',
  },
  titulo: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#50A6FF',
    mixBlendMode: 'normal',
    marginBottom: '10px',
  },
  button: {
    marginLeft: '15px',
    background: '#50A6FF',
    borderRadius: '200px',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    marginBottom: '8px',
    marginTop: '23px',
    color: '#fff',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
    padding: theme.spacing(1, 5),
  },
  beta: {
    background: theme.palette.warning.main,
    color: '#fff',
    fontSize: '10px',
    padding: '1px 4px',
    borderRadius: '200px',
    letterSpacing: '1px',
    marginLeft: '30px',
    width: '100%',
  },
  field: {
    margin: '5px 0px',
  },
  label: {
    color: '#4D5884',
    fontSize: '14px',
    fontWeight: 700,
  },
  value: {
    color: '#50A6FF',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.25px',
  },
  valuePayment: {
    fontSize: '18px',
    fontWeight: 700,
  },
  topic: {
    margin: '18px 0px',
  },
  titleTopic: {
    fontSize: '18px',
    letterSpacing: '0.5px',
    color: '#868CAB',
  },
  divQRCode: {
    background: 'rgba(189, 192, 213, 0.4)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
  },
  qrCodeTitle: {
    fontSize: '17px',
    color: '#50A6FF',
    fontWeight: 700,
  },
  qrCodeTip: {
    fontSize: '14px',
    color: '#4D5884',
    fontWeight: 500,
    textAlign: 'center',
  },
  qrCodeButton: {
    borderRadius: '200px',
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.75px',
    color: '#fff',
    textTransform: 'uppercase',
    background: '#4D5884',
    '&:hover': {
      background: `${darken(0.1, '#4D5884')}`,
    },
  },
  qrCode: {
    margin: '20px 0px',
  },
}));

function ModalPix({ dadosRecebimento, receber_id }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { cobranca } = useSelector((state) => state.pagamento);
  const unidade = useSelector((state) => state.unidade.data);
  const profile = useSelector((state) => state.aluno.profile);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getDataPagamento(unidade.unidade_id, receber_id));
  }, [receber_id]); // eslint-disable-line

  const copyToClipboard = () => {
    if (cobranca.qrcode_text) {
      navigator.clipboard.writeText(cobranca.qrcode_text);
      toast.success('Pix copiado com sucesso!');
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const formatInstructions = (instructions) => {
    return (
      <ul>
        {instructions.split('\r').map((instruction) => (
          <li>{instruction}</li>
        ))}
      </ul>
    );
  };

  return (
    <div className={classes.divModal}>
      {/* <Badge
        badgeContent={<Typography className={classes.beta}>NOVO</Typography>}
      > */}
      {cobranca && cobranca.qrcode_text && (
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            setOpen(true);
          }}
          fullWidth
        >
          PAGAR VIA PIX
        </Button>
      )}
      {/* </Badge> */}
      <Modal className={classes.modal} open={open} onClose={handleCloseModal}>
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.titulo}>Dados do PIX</Typography>
              </Grid>
              <Grid xs={12} sm={12} md={7}>
                <Grid container>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>Aluno</Typography>
                    <Typography className={classes.value}>
                      {profile && profile.nome}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>Curso</Typography>
                    <Typography className={classes.value}>
                      {dadosRecebimento && dadosRecebimento.curso}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      Pagador/Titular
                    </Typography>
                    <Typography className={classes.value}>
                      {cobranca && cobranca.apelido}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>CPF</Typography>
                    <Typography className={classes.value}>
                      {cobranca && cobranca.cgc_cpf}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      Beneficiário
                    </Typography>
                    <Typography className={classes.value}>
                      {dadosRecebimento && dadosRecebimento.beneficiario}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      CNPJ do Beneficiário
                    </Typography>
                    <Typography className={classes.value}>
                      {dadosRecebimento && dadosRecebimento.cnpj}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      Banco do Beneficiário
                    </Typography>
                    <Typography className={classes.value}>
                      {cobranca && cobranca.banco}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      Agência/Cód. Beneficiário
                    </Typography>
                    <Typography className={classes.value}>
                      {dadosRecebimento && dadosRecebimento.agencia}/
                      {cobranca && `${cobranca.conta}-${cobranca.contadigito}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.topic}>
                    <Typography className={classes.titleTopic}>
                      Valores
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>Tipo</Typography>
                    <Typography className={classes.value}>
                      {dadosRecebimento && dadosRecebimento.tiporecebimento}
                      {cobranca &&
                        ` (${cobranca.parcela + 1}/${cobranca.total_parcelas})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      Valor total a pagar
                    </Typography>
                    <Typography
                      className={clsx(classes.value, classes.valuePayment)}
                    >
                      {dadosRecebimento &&
                        CurrencyFormatter.format(dadosRecebimento.valor, {
                          code: 'BRL',
                        })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.field}>
                    <Typography className={classes.label}>
                      Dia do Vencimento
                    </Typography>
                    <Typography className={classes.value}>
                      {dadosRecebimento && dadosRecebimento.vencimento}
                    </Typography>
                  </Grid>
                  {cobranca && cobranca.instrucao && (
                    <>
                      <Grid item xs={12} className={classes.topic}>
                        <Typography className={classes.titleTopic}>
                          Instruções
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} className={classes.field}>
                        <Typography className={classes.value}>
                          {cobranca && formatInstructions(cobranca.instrucao)}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={5} className={classes.divQRCode}>
                <Typography className={classes.qrCodeTitle}>
                  Use o QR Code do Pix para pagar
                </Typography>
                <Typography className={classes.qrCodeTip}>
                  Abra o app em que vai fazer o pagamento, escaneie a imagem ou
                  copie o código do QR Code para fazer o pagamento.
                </Typography>
                {cobranca && (
                  <QRCode
                    size={256}
                    style={{
                      height: 'auto',
                      maxWidth: '100%',
                      width: '65%',
                      padding: '12px',
                      margin: '10px 0px',
                      background: '#fff',
                    }}
                    value={cobranca.qrcode_text}
                    viewBox="0 0 256 256"
                  />
                )}
                <Button
                  className={classes.qrCodeButton}
                  variant="contained"
                  color="secondary"
                  onClick={copyToClipboard}
                  fullWidth
                >
                  Copiar QR Code
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalPix.propTypes = {
  dadosRecebimento: PropTypes.shape().isRequired,
  receber_id: PropTypes.number.isRequired,
};

export default ModalPix;
