import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import apiBackendF10 from '~/services/apiBackendF10';

import {
  uploadContratError,
  uploadContratSuccessfully,
  setLoading,
  requestDadosContratadaSuccess,
  requestDadosCursoSuccess,
  requestDadosPagamentoSuccess,
  requestDadosPessoaisSuccess,
  requestTermosUsoSuccess,
} from './actions';

export function* generateContract({ payload }) {
  try {
    yield put(setLoading(true, 'Gerando autenticação...'));
    const responseAuth = yield call(
      apiBackendF10.post,
      `/unidade/anexos/contratoLinkUpload`,
      {
        unidade_id: payload.unidade_id,
        contrato_id: payload.contrato_id,
        pessoa_id: payload.pessoa_id,
        description: `Assinatura do contrato ${payload.contrato_id}`,
        subtype: 9,
        file_extension: 1,
        size: payload.bufferContrato.byteLength,
      }
    );

    const { link_id, urlUpload } = responseAuth.data;

    yield put(setLoading(true, 'Salvando contrato...'));
    yield call(apiBackendF10.put, urlUpload, payload.bufferContrato, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    const responseConfirm = yield call(
      apiBackendF10.post,
      '/unidade/anexos/confirmLinkUpload',
      {
        link_id,
        unidade_id: payload.unidade_id,
      }
    );

    if (!responseConfirm.data.result) {
      toast.error('Falha ao confirmar upload do contrato.');
      return;
    }

    yield put(uploadContratSuccessfully());
    yield put(setLoading(false, ''));
    toast.success('Contrato salvo com sucesso!');
  } catch (error) {
    yield put(setLoading(false, ''));
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    } else {
      toast.error('Falha ao aceitar contrato, favor contactar o suporte.');
    }

    yield put(uploadContratError());
  }
}

export function* getDadosCurso({ payload }) {
  try {
    const response = yield call(api.get, `/dados-curso/${payload.contrato_id}`);
    yield put(requestDadosCursoSuccess(response.data.dadosCurso));
  } catch (error) {
    toast.error('Falha ao carregar dados do curso, favor contactar o suporte.');
  }
}

export function* getDadosPagamento({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/dados-pagamento/${payload.contrato_id}`
    );
    yield put(requestDadosPagamentoSuccess(response.data.dadosPagamento));
  } catch (error) {
    toast.error(
      'Falha ao carregar dados do pagamento, favor contactar o suporte.'
    );
  }
}

export function* getDadosContratada() {
  try {
    const response = yield call(api.get, '/contratada');
    yield put(requestDadosContratadaSuccess(response.data.dadosContratada));
  } catch (error) {
    toast.error(
      'Falha ao carregar dados da contratada, favor contactar o suporte.'
    );
  }
}

export function* getDadosPessoais({ payload }) {
  try {
    const response = yield call(api.get, '/dados-pessoais', {
      params: { aluno: payload.aluno_id, titular: payload.titular_id },
    });
    yield put(requestDadosPessoaisSuccess(response.data));
  } catch (error) {
    toast.error('Falha ao carregar dados pessoais, favor contactar o suporte.');
  }
}

export function* getTermosUso({ payload }) {
  try {
    const response = yield call(api.get, `/termos/${payload.termo_id}`);
    yield put(requestTermosUsoSuccess(response.data.termos));
  } catch (error) {
    toast.error('Falha ao carregar termos de uso, favor contactar o suporte.');
  }
}

export default all([
  takeLatest('@aceitecontrato/UPLOAD_CONTRACT', generateContract),
  takeLatest('@aceitecontrato/DADOS_CURSO_REQUEST', getDadosCurso),
  takeLatest('@aceitecontrato/DADOS_PAGAMENTO_REQUEST', getDadosPagamento),
  takeLatest('@aceitecontrato/DADOS_CONTRATADA_REQUEST', getDadosContratada),
  takeLatest('@aceitecontrato/DADOS_PESSOAIS_REQUEST', getDadosPessoais),
  takeLatest('@aceitecontrato/TERMOS_USO_REQUEST', getTermosUso),
]);
