import produce from 'immer';

const INITIAL_STATE = {
  conteudo: null,
};

export default function vejaemcasa(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@vejaemcasa/CHOOSED': {
        draft.conteudo = action.payload.aula;
        break;
      }
      case '@vejaemcasa/CHOOSED_SUCCESS': {
        draft.conteudo = action.payload.aula;
        break;
      }
      case '@vejaemcasa/CLEAN': {
        draft.conteudo = null;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.conteudo = null;
        break;
      }
      default:
    }
  });
}
