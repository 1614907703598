import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import CurrencyFormatter from 'currency-formatter';
import { useDispatch, useSelector } from 'react-redux';

import ModalLivros from '~/components/Contrato/DadosCurso/ModalLivros';
import ModalMaterias from '~/components/Contrato/DadosCurso/ModalMaterias';
import ModalTurmas from '~/components/Contrato/DadosCurso/ModalTurmas';
import {
  requestDadosCurso,
  requestDadosPagamento,
} from '~/store/modules/aceitecontrato/actions';
import GridParcelas from './GridParcelas';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    border: 'none',
    boxShadow: 'none',
    marginTop: '15px',
  },
  box: {
    background: 'rgba(234, 236, 245, 0.4)',
    marginTop: '10px',
    borderRadius: '10px',
  },
  gridItem: {
    padding: '10px',
  },
  caption: {
    fontWeight: 'bold',
    color: '#4D5884',
    fontSize: '14px',
  },
  info: {
    color: '#50A6FF',
    fontSize: '14px',
  },
  sessao: {
    fontSize: '18px',
    color: '#868CAB',
    margin: '10px 0',
  },
  divider: {
    marginTop: '15px',
    height: '2px',
    background: '#bdc0d5',
  },
  titulo: {
    fontSize: '20px',
    color: '#4D5884',
    marginBottom: '15px',
  },
  dadosCurso: {
    fontSize: '18px',
    color: '#868CAB',
    margin: '15px 0',
  },
  boxMensalidade: {
    maxHeight: '300px',
    background: 'rgba(234, 236, 245, 0.95)',
    borderRadius: '10px',
    overflow: 'auto',
    width: '100%',
    padding: '15px',
  },
  cell: {
    color: '#50A6FF',
  },
  labelMore: {
    fontWeight: 'bold',
    marginLeft: '20px',
  },
});

const TIPO_MATRICULA = 'Matricula';
const TIPO_PARCELA = 'Parcela';
const TIPO_MATERIAL = 'Material Didático';

export default function DadosCurso({ contrato }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dados = useSelector((state) => state.aceitecontrato.dadosCurso);
  const labels = useSelector((state) => state.funcionalidades.labels);
  const dadosPagamento = useSelector(
    (state) => state.aceitecontrato.dadosPagamento
  );

  const [materias, setMaterias] = useState(null);
  const [openMaterias, setOpenMaterias] = useState(false);
  const [openLivros, setOpenLivros] = useState(false);
  const [openTurmas, setOpenTurmas] = useState(false);

  useEffect(() => {
    if (contrato && !dados) {
      dispatch(requestDadosCurso(contrato.contrato_id));
      if (!dadosPagamento) {
        dispatch(requestDadosPagamento(contrato.contrato_id));
      }
    }
  }, [contrato]); // eslint-disable-line

  const canShowDadosTurma = () => {
    return (
      dados &&
      dados.turmas &&
      dados.turmas.length !== 1 &&
      (dados.turmas.length === 0 || dados.turmas[0].nome === 'EAD')
    );
  };

  function getDadosTurmas() {
    if (dados.turmas.length > 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {dados.turmas[0].nome}
          </Typography>
          <Typography className={classes.info}>
            {`${dados.turmas[1].nome}`}
            <Fab
              size="small"
              color="primary"
              className={classes.labelMore}
              onClick={() => {
                setOpenTurmas(!openTurmas);
              }}
            >{`+${dados.turmas.length - 2}`}</Fab>
          </Typography>
        </>
      );
    }

    if (dados.turmas.length === 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {dados.turmas[0].nome}
          </Typography>
          <Typography className={classes.info}>
            {dados.turmas[1].nome}
          </Typography>
        </>
      );
    }

    if (dados.turmas[0].nome === 'EAD' || dados.turmas[0].nome === 'ead') {
      return <></>;
    }

    return (
      <Typography className={classes.info} component="p">
        {dados.turmas[0].nome}
      </Typography>
    );
  }

  function getDadosLivros() {
    if (dados.livros.length > 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {dados.livros[0].livro}
          </Typography>
          <Typography className={classes.info}>
            {`${dados.livros[1].livro} `}
            <Fab
              size="small"
              color="primary"
              className={classes.labelMore}
              onClick={() => {
                setOpenLivros(!openLivros);
              }}
            >{`+${dados.livros.length - 2}`}</Fab>
          </Typography>
        </>
      );
    }

    if (dados.livros.length === 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {dados.livros[0].livro}
          </Typography>
          <Typography className={classes.info}>
            {dados.livros[1].livro}
          </Typography>
        </>
      );
    }

    return (
      <Typography className={classes.info} component="p">
        {dados.livros[0].livro}
      </Typography>
    );
  }

  function getMateriasCurso() {
    const materiasOrdenadas = [...dados.materias];
    materiasOrdenadas.sort((a, b) => a.sequencia - b.sequencia);

    if (materiasOrdenadas.length > 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {materiasOrdenadas[0].materia}
          </Typography>
          <Typography className={classes.info}>
            {`${materiasOrdenadas[1].materia} `}
            <Fab
              size="small"
              color="primary"
              className={classes.labelMore}
              onClick={() => {
                setMaterias(materiasOrdenadas);
                setOpenMaterias(!openMaterias);
              }}
            >{`+${materiasOrdenadas.length - 2}`}</Fab>
          </Typography>
        </>
      );
    }

    if (materiasOrdenadas.length === 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {materiasOrdenadas[0].materia}
          </Typography>
          <Typography className={classes.info}>
            {materiasOrdenadas[1].materia}
          </Typography>
        </>
      );
    }

    return (
      <Typography className={classes.info} component="p">
        {materiasOrdenadas[0].materia}
      </Typography>
    );
  }

  function getMateriasGratuitas() {
    if (dados.materias_gratuitas.length > 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {dados.materias_gratuitas[0].materia}
          </Typography>
          <Typography className={classes.info}>
            {`${dados.materias_gratuitas[1].materia} `}
            <Fab
              size="small"
              color="primary"
              className={classes.labelMore}
              onClick={() => {
                setMaterias(dados.materias_gratuitas);
                setOpenMaterias(!openMaterias);
              }}
            >{`+${dados.materias_gratuitas.length - 2}`}</Fab>
          </Typography>
        </>
      );
    }

    if (dados.materias_gratuitas.length === 2) {
      return (
        <>
          <Typography className={classes.info} component="p">
            {dados.materias_gratuitas[0].materia}
          </Typography>
          <Typography className={classes.info}>
            {dados.materias_gratuitas[1].materia}
          </Typography>
        </>
      );
    }

    return (
      <Typography className={classes.info} component="p">
        {dados.materias_gratuitas[0].materia}
      </Typography>
    );
  }

  const getObservacoesParcela = (parcelas, tipo) => {
    if (!parcelas || parcelas.length === 0) {
      return '';
    }

    const parcelasFiltered = parcelas.filter(
      (parcela) => parcela.tipo === tipo
    );

    if (parcelasFiltered.length === 0) {
      return '';
    }

    const label =
      parcelasFiltered[0].tipo === TIPO_MATRICULA
        ? 'da Matrícula'
        : parcelasFiltered[0].tipo === TIPO_PARCELA
        ? 'das Parcelas'
        : 'do Material Didático';

    return (
      parcelasFiltered[0].observacao && (
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          key={parcelasFiltered[0].tipo}
        >
          <Typography
            component="p"
            variant="caption"
            className={classes.caption}
          >
            Descontos e Acréscimos {label}
          </Typography>
          <div className={classes.info}>
            {parcelasFiltered[0].observacao.split('\r').map((i) => (
              <div key={i}>
                {`${i}`} <br />
              </div>
            ))}
          </div>
        </Grid>
      )
    );
  };

  const showCursos = () => {
    if (!dados) {
      return '';
    }

    if (dados && dados.lista_cursos) {
      const listaCursos = dados.lista_cursos.split(',');
      return listaCursos.map((curso) => (
        <Typography component="p" className={classes.info}>
          {curso}
        </Typography>
      ));
    }

    return dados.curso;
  };

  // eslint-disable-next-line consistent-return
  const showDuracao = () => {
    if (!dados) {
      return '';
    }

    let duracao = 0;

    if (dados.duracao_total) {
      duracao = Math.trunc(dados.duracao_total);
    } else {
      duracao = Math.trunc(dados.meses);
    }

    if (duracao > 0) {
      return (
        <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
          <Typography
            component="p"
            variant="caption"
            className={classes.caption}
          >
            Duração Aproximada do Curso (em meses)
          </Typography>
          <Typography className={classes.info}>{duracao}</Typography>
        </Grid>
      );
    }
  };

  return (
    <>
      {contrato.pendenteaceite && (
        <>
          <Typography className={classes.titulo}>
            Verifique os dados pessoais
          </Typography>
          <Divider />
          <Typography className={classes.dadosCurso}>
            Dados do {labels.contrato.singular}
          </Typography>
        </>
      )}
      <Box p={3} className={classes.box}>
        <Grid container>
          <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              {labels.contrato.singular}
            </Typography>
            <Typography className={classes.info}>{showCursos()}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Tipo de contrato
            </Typography>
            <Typography className={classes.info}>
              {dados && dados.tipo}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Data da matrícula
            </Typography>
            <Typography className={classes.info}>
              {dados && dados.matricula}
            </Typography>
          </Grid>
          {dados &&
            dados.inicio &&
            !['Não Definido'].includes(dados.inicio) && (
              <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.caption}
                >
                  Início das aulas
                </Typography>
                <Typography className={classes.info}>{dados.inicio}</Typography>
              </Grid>
            )}
          {showDuracao()}
          {dados && dados.notacorte && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Notas de corte
              </Typography>
              <Typography className={classes.info}>
                {dados.notacorte}
              </Typography>
            </Grid>
          )}
          {dados && dados.multa && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Multa contratual
              </Typography>
              <Typography className={classes.info}>{dados.multa}</Typography>
            </Grid>
          )}
          {dados && dados.valorreposicao && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Valor Reposição de Aula
              </Typography>
              <Typography className={classes.info}>
                {CurrencyFormatter.format(dados.valorreposicao, {
                  code: 'BRL',
                })}
              </Typography>
            </Grid>
          )}
          {Boolean(contrato.cargahoraria) && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Carga Horária
              </Typography>
              <Typography className={classes.info}>
                {`${Math.trunc(contrato.cargahoraria / 60)} horas`}
              </Typography>
            </Grid>
          )}
          {dados && dados.frequenciaminima && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Frequência Mínima
              </Typography>
              <Typography className={classes.info}>
                {dados.frequenciaminima}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider className={classes.divider} variant="fullWidth" />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" className={classes.sessao}>
              Turmas e matérias
            </Typography>
          </Grid>
          {canShowDadosTurma() && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Turma
              </Typography>
              {getDadosTurmas()}
            </Grid>
          )}
          {dados && dados.livros && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Livros
              </Typography>
              {getDadosLivros()}
            </Grid>
          )}
          <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Matérias do curso
            </Typography>
            {dados && dados.materias && getMateriasCurso()}
          </Grid>
          {dados && dados.materias_gratuitas && (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Matérias gratuitas
              </Typography>
              {dados && dados.materias_gratuitas && getMateriasGratuitas()}
            </Grid>
          )}
          {dados &&
            (Boolean(dados.matricula_valor) ||
              Boolean(dados.materialdidatico_valor)) && (
              <>
                <Grid item xs={12}>
                  <Divider className={classes.divider} variant="fullWidth" />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" className={classes.sessao}>
                    Valores de Matrícula e Material
                  </Typography>
                </Grid>
              </>
            )}
          {dados && dados.matricula_valor && (
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Valor da matrícula
              </Typography>
              <Typography className={classes.info}>
                {CurrencyFormatter.format(dados.matricula_valor, {
                  code: 'BRL',
                })}
              </Typography>
            </Grid>
          )}
          {dados &&
            dados.matricula_parcelas &&
            parseInt(dados.matricula_parcelas, 10) > 0 && (
              <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.caption}
                >
                  Quantidade de Parcelas da Matrícula
                </Typography>
                <Typography className={classes.info}>
                  {dados.matricula_parcelas}
                </Typography>
              </Grid>
            )}
          {dados &&
            parseInt(dados.matricula_parcelas, 10) > 0 &&
            dados.matricula_vencimento && (
              <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.caption}
                >
                  Data de vencimento
                </Typography>
                <Typography className={classes.info}>
                  {dados.matricula_vencimento}
                </Typography>
              </Grid>
            )}
          {dadosPagamento &&
            dadosPagamento.parcelas_pagar &&
            getObservacoesParcela(
              dadosPagamento.parcelas_pagar,
              TIPO_MATRICULA
            )}
          <Grid item xs={12} />
          {dados && dados.materialdidatico_valor && (
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Valor do Material Didático
              </Typography>
              <Typography className={classes.info}>
                {CurrencyFormatter.format(dados.materialdidatico_valor, {
                  code: 'BRL',
                })}
              </Typography>
            </Grid>
          )}
          {dados &&
            dados.materialdidatico_parcelas &&
            parseInt(dados.materialdidatico_parcelas, 10) > 0 && (
              <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.caption}
                >
                  Quantidade de Parcelas de Material Didático
                </Typography>
                <Typography className={classes.info}>
                  {dados.materialdidatico_parcelas}
                </Typography>
              </Grid>
            )}
          {dados &&
            dados.materialdidatico_vencimento &&
            parseInt(dados.materialdidatico_parcelas, 10) > 0 && (
              <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.caption}
                >
                  Data de Vencimento
                </Typography>
                <Typography className={classes.info}>
                  {format(
                    new Date(dados.materialdidatico_vencimento),
                    'dd/MM/yyyy',
                    {
                      locale: ptBR,
                    }
                  )}
                </Typography>
              </Grid>
            )}
          {dados && dados.obs && (
            <Grid item xs={12} className={classes.gridItem}>
              <Typography
                component="p"
                variant="caption"
                className={classes.caption}
              >
                Observações gerais
              </Typography>
              <Typography className={classes.info}>{dados.obs}</Typography>
            </Grid>
          )}
          {dadosPagamento &&
            dadosPagamento.parcelas_pagar &&
            getObservacoesParcela(dadosPagamento.parcelas_pagar, TIPO_MATERIAL)}
          <Grid item xs={12}>
            <Divider className={classes.divider} variant="fullWidth" />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" className={classes.sessao}>
              Valores das parcelas
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.boxMensalidade}>
              {dados && dados.parcelas && (
                <GridParcelas parcelas={dados.parcelas} />
              )}
            </div>
          </Grid>
          {(dados || dadosPagamento) &&
            getObservacoesParcela(
              (dados && dados.parcelas) ||
                (dadosPagamento && dadosPagamento.parcelas_pagar) ||
                [],
              TIPO_PARCELA
            )}
        </Grid>
      </Box>
      {materias && openMaterias && (
        <ModalMaterias
          open={openMaterias}
          setOpen={setOpenMaterias}
          materias={materias}
        />
      )}
      {openLivros && (
        <ModalLivros
          open={openLivros}
          setOpen={setOpenLivros}
          livros={dados.livros}
        />
      )}
      {openTurmas && (
        <ModalTurmas
          open={openTurmas}
          setOpen={setOpenTurmas}
          turmas={dados.turmas}
        />
      )}
    </>
  );
}

DadosCurso.propTypes = {
  contrato: PropTypes.shape().isRequired,
};
