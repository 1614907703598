import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '~/services/history';

function errorHandler(err) {
  if (err.response && err.response.status && err.response.status === 401) {
    const errorMsg = err.response.data.error
      ? err.response.data.error
      : 'Sua sessão expirou.';
    toast.error(errorMsg);
    console.log(`NAO AUTORIZADO: 401 - ${errorMsg}`);
    history.push('/logout', true);
  }
}

export function* callHandler(fn, url, data) {
  try {
    const response = yield call(fn, url, data);
    return yield response;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
}

export async function apiCallHandler(fn, url, data, config) {
  try {
    return await fn(url, data, config);
  } catch (err) {
    errorHandler(err);
    throw err;
  }
}

export default callHandler;
