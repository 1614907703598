import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CssBaseline, Paper, Grid, Typography } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import Loading from '~/components/Loading';

import { useLoading } from '~/hooks/loading';

import schoolTheme from '~/styles/theme';
import { getConfigurations } from '~/config/Instituicoes';
import { loadSuccess } from '~/store/modules/instituicao/actions';

export default function AuthLayout({ children }) {
  const dispatch = useDispatch();
  const instituicao = useSelector((state) => state.instituicao.data);
  const [configLoaded, setConfigLoaded] = useState(false);
  const { loadingShowed } = useLoading();

  const initSystem = async () => {
    let escola = window.location.host.split('.')[1];
    const subDominio = window.location.hostname.split('.')[0];

    if (!escola || (escola === 'f10' && subDominio === 'portalaluno')) {
      escola = 'default';
    }

    const configurations = await getConfigurations(escola);

    dispatch(loadSuccess(configurations));
    setConfigLoaded(true);
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', `${escola}.ico`);
  };

  useEffect(() => {
    if (!configLoaded) {
      initSystem();
    }
  }, [configLoaded]); // eslint-disable-line

  const useStyles = makeStyles(() => ({
    wrapper: {
      backgroundImage: `url(${instituicao.background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100vh',
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      padding: '40px',
      borderRadius: '16px',
      maxWidth: '357px',
    },
    logo: {
      top: '-85px',
      display: 'block',
      width: '160px',
      height: '160px',
      border: `4px solid ${instituicao.primary_color}`,
      position: 'absolute',
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundColor: 'white',
      backgroundImage: `url(${instituicao.logo_full_path})`,
    },
    title: {
      marginTop: '45px',
      fontWeight: 'bold',
    },
    subtitle: {
      maxWidth: '160px',
      textAlign: 'center',
      marginBottom: '16px',
    },
  }));

  const classes = useStyles();

  return (
    <ThemeProvider theme={schoolTheme(instituicao)}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.wrapper}
      >
        <CssBaseline />
        <Grid item lg={3} md={4} sm={8} xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.logo} />
            <Typography variant="h6" className={classes.title} color="primary">
              Portal do aluno
            </Typography>
            <Typography
              variant="body2"
              component="p"
              color="secondary"
              className={classes.subtitle}
            >
              Veja suas notas, matérias e conteúdos
            </Typography>
            {children}
            {loadingShowed && <Loading />}
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
