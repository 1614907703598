import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider, Card } from '@material-ui/core';
import { darken } from 'polished';
import CurrencyFormatter from 'currency-formatter';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import ModalPagamento from '~/components/Pagamento/Modal';
// import ModalLinhaDigitavel from '~/components/Pagamento/ModalLinhaDigitavel';
import ModalBoleto from '~/components/Pagamento/ModalBoleto';
import ModalPix from '~/components/Pagamento/ModalPix';

import api from '~/services/api';
// import history from '~/services/history';

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  card: {
    padding: theme.spacing(3, 4),
    borderRadius: '8px',
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },
  dadosPagamento: {
    padding: theme.spacing(2, 3),
    background: 'rgba(234, 236, 245, 0.4)',
    borderRadius: '16px',
  },
  dadosValores: {
    padding: theme.spacing(1.3, 3),
    background: '#E3E4ED',
    borderRadius: '16px',
  },
  labelHeader: {
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '24px',
    color: '#868CAB',
  },
  valueHeader: {
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.15px',
    color: '#50A6FF',
  },
  session: {
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: '#868CAB',
  },
  sessionLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#4D5884',
    fontWeight: 'bold',
  },
  valueSessionEscola: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
  },
  valueSessionValores: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    background: '#47D19D',
    borderRadius: '200px',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    marginBottom: '8px',
    marginTop: '23px',
    color: '#fff',
    '&:hover': {
      background: `${darken(0.1, '#47D19D')}`,
    },
    padding: theme.spacing(1, 5),
  },
  btVoltar: {
    background: '#4D5884',
    borderRadius: '200px',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    marginBottom: '8px',
    marginTop: '23px',
    color: '#fff',
    '&:hover': {
      background: `${darken(0.1, '#4D5884')}`,
    },
    padding: theme.spacing(1, 5),
  },
}));

export default function Pagamento({ match }) {
  const { id_receber } = match.params;
  const classes = useStyles();

  const { cobranca } = useSelector((state) => state.pagamento);

  const [openModal, setOpenModal] = useState(false);
  const [dadosRecebimento, setDadosRecebimento] = useState(null);
  const [scriptPjBankFixo, setScriptPjBankFixo] = useState(null);
  const production = process.env.NODE_ENV === 'production';
  // const [scriptPjBankParc, setScriptPjBankParc] = useState(null);

  useEffect(() => {
    async function getDadosRecebimento() {
      const response = await api.get(`/financeiro/rececimento/${id_receber}`);
      setDadosRecebimento(response.data);
    }

    if (!dadosRecebimento) {
      getDadosRecebimento();
    }

    if (
      dadosRecebimento &&
      dadosRecebimento.credencial_cartao_fixo &&
      !scriptPjBankFixo
    ) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.async = true;
      script.append(`
        superlogica.require('pjbank');
        superlogica.pjbank('checkout_transparente', '${
          dadosRecebimento.credencial_cartao_fixo
        }', ${!production});
      `);

      document.body.appendChild(script);
      setScriptPjBankFixo(script);
    }

    // if (
    //   dadosRecebimento &&
    //   dadosRecebimento.credencial_cartao_perc &&
    //   !scriptPjBankParc
    // ) {
    //   const script = document.createElement('script');

    //   script.type = 'text/javascript';
    //   script.async = true;
    //   script.append(`
    //     superlogica.require('pjbank');
    //     superlogica.pjbank('checkout_transparente', '${dadosRecebimento.credencial_cartao_perc}', true);
    //   `);

    //   document.body.appendChild(script);
    //   setScriptPjBankParc(script);
    // }
  }, [dadosRecebimento, scriptPjBankFixo]); //eslint-disable-line

  return (
    <>
      <Breadcrumb
        categorias={['Financeiro']}
        funcionalidade="Pagamento Online"
      />
      <Typography variant="h6" className={classes.title}>
        Pagamento online
      </Typography>
      {dadosRecebimento && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card elevation={5} className={classes.card}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} className={classes.header}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.labelHeader}
                  >
                    CURSO
                  </Typography>
                  <Typography variant="body1" className={classes.valueHeader}>
                    {dadosRecebimento.curso}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.labelHeader}
                  >
                    N° DO CONTRATO
                  </Typography>
                  <Typography variant="body1" className={classes.valueHeader}>
                    {dadosRecebimento.numerocontrato}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    className={classes.dadosPagamento}
                  >
                    <Grid item xs={12}>
                      <Typography
                        component="p"
                        variant="body1"
                        className={classes.session}
                      >
                        Dados da escola
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.sessionLabel}
                      >
                        Beneficiário
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.valueSessionEscola}
                      >
                        {dadosRecebimento.beneficiario}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.sessionLabel}
                      >
                        CNPJ do beneficiário
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.valueSessionEscola}
                      >
                        {dadosRecebimento.cnpj}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.sessionLabel}
                      >
                        Agência/Cód. Beneficiário
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.valueSessionEscola}
                      >
                        {dadosRecebimento.agencia}/
                        {`${dadosRecebimento.conta}-${dadosRecebimento.contadigito}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.session}>
                        Valores
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        className={classes.dadosValores}
                      >
                        <Grid item xs={4}>
                          <Typography
                            component="p"
                            variant="caption"
                            className={classes.sessionLabel}
                          >
                            Tipo
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.valueSessionValores}
                          >
                            {dadosRecebimento.tiporecebimento}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            component="p"
                            variant="caption"
                            className={classes.sessionLabel}
                          >
                            Valor total a pagar
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.valueSessionValores}
                          >
                            {CurrencyFormatter.format(dadosRecebimento.valor, {
                              code: 'BRL',
                            })}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            component="p"
                            variant="caption"
                            className={classes.sessionLabel}
                          >
                            Dia do vencimento
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.valueSessionValores}
                          >
                            {dadosRecebimento.vencimento}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridButton}>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item xs={12} sm={12} md={4}>
                      <ModalPix
                        dadosRecebimento={dadosRecebimento}
                        receber_id={id_receber}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4}>
                      <Button
                        variant="contained"
                        className={classes.btVoltar}
                        onClick={() => {
                          history.push('/financeiro');
                        }}
                        fullWidth
                      >
                        VOLTAR
                      </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={4}>
                      {(!cobranca || !cobranca.qrcode_text) && (
                        <ModalBoleto
                          contratoId={dadosRecebimento.contrato_id}
                          valor={CurrencyFormatter.format(
                            dadosRecebimento.valor,
                            {
                              code: 'BRL',
                            }
                          )}
                          linhaDigitavel={dadosRecebimento.linhadigitavel}
                          beneficiario={dadosRecebimento.beneficiario}
                          cnpjBeneficiario={dadosRecebimento.cnpj}
                          agenciaBeneficiario={dadosRecebimento.agencia}
                          codCedente={dadosRecebimento.codigocedente}
                          tipoRecebimento={dadosRecebimento.tiporecebimento}
                          dataVencimento={dadosRecebimento.vencimento}
                          parcela={dadosRecebimento.parcela}
                          total_parcelas={dadosRecebimento.total_parcelas}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    INSERIR DADOS DO CARTÃO
                  </Button> */}
                  {/* {dadosRecebimento.linhadigitavel && (
                    <ModalLinhaDigitavel
                      linhaDigitavel={dadosRecebimento.linhadigitavel}
                    />
                  )} */}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      {openModal && (
        <ModalPagamento
          open={openModal}
          setOpen={setOpenModal}
          dadosRecebimento={dadosRecebimento}
          recebimentoId={id_receber}
        />
      )}
    </>
  );
}

Pagamento.propTypes = {
  match: PropTypes.shape().isRequired,
};
