import React, { useState, useEffect } from 'react';
import { darken } from 'polished';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import Image from 'material-ui-image';
import IdleTimer from 'react-idle-timer';

import ImgConteudo from '~/assets/ilustra_conteudo.svg';
import ImgAgendado from '~/assets/waiting_class.svg';
import ImgAssistirVideo from '~/assets/grafismo_assistir_video.jpeg';

import Live from './Live';
import Video from './Video';
import Videofront from './Videofront';
import Hangouts from './Hangouts';
import Jitsi from './Jitsi';
import FullScreen from '../ConteudoFullScreen';

const useStyles = makeStyles({
  descricao: {
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  descricaoAgendado: {
    color: '#465471',
    fontSize: '34px',
    lineHeight: '40px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    marginTop: '15px',
    maxWidth: '315px',
  },
  caption: {
    color: '#777',
    fontWeight: 'bold',
  },
  podcast: {
    width: '100%',
    height: '70px',
  },
  buttonSala: {
    borderRadius: '200px',
    color: '#fff',
    background: '#50A6FF',
    width: '247px',

    '&:hover': {
      background: darken(0.2, '#50A6FF'),
    },
  },
  descricaoAulaEncerrada: {
    color: '#FF9239',
    fontWeight: 'bold',
    fontSize: '27px',
    lineHeight: '32px',
    textAlign: 'center',
    letterSpacing: '0.25px',
  },
  subDescricaoAulaEncerrada: {
    color: '#868CAB',
    fontSize: '16px',
    lineHeight: '21px',
    textAlign: 'center',
    letterSpacing: '0.15px',
  },
  divDescricao: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default function Conteudo() {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const [showArtConteudo, setShowArtConteudo] = useState(
    !!window.location.search.substr('conteudo')
  );
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setFullScreen(smallScreen);
  }, [smallScreen]);

  const getConteudo = () => {
    if (!conteudoEscolhido) {
      return (
        <>
          <Image
            src={ImgConteudo}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '10px',
              justifyContent: 'center',
              background: 'none',
            }}
            imageStyle={{
              width: '370px',
              height: 'unset',
              position: 'unset',
            }}
          />
          <div className={classes.divDescricao}>
            <Typography variant="h5" className={classes.descricao}>
              Preparado para estudar para valer?
            </Typography>
            <Typography variant="caption" className={classes.caption}>
              Selecione um conteúdo para começar.
            </Typography>
          </div>
        </>
      );
    }

    if (conteudoEscolhido && conteudoEscolhido.agendado) {
      return (
        <>
          <Image
            src={ImgAgendado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '10px',
              justifyContent: 'center',
              background: 'none',
            }}
            imageStyle={{
              width: '438px',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography component="div" className={classes.descricaoAgendado}>
            Essa aula começará em breve.
          </Typography>
        </>
      );
    }

    if (showArtConteudo) {
      return (
        <Image
          src={ImgAssistirVideo}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            marginRight: '20px',
            marginBottom: '10px',
            marginTop: '10px',
            justifyContent: 'center',
            background: 'none',
            cursor: 'pointer',
          }}
          imageStyle={{
            width: '640px',
            height: 'unset',
            position: 'unset',
            cursor: 'pointer',
          }}
          onClick={() => setShowArtConteudo(false)}
        />
      );
    }

    switch (conteudoEscolhido.tipovideo) {
      case 2:
        return <Live />;
      case 3:
        return <Hangouts />;
      case 4:
        return <Jitsi />;
      case 5:
        return <Videofront />;
      default:
        return <Video />;
    }
  };

  return (
    <>
      <IdleTimer timeout={100000 * 60 * 15} debounce={250} />
      {!!conteudoEscolhido && fullScreen && (
        <FullScreen
          conteudo={getConteudo()}
          open={!!conteudoEscolhido && fullScreen}
        />
      )}
      {(!conteudoEscolhido || (!!conteudoEscolhido && !fullScreen)) &&
        getConteudo()}
    </>
  );
}
