import produce from 'immer';

const INITIAL_STATE = {
  data: {
    active: 'contratos',
  },
};

export default function navigation(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@navigation/REQUEST_SUCCESS': {
        draft.data = action.payload.navigation;
        break;
      }
      default:
    }
  });
}
