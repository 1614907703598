export function fetchDocuments(numeroContrato) {
  return {
    type: '@documents/FETCH',
    payload: { numeroContrato },
  };
}

export function fetchDocumentsSuccess(deliveredDocuments, missingDocuments) {
  return {
    type: '@documents/FETCH_SUCCESS',
    payload: { deliveredDocuments, missingDocuments },
  };
}

export function fetchDocumentsFailure() {
  return {
    type: '@documents/FETCH_FAILURE',
  };
}
