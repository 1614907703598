export function aulaChoosed(aula) {
  return {
    type: '@vejaemcasa/CHOOSED',
    payload: { aula },
  };
}

export function aulaChoosedSuccess(aula) {
  return {
    type: '@aula/CHOOSED_SUCCESS',
    payload: { aula },
  };
}

export function limparConteudo() {
  return {
    type: '@vejaemcasa/CLEAN',
  };
}
