import { takeLatest, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import fxCall from '~/services/callWrapper';

import { requestSuccess, addNotificacaoContratos } from './actions';

export function* getContratos() {
  try {
    const response = yield fxCall(api.get, `contratos`);
    let notificacoes = 0;
    const cache = new Date();
    if (response.data.length > 0) {
      // eslint-disable-next-line
      response.data.map((contrato) => {
        if (contrato.pendenteaceite) {
          notificacoes += 1;
        }
      });
    }
    const dados = response.data;

    yield put(addNotificacaoContratos(notificacoes));
    yield put(requestSuccess({ dados, cache }));
  } catch (err) {
    toast.error('Erro ao recuperar os contratos, contacte o suporte');
  }
}

export default all([
  takeLatest('@unidade/SIGN_IN_SUCCESS', getContratos),
  takeLatest('@contratos/REQUEST', getContratos),
  takeLatest('@auth/REHYDRATE_SUCCESS', getContratos),
]);
