import React, { useEffect, useState } from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import api from '~/services/api';
import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import FiltrosNotas from '~/components/Notas/FiltrosNotas';
import Filtros from '~/components/Filtros';
import { apiCallHandler } from '~/services/callWrapper';

const useStyles = makeStyles({
  tituloFuncionalidade: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  grids: {
    marginTop: '15px',
  },
  showFiltros: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default function Notas() {
  const classes = useStyles();
  const [contratos, setContratos] = useState(null);
  const [showFiltros, setShowFiltros] = useState(true);

  const [filtros, setFiltros] = useState({
    curso: undefined,
    materia: undefined,
  });

  useEffect(() => {
    async function getNotas() {
      const response = await apiCallHandler(api.get, '/notas');
      setContratos(response.data);
    }

    if (!contratos) {
      getNotas();
    }
  }, [contratos]);

  useEffect(() => {
    async function getNotas() {
      const response = await apiCallHandler(api.get, '/notas', {
        params: filtros,
      });
      setContratos(response.data);
    }

    getNotas();
  }, [filtros]); //eslint-disable-line

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'NOTA') {
        e.cellElement.innerText = Math.round(e.value * 10) / 10;
      }
    }
  }

  function handleChangeFiltros(novosFiltros) {
    setFiltros({ ...filtros, ...novosFiltros });
  }

  const handleChangeShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  return (
    <>
      <Breadcrumb funcionalidade="Notas e frequência" />
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.tituloFuncionalidade}>
            Notas e frequência
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.showFiltros}>
          <Button
            size="small"
            type="button"
            onClick={handleChangeShowFiltros}
            className={classes.btShowFiltros}
          >
            <FilterList /> Filtros Avançados
          </Button>
        </Grid>
      </Grid>
      {showFiltros && (
        <Filtros
          returnFiltros={handleChangeFiltros}
          filtrosAplicados={filtros}
          form={
            <FiltrosNotas
              returnFiltros={handleChangeFiltros}
              filtrosAplicados={filtros}
            />
          }
        />
      )}
      <Grid item xs={12} className={classes.grids}>
        <DataGrid
          dataSource={contratos}
          allowColumnReordering
          showBorders
          hoverStateEnabled
          noDataText="Sem dados disponíveis"
          onCellPrepared={(e) => {
            prepareCell(e);
          }}
        >
          <GroupPanel visible emptyPanelText="" />
          <SearchPanel visible placeholder="Filtrar" />
          <Grouping autoExpandAll={false} />
          <Paging defaultPageSize={10} />
          <Pager showInfo infoText="Página {0} de {1} ({2} aulas)" />

          <Column caption="ID" dataField="contrato_id" visible={false} />
          <Column caption="CURSO" dataField="curso" />
          <Column caption="MATÉRIA" dataField="materia" />
          <Column caption="PROFESSOR" dataField="professor" />
          <Column caption="PRESENÇAS" dataField="presencas" />
          <Column caption="% FREQUÊNCIA" dataField="frequencia" />
          <Column caption="NOTA" dataField="nota" />
        </DataGrid>
      </Grid>
    </>
  );
}
