import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestDadosContratada,
  requestTermosUso,
} from '~/store/modules/aceitecontrato/actions';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    border: 'none',
    boxShadow: 'none',
    marginTop: '15px',
  },
  box: {
    background: 'rgba(234, 236, 245, 0.4)',
    marginTop: '10px',
    borderRadius: '10px',
    maxHeight: '513px',
    overflow: 'auto',
  },
  info: {
    color: '#50A6FF',
    fontSize: '14px',
  },
  tituloClausula: {
    fontSize: '18px',
    color: '#868CAB',
    marginTop: '10px',
  },
  divider: {
    marginTop: '15px',
    height: '2px',
    background: '#bdc0d5',
  },
  titulo: {
    fontSize: '20px',
    color: '#4D5884',
    marginBottom: '15px',
  },
  paragrafo: {
    lineHeight: '25px',
  },
  sessao: {
    fontSize: '18px',
    color: '#868CAB',
    margin: '10px 0',
  },
  gridItem: {
    padding: '10px',
  },
  caption: {
    fontWeight: 'bold',
    color: '#4D5884',
    fontSize: '14px',
  },
});

export default function TermosContrato({ contrato, accepted, setAccepted }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const termos = useSelector((state) => state.aceitecontrato.termosUso);
  const contratada = useSelector(
    (state) => state.aceitecontrato.dadosContratada
  );

  useEffect(() => {
    if (contrato) {
      if (!termos) {
        dispatch(requestTermosUso(contrato.termouso_id));
      }

      if (!contratada) {
        dispatch(requestDadosContratada());
      }
    }
  }, [contrato, contratada, termos]); // eslint-disable-line

  function handleAccept() {
    setAccepted(!accepted);
  }

  return (
    <>
      {contrato.pendenteaceite && (
        <>
          <Typography className={classes.titulo}>
            Leia os termos do contrato
          </Typography>
        </>
      )}
      {contratada && (
        <Grid container>
          <Grid item xs={12}>
            <Divider className={classes.divider} variant="fullWidth" />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" className={classes.sessao}>
              Dados da Contratada
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Razão social
            </Typography>
            <Typography className={classes.info}>{contratada.razao}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Nome fantasia
            </Typography>
            <Typography className={classes.info}>
              {contratada.fantasia}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              CEP
            </Typography>
            <Typography className={classes.info}>{contratada.cep}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Endereço
            </Typography>
            <Typography className={classes.info}>
              {contratada.endereco}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Bairro
            </Typography>
            <Typography className={classes.info}>
              {contratada.bairro}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Cidade
            </Typography>
            <Typography className={classes.info}>
              {contratada.cidade}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Estado
            </Typography>
            <Typography className={classes.info}>
              {contratada.estado}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              CNPJ
            </Typography>
            <Typography className={classes.info}>{contratada.cnpj}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Inscrição estadual
            </Typography>
            <Typography className={classes.info}>
              {contratada.inscricao}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Typography
              component="p"
              variant="caption"
              className={classes.caption}
            >
              Telefone
            </Typography>
            <Typography className={classes.info}>{contratada.fone}</Typography>
          </Grid>
        </Grid>
      )}
      <Box p={3} className={classes.box}>
        {termos &&
          termos.termo_uso.split(/\n/).map((paragrafo) => {
            return (
              <Typography
                component="p"
                variant="body2"
                key={Math.random(9999)}
                className={classes.paragrafo}
                dangerouslySetInnerHTML={{ __html: paragrafo }}
              />
            );
          })}
      </Box>
      {contrato.pendenteaceite && (
        <FormControlLabel
          control={
            <Checkbox
              checked={accepted}
              onChange={handleAccept}
              name="checkedB"
              color="primary"
            />
          }
          label="Eu li e concordo com os termos desse contrato."
        />
      )}
    </>
  );
}

TermosContrato.propTypes = {
  setAccepted: PropTypes.func,
  accepted: PropTypes.bool,
  contrato: PropTypes.shape().isRequired,
};

TermosContrato.defaultProps = {
  setAccepted: () => {},
  accepted: false,
};
