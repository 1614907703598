import React, { useEffect, useState, useRef } from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  Fade,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import { Form } from '@rocketseat/unform';
import { format, formatDistanceToNow, isToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    height: '584px',
    width: '432px',
  },
  titulo: {
    padding: theme.spacing(3),
    maxHeight: '135px',
    minHeight: '128px',
  },
  tituloLabel: {
    color: '#4D5884',
    fontSize: '16px',
  },
  tituloTrabalho: {
    fontSize: '19px',
    color: '#50A6FF',
  },
  form: {
    background: '#50A6FF',
    padding: theme.spacing(2, 3),
    maxHeight: '88px',
    borderRadius: '0px 0px 8px 8px',
  },
  icon: {
    color: '#50A6FF',
  },
  chat: {
    height: '100%',
  },
  messages: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '368px',
    background: '#F7F8FA',
    paddingLeft: theme.spacing(1),
  },
  autor: {
    color: '#8F00FF',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  mensagemContainer: {
    height: 'fit-content',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  mensagemProfessor: {
    background: '#E1E8F2',
    padding: theme.spacing(1),
    borderRadius: '16px 16px 16px 0px',
  },
  mensagemAluno: {
    background: '#97A4BF',
    padding: theme.spacing(1),
    borderRadius: '16px 16px 0 16px',
  },
  textProfessor: {
    fontSize: '13px',
    color: '#465471',
  },
  dateProfessor: {
    fontSize: '11px',
    textAlign: 'end',
    color: '#97A4BF',
  },
  textAluno: {
    fontSize: '13px',
    color: '#FFFFFF',
  },
  dateAluno: {
    fontSize: '11px',
    textAlign: 'end',
    color: 'rgba(255, 255, 255, 0.53)',
  },
}));

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      background: '#fff',
    },
    '& .MuiFocused': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
})(TextField);

export default function ModalComentarios({ atividade, open, handleClose }) {
  const classes = useStyles();
  const [mensagens, setMensagens] = useState(atividade.anotacoes || []);
  const [mensagemDigitada, setMensagemDigitada] = useState(null);
  const ref = useRef(null);

  async function handleSubmit() {
    if (mensagemDigitada) {
      const newMensagens = [...mensagens];
      newMensagens.push({
        id: mensagens.length + 1,
        text: mensagemDigitada,
        tipo: 'aluno',
        data: new Date(),
      });

      setMensagens(newMensagens);
      setMensagemDigitada(null);
      ref.current.value = '';

      await apiCallHandler(
        api.post,
        `/anotacao/${atividade.realizaratividade_id}`,
        {
          id: mensagens.length + 1,
          text: mensagemDigitada,
          data: new Date(),
        }
      );
    }
  }

  useEffect(() => {
    async function updateStatusNotificacoes() {
      await api.put(
        `/notificacoes-atividades/${atividade.realizaratividade_id}`
      );
    }

    async function getAnotacoes() {
      const response = await api.get(
        `/anotacoes/${atividade.realizaratividade_id}`
      );
      setMensagens(response.data);
    }

    updateStatusNotificacoes();
    getAnotacoes();
  }, [atividade.realizaratividade_id]);

  function getDataFormatada(data) {
    data = new Date(data);
    if (isToday(data)) {
      return formatDistanceToNow(data, {
        includeSeconds: true,
        addSuffix: 'true',
        locale: ptBR,
      });
    }

    return format(data, "dd/MM/yyyy à's' HH:mm", { locale: ptBR });
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={classes.chat}
            >
              <Grid item xs={12} className={classes.titulo}>
                <Typography component="p" className={classes.tituloLabel}>
                  Comentários para
                </Typography>
                <Typography component="p" className={classes.tituloTrabalho}>
                  {atividade.materia || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.messages}>
                <ScrollableFeed>
                  {mensagens &&
                    mensagens.map((mensagem) => {
                      const isMensagemAluno = mensagem.tipo === 'aluno';
                      return (
                        <Grid
                          container
                          className={classes.mensagemContainer}
                          justifyContent={`${
                            isMensagemAluno ? 'flex-end' : 'flex-start'
                          }`}
                          key={mensagem.id}
                        >
                          <Grid
                            item
                            xs={7}
                            className={
                              classes[
                                `mensagem${
                                  isMensagemAluno ? 'Aluno' : 'Professor'
                                }`
                              ]
                            }
                          >
                            {!isMensagemAluno && (
                              <Typography
                                component="p"
                                className={classes.autor}
                              >
                                {mensagem.name}
                              </Typography>
                            )}

                            <Typography
                              component="p"
                              className={
                                classes[
                                  `text${
                                    isMensagemAluno ? 'Aluno' : 'Professor'
                                  }`
                                ]
                              }
                            >
                              {mensagem.text}
                            </Typography>
                            <Typography
                              component="p"
                              className={
                                classes[
                                  `date${
                                    isMensagemAluno ? 'Aluno' : 'Professor'
                                  }`
                                ]
                              }
                            >
                              {getDataFormatada(mensagem.data)}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                </ScrollableFeed>
              </Grid>
              <Grid item xs={12} className={classes.form}>
                <Form onSubmit={handleSubmit}>
                  <StyledTextField
                    name="mensagem"
                    placeholder="Sua mensagem..."
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <SendOutlined className={classes.icon} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={classes.field}
                    fullWidth
                    color="secondary"
                    autoComplete="off"
                    onChange={(e) => setMensagemDigitada(e.target.value)}
                    inputRef={ref}
                  />
                </Form>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalComentarios.propTypes = {
  atividade: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
