import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Modal, Button } from '@material-ui/core';
import { DateRangeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ptBr from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DatePickerModal({ setDataSelecionada }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function changeDate(newDate) {
    setDate(newDate);
    setDataSelecionada(newDate);
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        type="button"
        onClick={handleOpen}
        size="large"
      >
        <DateRangeOutlined />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
            <DatePicker
              autoOk
              orientation="landscape"
              variant="static"
              openTo="date"
              value={date}
              onChange={changeDate}
              shouldDisableDate={(datePick) => datePick.getDay() === 0}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Modal>
    </div>
  );
}

DatePickerModal.propTypes = {
  setDataSelecionada: PropTypes.func.isRequired,
};
