import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';
import ReactPlayer from 'react-player';

import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';

export default function Video() {
  const conteudoEscolhido = useSelector((state) => state.videoaula.conteudo);

  const showVideo = () => {
    if (conteudoEscolhido.desativado || !conteudoEscolhido.url) {
      return (
        <>
          <Image
            src={imgConteudoDesativado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              backgroundColor: 'none',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography
            color="primary"
            variant="h5"
            component="div"
            align="center"
          >
            Fim da Transmissão
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            component="div"
            align="center"
          >
            A transmissão da videoaula foi encerrada ;)
          </Typography>
        </>
      );
    }

    return (
      <ReactPlayer
        url={conteudoEscolhido.url}
        controls
        config={{
          file: {
            attributes: {
              onContextMenu: (e) => e.preventDefault(),
              disablePictureInPicture: true,
              controlsList: 'nodownload noremoteplayback',
            },
          },
        }}
        youtubeConfig={{
          playerVars: {
            disablekb: 1,
            hl: 'pt',
            modestbranding: 1,
            rel: 0,
            showInfo: 0,
            origin: window.location.origin,
          },
          embedOptions: {
            host: 'http://www.youtube-nocookie.com',
          },
        }}
      />
    );
  };

  return <>{showVideo()}</>;
}
