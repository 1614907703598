import React, { useState } from 'react';
import {
  Typography,
  Button,
  Menu,
  Grid,
  Link,
  IconButton,
} from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';

import { useStyles } from './styles';

export default function Profile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.aluno.profile);
  const instituicao = useSelector((state) => state.instituicao.data);

  const [openProfile, setOpenProfile] = useState(null);

  const handleClickProfile = (event) => {
    setOpenProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleSignOut = (data) => {
    dispatch(signOut(data));
  };

  return (
    <div className={classes.profile}>
      <IconButton onClick={handleClickProfile}>
        <PersonOutline className={classes.avatarButton} />
      </IconButton>
      <Menu
        anchorEl={openProfile}
        keepMounted
        open={!!openProfile}
        onClose={handleCloseProfile}
      >
        <Grid container justifyContent="space-between" className={classes.menu}>
          <Grid item xs={12} className={classes.gridAvatar}>
            <PersonOutline className={classes.avatar} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.nome}>
              {profile && profile.nome}
            </Typography>
            <Typography className={classes.login}>
              {profile && profile.login}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link
              href={instituicao.politicas_privacidade}
              className={classes.politica}
              underline="hover"
              target="_blank"
            >
              <Typography component="p">Política de Privacidade</Typography>
            </Link>
            <Link
              href="https://www.f10.com.br/termos-de-uso"
              className={classes.politica}
              underline="hover"
              target="_blank"
            >
              <Typography component="p">Termos de Serviço</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} className={classes.gridExit}>
            <Button
              variant="outlined"
              className={classes.exit}
              onClick={handleSignOut}
            >
              Sair
            </Button>
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
}
