import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
} from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import CloseIcon from '@material-ui/icons/Close';
import { Settings, AspectRatio, MenuOpen } from '@material-ui/icons';
import { darken } from 'polished';

import SelectMateria from '~/components/Curso/SelectMateria';
// import Progresso from '~/components/Curso/Progresso';
import Menu from '~/components/Curso/Menu';
import Conteudo from '~/components/Curso/Conteudo';

import { limparConteudo, changeMode } from '~/store/modules/aula/actions';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
  dialog: {
    position: 'relative',
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    marginRight: theme.spacing(2),
  },
  acessar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  gridNomeCurso: {
    display: 'flex',
    alignItems: 'center',
  },
  nomeCurso: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  nomeAula: {
    color: '#777',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  descricaoAula: {
    color: '#999',
  },
  tituloAula: {
    color: '#3E98C7',
  },
  conteudo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  progresso: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menu: {
    maxHeight: '400px',
    overflow: 'auto',
  },
  closeContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenContent({
  contrato,
  materiaEscolhida,
  materias,
  open,
  setOpen,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const conteudoEscolhido = useSelector((state) => state.aula.conteudo);
  const mode = useSelector((state) => state.aula.mode);

  const [materiaSelecionada, setMateriaSelecionada] =
    useState(materiaEscolhida);
  const [dialOpen, setDialOpen] = useState(false);

  const actions = [
    {
      icon: <AspectRatio />,
      name: 'Modo Teatro',
      handleClick: () => dispatch(changeMode('theater')),
    },
    {
      icon: <MenuOpen />,
      name: 'Abrir Menu',
      handleClick: () => dispatch(changeMode('default')),
    },
  ];

  const handleClose = () => {
    dispatch(limparConteudo());
    setOpen(false);
  };

  const handleOpenDial = () => {
    setDialOpen(true);
  };

  const handleCloseDial = () => {
    setDialOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open && conteudoEscolhido}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.dialog}
      >
        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          className={classes.speedDial}
          // hidden={hidden}
          icon={<SpeedDialIcon openIcon={<Settings />} />}
          onClose={handleCloseDial}
          onOpen={handleOpenDial}
          open={dialOpen}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                action.handleClick();
                handleCloseDial();
              }}
            />
          ))}
        </SpeedDial>
        {mode === 'default' && (
          <AppBar className={classes.appBar} color="inherit">
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" className={classes.title}>
                Fechar
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
        <Grid container spacing={1} className={classes.main}>
          <Grid item xs={11} className={classes.gridNomeCurso}>
            <Typography variant="h6" className={classes.nomeCurso}>
              {contrato && contrato.curso}
            </Typography>
          </Grid>
          {mode === 'theater' && (
            <Grid item xs={1} className={classes.closeContent}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          {mode === 'default' && (
            <>
              <Grid item xs={12} md={3}>
                <SelectMateria
                  materias={materias}
                  materiaSelecionada={materiaSelecionada}
                  changeMateriaSelecionada={setMateriaSelecionada}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {conteudoEscolhido && (
                  <>
                    {!conteudoEscolhido.introducao && (
                      <Typography variant="h5" className={classes.nomeAula}>
                        {conteudoEscolhido.aula}
                      </Typography>
                    )}
                    <Typography variant="h6" className={classes.tituloAula}>
                      {conteudoEscolhido.titulo}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.descricaoAula}
                    >
                      {conteudoEscolhido.descricao}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={3} className={classes.progresso}>
                {/* <Progresso materia={materiaSelecionada} /> */}
              </Grid>
              <Grid item xs={12} md={3} className={classes.menu}>
                <Menu
                  materia={materiaSelecionada}
                  curso={contrato.curso_id}
                  contrato={contrato}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            md={mode === 'default' ? 9 : 12}
            className={classes.conteudo}
          >
            <Conteudo />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

FullScreenContent.propTypes = {
  contrato: PropTypes.shape().isRequired,
  materiaEscolhida: PropTypes.shape().isRequired,
  materias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
