import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';

import api from '~/services/api';
import history from '~/services/history';
import { useLoading } from '~/hooks/loading';

const useStyles = makeStyles((theme) => ({
  tituloFuncionalidade: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
  },
  questionario: {
    padding: theme.spacing(4),
  },
  enunciado: {
    color: '#4D5884',
  },
  tituloCabecalho: {
    color: '#868CAB',
    letterSpacing: '0.5px',
    fontSize: '18px',
    lineHeight: '28px',
    padding: '24px',
  },
  gridCabecalho: {
    background: 'rgba(234, 236, 245, 0.4)',
    borderRadius: '12px',
    padding: '24px',
  },
  cabecalhoLabel: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
  cabecalhoValue: {
    color: '#50A6FF',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  btChangeView: {
    borderRadius: '200px',
  },
  gridChangeView: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelResposta: {
    color: '#50A6FF',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  step: {
    '& svg': {
      fontSize: '25px',
    },
    cursor: 'pointer',
  },
  actionsContainer: {
    margin: theme.spacing(2, 0),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  cabecalho: {
    padding: '24px',
  },
  confirmButton: {
    borderRadius: '200px',
    minWidth: '120px',
    margin: theme.spacing(0, 1),
  },
  paper: {
    position: 'relative',
    width: '90%',
    maxWidth: '800px',
    minWidth: '400px',
    height: '480px',
    margin: '0 auto',
    background: '#fafafa',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0,0,0,.3)',
    overflow: 'hidden',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '0; bottom: 0; left: 0',
      width: '60px',
      background: 'radial-gradient(#575450 6px, transparent 7px) repeat-y',
      backgroundSize: '30px 30px',
      borderRight: '3px solid #D44147',
      boxSizing: 'border-box',
    },
  },
  paperContent: {
    position: 'absolute',
    top: '30px',
    right: 0,
    bottom: '30px',
    left: '60px',
    overflow: 'auto',
    background: 'linear-gradient(transparent, transparent 28px, #91D1D3 28px)',
    backgroundSize: '30px 30px',
    '& div': {
      overflow: 'hidden',
    },
    '& div > div': {
      overflow: 'hidden',
    },
    '& div > div > textarea': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      lineHeight: '30px',
      padding: '0 10px',
      border: 0,
      outline: 0,
      background: 'transparent',
      color: 'mediumblue',
      fontWeight: 'bold',
      fontSize: '18px',
      boxSizing: 'border-box',
      fontFamily: 'Handlee, cursive',
      'z-index': 1,
    },
  },
}));

export default function Questionario({ match }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const atividade = useSelector((state) => state.questionario.atividade);
  const { showLoading, hideLoading } = useLoading();
  const [questionario, setQuestionario] = useState(null);
  const [data, setData] = useState(null);
  const [realizarAtividade, setRealizarAtividade] = useState(null);
  const [stepView, setStepView] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [respostas, setRespostas] = useState([]);

  const podeEnviarResposta = () => {
    if (
      !atividade ||
      ['Entregue', 'Avaliado', 'Fechado', 'Só Leitura'].includes(
        atividade.status
      )
    ) {
      return false;
    }

    return true;
  };

  // const SELECAO_UNICA = 1;
  const TEXTO_CURTO = 2;
  const TEXTO_LONGO = 3;
  // const VERDADEIRO_FALSO = 4;
  const MULTIPLA_ESCOLHA = 5;

  const STATUS_PENDENTE = 1;
  const STATUS_SUBMETIDO = 2;

  useEffect(() => {
    const getQuestionarioAtividade = async () => {
      showLoading();
      const atividadeLancada = Number(match.params.atividadeLancada);
      const response = await api
        .get(`/atividade-lancada/${atividadeLancada}/questionario`)
        .catch(() => {
          hideLoading();
        });

      setQuestionario(response.data.questionario);
      setRespostas(response.data.respostas || []);
      setRealizarAtividade(response.data.realizaratividade);

      const { curso, materia, prazo } = response.data;

      setData({ curso, materia, prazo });
      hideLoading();
    };

    if (!questionario) {
      getQuestionarioAtividade();
    }
  }, []); //eslint-disable-line

  const saveAnswers = async (status) => {
    try {
      const atividadeLancada = Number(match.params.atividadeLancada);
      showLoading();
      const response = await api.post(
        `/atividade-lancada/${Number(atividadeLancada)}/questionario/respostas`,
        { status, respostas, realizarAtividade }
      );
      setRealizarAtividade(response.data.realizaratividade_id);
      hideLoading();
      if (STATUS_SUBMETIDO === status) {
        history.push('/atividades');
      }
    } catch (error) {
      hideLoading();
      toast.error(
        'Ocorreu um erro ao tentar salvar as respostas, tente novamente mais tarde.'
      );
    }
  };

  const tryToSendAnswers = async (status) => {
    if (STATUS_SUBMETIDO === status) {
      if (respostas.length !== questionario.questoes.length) {
        toast.error(
          'Para enviar para a análise, todas as perguntas devem estar respondidas.'
        );
        return;
      }
      confirm({
        description: '',
        title:
          'Tem certeza que deseja enviar as respostas para avaliação do professor?',
        confirmationText: 'Confirmar',
        cancellationText: 'Cancelar',
        confirmationButtonProps: {
          variant: 'contained',
          className: classes.confirmButton,
        },
        cancellationButtonProps: {
          variant: 'contained',
          color: 'secondary',
          className: classes.confirmButton,
        },
      })
        .then(async () => {
          await saveAnswers(status);
        })
        .catch(() => {});
    } else {
      await saveAnswers(status);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const salvarRespostaTipoTexto = (idQuestao, resposta) => {
    const newRespostas = [...respostas];

    if (newRespostas.length) {
      const respostaKey = respostas.findIndex(
        (respostaFind) => respostaFind.questao_id === idQuestao
      );

      if (respostaKey < 0) {
        newRespostas.push({ questao_id: idQuestao, resposta });
      } else {
        newRespostas.splice(respostaKey, 1);
        newRespostas.push({ questao_id: idQuestao, resposta });
      }
    } else {
      newRespostas.push({ questao_id: idQuestao, resposta });
    }

    setRespostas(newRespostas);
  };

  const salvarTipoMultiplaEscolha = (idQuestao, idOpcao) => {
    const newRespostas = [...respostas];

    if (!newRespostas.length) {
      newRespostas.push({ questao_id: idQuestao, resposta: [idOpcao] });
    } else {
      const respostaKey = respostas.findIndex(
        (respostaFind) => respostaFind.questao_id === idQuestao
      );

      if (typeof newRespostas[respostaKey] === 'undefined') {
        newRespostas.push({ questao_id: idQuestao, resposta: [idOpcao] });
      } else {
        const opcaoKey = newRespostas[respostaKey].resposta.findIndex(
          (respostaId) => respostaId === idOpcao
        );

        if (opcaoKey < 0) {
          newRespostas[respostaKey].resposta.push(idOpcao);
        } else {
          newRespostas[respostaKey].resposta.splice(opcaoKey, 1);
        }
      }
    }

    setRespostas(newRespostas);
  };

  const salvarSelecaoUnica = (idQuestao, idOpcao) => {
    const newRespostas = [...respostas];
    if (newRespostas.length) {
      const respostaKey = respostas.findIndex(
        (respostaFind) => respostaFind.questao_id === idQuestao
      );

      if (respostaKey < 0) {
        newRespostas.push({ questao_id: idQuestao, resposta: idOpcao });
      } else {
        newRespostas.splice(respostaKey, 1);
        newRespostas.push({ questao_id: idQuestao, resposta: idOpcao });
      }
    } else {
      newRespostas.push({ questao_id: idQuestao, resposta: idOpcao });
    }

    setRespostas(newRespostas);
  };

  const showQuestion = (questao) => {
    const respostaQuestao = respostas.find(
      (respostaFind) => respostaFind.questao_id === questao.id
    );

    switch (questao.tipo) {
      case TEXTO_CURTO:
        return (
          <TextField
            placeholder="Digite aqui sua resposta..."
            fullWidth
            variant="outlined"
            color="secondary"
            defaultValue={(respostaQuestao && respostaQuestao.resposta) || ''}
            onBlur={(event) =>
              salvarRespostaTipoTexto(questao.id, event.target.value)
            }
          />
        );
      case TEXTO_LONGO:
        return (
          <div className={classes.paper}>
            <div className={classes.paperContent}>
              <TextField
                placeholder="Digite aqui sua resposta..."
                fullWidth
                multiline={questao.tipo === TEXTO_LONGO}
                rows={40}
                // variant="outlined"
                color="secondary"
                defaultValue={
                  (respostaQuestao && respostaQuestao.resposta) || ''
                }
                onBlur={(event) =>
                  salvarRespostaTipoTexto(questao.id, event.target.value)
                }
              />
            </div>
          </div>
        );
      case MULTIPLA_ESCOLHA:
        return (
          <FormControl component="fieldset">
            <FormGroup>
              {questao.opcoes.map((opcao) => (
                <FormControlLabel
                  key={opcao.id + Math.random(9999)}
                  control={
                    <Checkbox
                      onChange={() =>
                        salvarTipoMultiplaEscolha(questao.id, opcao.id)
                      }
                      name={opcao.id}
                      defaultChecked={
                        respostaQuestao &&
                        respostaQuestao.resposta.includes(opcao.id)
                      }
                    />
                  }
                  label={opcao.descricao}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      default:
        return (
          <FormControl component="fieldset">
            <RadioGroup
              name={`test${questao.id}`}
              value={(respostaQuestao && respostaQuestao.resposta) || undefined}
            >
              {questao.opcoes.map((opcao) => (
                <FormControlLabel
                  key={opcao.id + Math.random(9999)}
                  value={opcao.id}
                  control={<Radio />}
                  label={opcao.descricao}
                  onChange={() => {
                    salvarSelecaoUnica(questao.id, opcao.id);
                  }}
                  checked={
                    respostaQuestao && respostaQuestao.resposta === opcao.id
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
    }
  };

  const isQuestaoRespondida = (questao) => {
    return respostas.some(
      (respostaQuestao) =>
        respostaQuestao.questao_id === questao.id &&
        ((Boolean(respostaQuestao.resposta) &&
          !Array.isArray(respostaQuestao.resposta)) ||
          (Array.isArray(respostaQuestao.resposta) &&
            Boolean(respostaQuestao.resposta.length)))
    );
  };

  return (
    <>
      <Breadcrumb
        categorias={['Atividades de Aula']}
        funcionalidade={(questionario && questionario.titulo) || ''}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.tituloFuncionalidade}>
            {(questionario && questionario.titulo) || ''}
          </Typography>
        </Grid>
        <Grid item xs={12} component={Paper} className={classes.questionario}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.tituloCabecalho}>
              Dados da Atividade
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.gridCabecalho} spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className={classes.cabecalhoLabel}>
                    Curso
                  </Typography>
                  <Typography className={classes.cabecalhoValue}>
                    {data && data.curso}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className={classes.cabecalhoLabel}>
                    Matéria
                  </Typography>
                  <Typography className={classes.cabecalhoValue}>
                    {data && data.materia}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className={classes.cabecalhoLabel}>
                    Tema
                  </Typography>
                  <Typography className={classes.cabecalhoValue}>
                    {questionario && questionario.titulo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className={classes.cabecalhoLabel}>
                    Prazo
                  </Typography>
                  <Typography className={classes.cabecalhoValue}>
                    {data && data.prazo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className={classes.cabecalhoLabel}>
                    Peso
                  </Typography>
                  <Typography className={classes.cabecalhoValue}>
                    {questionario && questionario.peso_total.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.gridChangeView}>
              <Typography className={classes.labelResposta}>
                Vamos lá, responda as perguntas abaixo.
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setStepView(!stepView)}
                className={classes.btChangeView}
              >
                {stepView
                  ? 'VISUALIZAR TODAS AS PERGUNTAS'
                  : 'VISUALIZAR UMA PERGUNTA POR VEZ'}
              </Button>
            </Grid>
            {!stepView && (
              <>
                {questionario &&
                  questionario.questoes.map((questao, key) => {
                    return (
                      <React.Fragment key={questao.id}>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            variant="body1"
                            className={classes.enunciado}
                          >
                            {questao.id} - {questao.enunciado}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {showQuestion(questao)}
                        </Grid>
                        {questionario.questoes.length - 1 !== key && (
                          <Grid item xs={12}>
                            <Divider variant="middle" />
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="flex-end">
                    {/* <Grid item xs={6} sm={3} lg={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => tryToSendAnswers(STATUS_PENDENTE)}
                      >
                        Salvar Rascunho
                      </Button>
                    </Grid> */}
                    {podeEnviarResposta() && (
                      <Grid item xs={6} sm={3} lg={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => tryToSendAnswers(STATUS_SUBMETIDO)}
                        >
                          Salvar e Enviar para Análise
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {stepView && (
              <Grid item xs={12}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {questionario &&
                    questionario.questoes.map((questao, index) => (
                      <Step
                        key={questao.id}
                        completed={isQuestaoRespondida(questao)}
                      >
                        <StepLabel
                          className={classes.step}
                          onClick={() => setActiveStep(index)}
                        >
                          {questao.enunciado}
                        </StepLabel>
                        <StepContent>
                          <div>{showQuestion(questao)}</div>
                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Voltar
                              </Button>
                              {questionario.questoes.length > index + 1 &&
                                !isQuestaoRespondida(questao) && (
                                  <Button
                                    onClick={handleNext}
                                    className={classes.button}
                                  >
                                    Pular
                                  </Button>
                                )}
                              {podeEnviarResposta() && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    activeStep ===
                                      questionario.questoes.length - 1 &&
                                    !respostas.length
                                  }
                                  onClick={() => {
                                    if (
                                      activeStep ===
                                      questionario.questoes.length - 1
                                    ) {
                                      tryToSendAnswers(STATUS_SUBMETIDO);
                                    } else {
                                      tryToSendAnswers(STATUS_PENDENTE);
                                      handleNext();
                                    }
                                  }}
                                  className={classes.button}
                                >
                                  {activeStep ===
                                  questionario.questoes.length - 1
                                    ? 'Enviar para análise'
                                    : 'Salvar e ir para a próxima'}
                                </Button>
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                </Stepper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Questionario.propTypes = {
  match: PropTypes.shape().isRequired,
};
