import React from 'react';
import {
  Button,
  Hidden,
  Typography,
  Modal,
  Fade,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Report } from '@material-ui/icons';
import { darken } from 'polished';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDocuments } from '~/store/modules/documents/actions';
import history from '~/services/history';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '400px',
  },
  btDocuments: {
    background: '#ffa726',
    color: '#fff',
    fontWeight: 'normal',
    borderRadius: '200px',
    fontSize: '14px',
    transition: 'background 0.2s',
    textTransform: 'unset',
    marginRight: '10px',
    '&:hover': {
      background: `${darken(0.1, '#ffa726')}`,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  dropZone: {
    border: `4px dashed #BDC0D4`,
    borderRadius: '10px',
    height: '200px',
    width: '400px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#F7F8FA',
    padding: '10px',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  btEnviar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  divFile: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    padding: '11px',
    borderRadius: '8px',
  },
  fileName: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(80, 166, 255, 1)',
  },
}));

function DocumentsPendingModal() {
  const dispatch = useDispatch();
  const contratos = useSelector((state) => state.contratos.data);
  const { loaded, loading, missingDocuments } = useSelector(
    (state) => state.documents
  );
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const redirectToContract = (contractId) => {
    if (contractId) {
      handleClose();
      history.push(`/detalhes-contrato/${contractId}/?action=1`);
    }
  };

  const handleOpen = () => {
    const contratosAceitos = contratos.dados.filter(
      (contrato) => !contrato.pendenteaceite
    );
    if (contratosAceitos.length === 1) {
      redirectToContract(contratosAceitos[0].contrato_id);
      return;
    }

    setOpen(true);
  };

  React.useEffect(() => {
    if (!loaded && !loading && contratos && contratos.dados.length > 0) {
      dispatch(fetchDocuments(contratos.dados[0].numerocontrato));
    }
  }, [loaded, loading, contratos]); // eslint-disable-line

  if (missingDocuments.length === 0) {
    return null;
  }

  return (
    <div>
      <Button
        type="button"
        onClick={handleOpen}
        variant="contained"
        className={classes.btDocuments}
        size="small"
        startIcon={<Report />}
      >
        <Hidden only="xs">
          <Typography component="span" variant="caption">
            Você tem documentos pendentes
          </Typography>
        </Hidden>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <Typography variant="h6" color="secondary">
                Escolha o contrato
              </Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
                size="small"
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
            <Divider variant="fullWidth" />
            {contratos && contratos.dados.length > 1 && (
              <FormControl
                color="secondary"
                variant="filled"
                fullWidth
                size="small"
                margin="normal"
              >
                <InputLabel id="select-contrato-filled-label">
                  Contrato
                </InputLabel>
                <Select
                  color="secondary"
                  labelId="select-contrato-filled-label"
                  name="contrato"
                  onChange={(e) => redirectToContract(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    <em>Escolha o contrato</em>
                  </MenuItem>
                  {contratos.dados
                    .filter((contrato) => !contrato.pendenteaceite)
                    .map((contrato) => (
                      <MenuItem
                        value={contrato.contrato_id}
                        key={contrato.contrato_id}
                      >
                        {contrato.curso}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default DocumentsPendingModal;
