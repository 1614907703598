import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MuiModal, Fade, Typography, Button } from '@material-ui/core';
import Image from 'material-ui-image';
import { darken } from 'polished';

import history from '~/services/history';

import PaymentSuccessImg from '~/assets/ic_payment_success.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(4, 3),
    maxHeight: '520px',
    maxWidth: '370px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#47D19D',
    fontSize: '31px',
    lineHeight: '36px',
    textAlign: 'center',
    margin: '10px 0',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '19px',
    textAlign: 'center',
  },
  button: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    marginTop: '15px',
    transition: 'background 0.4s',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
}));

export default function PaymentSuccess({ open, setOpen }) {
  const classes = useStyles();

  return (
    <div>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Image
              src={PaymentSuccessImg}
              style={{
                display: 'flex',
                paddingTop: 'unset',
                marginRight: '20px',
              }}
              imageStyle={{
                width: 'unset',
                height: 'unset',
                position: 'unset',
                maxHeight: '255px',
              }}
            />
            <Typography className={classes.title}>Sucesso \o/</Typography>
            <Typography color="secondary" className={classes.subtitle}>
              Seu pagamento foi realizado com sucesso.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              className={classes.button}
              size="small"
              onClick={() => {
                history.push('/financeiro');
              }}
            >
              OK, FECHAR
            </Button>
          </div>
        </Fade>
      </MuiModal>
    </div>
  );
}

PaymentSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
