import { takeLatest, all, put } from 'redux-saga/effects';

import api from '~/services/api';
import fxCall from '~/services/callWrapper';

import { aulaChoosedSuccess } from './actions';

export function* changeStatusVisualizado({ payload }) {
  try {
    const { aula } = payload;

    yield fxCall(api.put, `veja-em-cada/status/${aula.smartconteudosmart_id}`);

    aula.visualizado = true;

    yield put(aulaChoosedSuccess(aula));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') console.tron.log(err);
  }
}

export default all([
  takeLatest('@vejaemcasa/CHOOSED', changeStatusVisualizado),
]);
