import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Fade, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from 'polished';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import BoletoJS from 'boleto.js';
import Copy from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';

import { apiCallHandler } from '~/services/callWrapper';
import api from '~/services/api';

import getLogoBank from '~/assets/banks';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 10px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    width: '850px',
    // height: '628px',
    overflow: 'auto',
  },
  content: {
    width: '785px',
  },
  titulo: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#50A6FF',
    mixBlendMode: 'normal',
    marginBottom: '10px',
  },
  button: {
    marginLeft: '15px',
    background: '#4D5884',
    borderRadius: '200px',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.75px',
    marginBottom: '8px',
    marginTop: '23px',
    color: '#fff',
    '&:hover': {
      background: `${darken(0.1, '#4D5884')}`,
    },
    padding: theme.spacing(1, 5),
  },
  btPrimary: {
    minWidth: '183px',
    background: '#50A6FF',
    color: '#fff',
    borderRadius: '200px',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
    margin: '0 5px',
  },
  btCancelar: {
    borderRadius: '200px',
    margin: '0 5px',
  },
  tableDetail: {
    padding: theme.spacing(1),
    borderBottom: '2px solid rgba(189, 192, 213, 0.2)',
    borderRight: '2px solid rgba(189, 192, 213, 0.2)',
  },
  textsHeader: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
  },
  codBanco: {
    width: '77px',
  },
  logoBanco: {
    width: '135px',
  },
  linhaDigitavel: {
    textAlign: 'center',
    borderRight: 'none',
  },
  beneficiario: {
    width: '170px',
  },
  cnpjBeneficiario: {
    width: '188px',
  },
  campo: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#4D5884',
  },
  valor: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#50A6FF',
  },
  pagador: {
    border: 'none',
  },
  cpfPagador: {
    border: 'none',
    borderRight: '2px solid rgba(189, 192, 213, 0.2)',
  },
  acrescimosDescontos: {
    borderBottom: '2px solid rgba(189, 192, 213, 0.2)',
  },
  camposDireita: {
    borderRight: 'none',
  },
  divCodBar: {
    marginTop: '15px',
  },
  buttons: {
    marginTop: '15px',
    width: '785px',
    display: 'flex',
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

function ModalBoleto({
  linhaDigitavel,
  beneficiario,
  cnpjBeneficiario,
  agenciaBeneficiario,
  codCedente,
  tipoRecebimento,
  valor,
  dataVencimento,
  parcela,
  total_parcelas,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  // const [boleto] = useState(new BoletoJS(linhaDigitavel));
  const [dadosPagamento, setDadosPagamento] = useState(null);

  const { id_receber } = useParams();

  const paperRef = useRef(null);

  const getDadosPagamento = async () => {
    const response = await apiCallHandler(api.get, `parcela/${id_receber}`);
    setDadosPagamento(response.data);
  };

  useEffect(() => {
    if (!dadosPagamento) {
      getDadosPagamento();
    }
  }, [dadosPagamento]); // eslint-disable-line

  // const createImageUrl = (svg) => {
  //   const blob = new Blob([svg], { type: 'image/svg+xml' });
  //   const url = URL.createObjectURL(blob);

  //   return url;
  // };

  const formatInstructions = (instructions) => {
    return (
      <ul>
        {instructions.split('\r').map((instruction) => (
          <li>{instruction}</li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => {
          setOpen(true);
        }}
        fullWidth
      >
        PAGAR VIA BOLETO
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <div className={classes.paper} ref={paperRef}>
            <Typography className={clsx(classes.titulo, classes.noPrint)}>
              Dados do boleto
            </Typography>
            <div id="divcontents" className={classes.content}>
              <table>
                <tbody>
                  <tr>
                    <td
                      className={clsx(classes.tableDetail, classes.logoBanco)}
                    >
                      <img
                        width="95px"
                        height="30px"
                        src={
                          dadosPagamento &&
                          getLogoBank(`${dadosPagamento.codigo}`)
                        }
                        alt="logo"
                      />
                    </td>
                    <td
                      className={clsx(classes.tableDetail, classes.codBanco)}
                      colSpan="1"
                    >
                      <Typography className={classes.campo} align="left">
                        Banco
                      </Typography>
                      <Typography className={classes.textsHeader}>
                        {dadosPagamento && dadosPagamento.codigo}
                      </Typography>
                    </td>
                    <td
                      colSpan="3"
                      className={clsx(
                        classes.tableDetail,
                        classes.linhaDigitavel
                      )}
                    >
                      <Typography className={classes.campo} align="left">
                        Linha digitável
                      </Typography>
                      <Typography className={classes.textsHeader}>
                        {linhaDigitavel}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableDetail}>
                      <Typography className={classes.campo}>Tipo</Typography>
                      <Typography className={classes.valor}>
                        {tipoRecebimento}
                        {parcela && ` (${parcela}/${total_parcelas})`}
                      </Typography>
                    </td>
                    <td
                      className={clsx(
                        classes.tableDetail,
                        classes.beneficiario
                      )}
                    >
                      <Typography className={classes.campo}>
                        Beneficiário
                      </Typography>
                      <Typography className={classes.valor}>
                        {beneficiario}
                      </Typography>
                    </td>
                    <td
                      className={clsx(
                        classes.tableDetail,
                        classes.cnpjBeneficiario
                      )}
                    >
                      <Typography className={classes.campo}>
                        CNPJ do beneficiário
                      </Typography>
                      <Typography className={classes.valor}>
                        {cnpjBeneficiario}
                      </Typography>
                    </td>
                    <td
                      className={clsx(
                        classes.tableDetail,
                        classes.camposDireita
                      )}
                    >
                      <Typography className={classes.campo}>
                        Agência/Cód. Beneficiário
                      </Typography>
                      <Typography className={classes.valor}>
                        {agenciaBeneficiario}/{codCedente}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      className={clsx(classes.tableDetail, classes.pagador)}
                    >
                      <Typography className={classes.campo}>Pagador</Typography>
                      <Typography className={classes.valor}>
                        {dadosPagamento && dadosPagamento.pagador}
                      </Typography>
                    </td>
                    <td
                      className={clsx(classes.tableDetail, classes.cpfPagador)}
                      colSpan="1"
                    >
                      <Typography className={classes.campo}>CPF</Typography>
                      <Typography className={classes.valor}>
                        {dadosPagamento && dadosPagamento.cnpj_cpf}
                      </Typography>
                    </td>
                    <td
                      className={clsx(
                        classes.tableDetail,
                        classes.camposDireita
                      )}
                    >
                      <Typography className={classes.campo}>
                        Valor total a pagar
                      </Typography>
                      <Typography className={classes.valor}>{valor}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={clsx(
                        classes.tableDetail,
                        classes.pagador,
                        dadosPagamento &&
                          !dadosPagamento.instrucao &&
                          classes.acrescimosDescontos
                      )}
                      colSpan="2"
                    >
                      <Typography className={classes.campo}>
                        Endereço
                      </Typography>
                      <Typography className={classes.valor}>
                        {dadosPagamento && dadosPagamento.endereco}
                      </Typography>
                    </td>
                    <td
                      className={clsx(
                        classes.cpfPagador,
                        dadosPagamento &&
                          !dadosPagamento.instrucao &&
                          classes.acrescimosDescontos
                      )}
                    />
                    <td
                      className={clsx(
                        classes.tableDetail,
                        classes.camposDireita
                      )}
                      colSpan="2"
                    >
                      <Typography className={classes.campo}>
                        Dia do vencimento
                      </Typography>
                      <Typography className={classes.valor}>
                        {dataVencimento}
                      </Typography>
                    </td>
                  </tr>
                  {dadosPagamento && dadosPagamento.instrucao && (
                    <tr>
                      <td
                        className={clsx(
                          classes.tableDetail,
                          classes.acrescimosDescontos
                        )}
                        colSpan="3"
                      >
                        <Typography className={classes.campo}>
                          Instruções de Responsabilidade do BENEFICIÁRIO.
                          Qualquer dúvida sobre este boleto, contate o
                          BENEFICIÁRIO.
                        </Typography>
                        <Typography className={classes.valor}>
                          {formatInstructions(dadosPagamento.instrucao)}
                        </Typography>
                      </td>
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
              <div className={classes.divCodBar}>
                {/* <img alt="codebar" src={createImageUrl(boleto.toSVG())} /> */}
              </div>
              <Typography
                className={classes.textsHeader}
                component="p"
                align="center"
              >
                {linhaDigitavel}
              </Typography>
            </div>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                size="small"
                fullWidth
                color="secondary"
                className={clsx(classes.noPrint, classes.btCancelar)}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Copy
                text={linhaDigitavel}
                onCopy={() => {
                  toast.success('Linha digitável copiada com sucesso');
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  color="primary"
                  className={clsx(classes.noPrint, classes.btPrimary)}
                >
                  Copiar código de barras
                </Button>
              </Copy>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    color="primary"
                    className={clsx(classes.noPrint, classes.btPrimary)}
                  >
                    Imprimir boleto
                  </Button>
                )}
                content={() => paperRef.current}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalBoleto.propTypes = {
  linhaDigitavel: PropTypes.string.isRequired,
  beneficiario: PropTypes.string.isRequired,
  cnpjBeneficiario: PropTypes.string.isRequired,
  agenciaBeneficiario: PropTypes.string.isRequired,
  codCedente: PropTypes.string.isRequired,
  tipoRecebimento: PropTypes.string.isRequired,
  valor: PropTypes.string.isRequired,
  dataVencimento: PropTypes.string.isRequired,
  parcela: PropTypes.number.isRequired,
  total_parcelas: PropTypes.number.isRequired,
  // contratoId: PropTypes.number.isRequired,
};

export default ModalBoleto;
