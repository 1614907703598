import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import FaturasAbertas from '~/components/Financeiro/FaturasAbertas';
import FaturasFechadas from '~/components/Financeiro/FaturasFechadas';
import { loadInstallments } from '~/store/modules/financeiro/actions';

export default function Financeiro() {
  const useStyles = makeStyles((theme) => ({
    title: {
      textTransform: 'uppercase',
      color: '#3E98C7',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    grid: {
      marginTop: '15px',
      padding: theme.spacing(0, 3, 3),
    },
    selectContrato: {
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      padding: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const contratos = useSelector((state) => state.contratos.data);
  const installments = useSelector((state) => state.financeiro.data);

  const [contratoSelected, setContratoSelected] = useState(null);

  useEffect(() => {
    if (
      contratos &&
      (!installments ||
        !installments.cache ||
        new Date() - installments.cache > 60000)
    ) {
      setContratoSelected(contratos.dados[0].contrato_id);
      dispatch(loadInstallments(contratos.dados[0].contrato_id));
    }
  }, [installments]); // eslint-disable-line

  const handleChangeContrato = (e) => {
    dispatch(loadInstallments(e.target.value));
    setContratoSelected(e.target.value);
  };

  return (
    <>
      <Breadcrumb funcionalidade="Financeiro" />
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={8} md={9} className={classes.title}>
          <Typography variant="h6">Financeiro</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.selectContrato}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            color="secondary"
          >
            <InputLabel id="demo-curso-outlined-label" shrink>
              Curso
            </InputLabel>
            <Select
              labelId="demo-curso-outlined-label"
              id="demo-curso-outlined"
              value={contratoSelected}
              onChange={handleChangeContrato}
              label="Curso"
            >
              {contratos.dados &&
                contratos.dados.map((contrato) => (
                  <MenuItem value={contrato.contrato_id}>
                    {contrato.curso}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} component={Paper} className={classes.grid}>
          <FaturasAbertas />
          <FaturasFechadas />
        </Grid>
      </Grid>
    </>
  );
}
