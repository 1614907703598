import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { darken } from 'polished';

import { IoIosArrowForward } from 'react-icons/io';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import api from '~/services/api';
import { requestSuccess } from '~/store/modules/turmas/actions';
import 'react-circular-progressbar/dist/styles.css';

export default function Turmas() {
  const instituicao = useSelector((state) => state.instituicao.data);

  const StyledButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      color: '#fff',
      backgroundColor: instituicao.primary_color,
      borderColor: instituicao.primary_color,
      '&:hover': {
        backgroundColor: darken(0.05, `${instituicao.primary_color}`),
        borderColor: instituicao.primary_color,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: instituicao.primary_color,
        borderColor: instituicao.primary_color,
      },
    },
  })(Button);

  const useStyles = makeStyles({
    title: {
      color: '#333',
      margin: '15px 0',
    },
    header: {
      background: '#fff',
      flexGrow: 1,
      padding: '10px',
    },
    percent: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    turmaName: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    content: {
      flexGrow: 1,
      padding: '10px',
    },
    label: {
      color: '#999',
    },
    vencimento: {
      background: '#f5f5f5',
      margin: '5px',
      borderRadius: '15px',
      padding: '10px',
    },
    svg: {
      width: '70px',
    },
    card: {
      borderLeft: `3px solid ${instituicao.primary_color}`,
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const turmas = useSelector((state) => state.turmas.data);
  const unidade = useSelector((state) => state.unidade.data);

  useEffect(() => {
    async function loadTurmas() {
      const response = await api.get('turmas');
      dispatch(requestSuccess(response.data));
    }

    if (unidade) {
      loadTurmas();
    }
  }, [dispatch, unidade]);

  return (
    <>
      <Typography variant="h4" component="h2" className={classes.title}>
        Turmas
      </Typography>
      {turmas &&
        turmas.map((turma) => (
          <Grid key={turma.turma_id} item md={6} xs={12}>
            <Card className={classes.card}>
              <div className={classes.header}>
                <Grid container spacing={1}>
                  <Grid item xs={6} className={classes.turmaName}>
                    <Typography variant="h4" component="h2">
                      {turma.turma}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.percent}>
                    <CircularProgressbar
                      className={classes.svg}
                      value={turma.frequencia.replace(/\D/, '')}
                      text={turma.frequencia}
                      styles={buildStyles({
                        textSize: '2em',
                        pathColor: instituicao.primary_color,
                        textColor: instituicao.primary_color,
                        backgroundColor: instituicao.primary_color,
                        strokeWidth: 15,
                      })}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.content}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      CURSO
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.curso}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      STATUS
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.status}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      PROFESSOR
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.professor}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      MATÉRIA ATUAL
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.material_atual}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" variant="middle" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      PERÍODO
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.periodo}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      DIAS
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.dias}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.label}
                    >
                      HORÁRIOS
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {turma.horario}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={`/materias/${turma.turma_id}`}>
                      <StyledButton variant="contained" fullWidth>
                        Matérias e conteúdos dessa turma{' '}
                        <IoIosArrowForward size={25} />
                      </StyledButton>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        ))}
    </>
  );
}
