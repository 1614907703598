import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import Typography from '@material-ui/core/Typography';

import WelcomeGo from '~/assets/welcome_go.svg';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  go: {
    color: '#50A6FF',
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Image
        src={WelcomeGo}
        style={{
          paddingTop: 'unset',
          background: 'none',
        }}
        imageStyle={{
          width: 'unset',
          height: 'unset',
          position: 'unset',
        }}
      />

      <Typography variant="h4" className={classes.go}>
        Vamos lá!!
      </Typography>
      <Typography color="secondary" variant="h5">
        No menu, escolha por onde você deseja começar.
      </Typography>
    </div>
  );
}
