import produce from 'immer';

const INITIAL_STATE = {
  assinatura: null,
  contractUploaded: false,
  aceiteConcluido: false,
  dadosCurso: null,
  dadosPagamento: null,
  dadosContratada: null,
  dadosPessoais: null,
  dadosTitular: null,
  termosUso: null,
  loading: false,
  loadingStep: '',
};

export default function aceitecontrato(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@aceitecontrato/SAVE_SIGNATURE': {
        draft.assinatura = action.payload.assinatura;
        break;
      }
      case '@aceitecontrato/ERASE_SIGNATURE': {
        draft.assinatura = null;
        break;
      }
      case '@aceitecontrato/UPLOAD_CONTRACT_SUCCESFULLY': {
        draft.contractUploaded = true;
        break;
      }
      case '@aceitecontrato/UPLOAD_CONTRACT_ERROR': {
        draft.contractUploaded = false;
        break;
      }
      case '@aceitecontrato/DADOS_PESSOAIS_REQUEST_SUCCESS': {
        draft.dadosPessoais = action.payload.dados.dadosPessoais;
        draft.dadosTitular = action.payload.dados.dadosTitular;
        break;
      }
      case '@aceitecontrato/DADOS_CURSO_REQUEST_SUCCESS': {
        draft.dadosCurso = action.payload.dadosCurso;
        break;
      }
      case '@aceitecontrato/DADOS_PAGAMENTO_REQUEST_SUCCESS': {
        draft.dadosPagamento = action.payload.dadosPagamento;
        break;
      }
      case '@aceitecontrato/DADOS_CONTRATADA_REQUEST_SUCCESS': {
        draft.dadosContratada = action.payload.dadosContratada;
        break;
      }
      case '@aceitecontrato/TERMOS_USO_REQUEST_SUCCESS': {
        draft.termosUso = action.payload.termosUso;
        break;
      }
      case '@aceitecontrato/SET_LOADING': {
        draft.loading = action.payload.loading;
        draft.loadingStep = action.payload.loadingStep;
        break;
      }
      case '@unidade/UNIDADE_CHOOSE_SUCCESS': {
        draft.assinatura = null;
        draft.processandoAceite = false;
        draft.aceiteConcluido = false;
        draft.dadosCurso = null;
        draft.dadosPagamento = null;
        draft.dadosContratada = null;
        draft.dadosPessoais = null;
        draft.loading = false;
        draft.loadingStep = '';
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.assinatura = null;
        draft.processandoAceite = false;
        draft.aceiteConcluido = false;
        draft.dadosCurso = null;
        draft.dadosPagamento = null;
        draft.dadosContratada = null;
        draft.dadosPessoais = null;
        draft.loading = false;
        draft.loadingStep = '';
        break;
      }
      default:
    }
  });
}
